/* Bootstrap overrides */ 
$grid-gutter-width: 32px;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$enable-rounded: false;

$font-size: 0.875rem;
$font-family: 'EYInterstate-Regular', Arial, sans-serif;

/* Charts */
$series-a: #AE120F;
$series-b: #155cb4;
$series-c: #F1951C;
$series-d: #007BFF;
$series-e: #7ED321;

/* Tooltip */
$tooltip-bg: #FFFFFF;

/* Checkbox */
$checkbox-bg: #FFFFFF;
$checkbox-border: #C0C0C0;
$checkbox-checked-border: #646464;
$checkbox-checked-bg: #155cb4;
$focused-checked-shadow: none;

/* Radio */
$radio-bg: #FFFFFF;
$radio-border: #C0C0C0;
$radio-hovered-border: #646464;
$radio-checked-bg: #155cb4;
$radio-checked-text: #FFFFFF;