:root {
    /* Fonts */

    --font-family-default: EYInterstate;
    --font-family: 'EYInterstate-Regular', 'Noto Sans', Arial, sans-serif;
    --font-family-fontAwesome: FontAwesome;
    --font-family-alternate: Georgia;
    --font-family-bold: 'EYInterstate-Bold', 'Noto Sans', Arial, sans-serif;
    --font-family-light: 'EYInterstate-Light', 'Noto Sans', Arial, sans-serif;
    --font-family-notoSans: 'Noto Sans', Arial, sans-serif;
    --font-family-materialIcons: 'Material Icons', sans-serif;
    --font-family-materialIconsFilled: 'Material Icons Filled', sans-serif;
    --font-family-webComponentIcons: 'WebComponentsIcons', sans-serif;
    --font-family-outline-illustration-icons: 'outline-illustration-icons', sans-serif;
    --font-size: 16px;

    /* Font Size */

    --font-size-1: 0.5rem;
    --font-size-2: 0.625rem;
    --font-size-3: 0.75rem;
    --font-size-4: 0.875rem;
    --font-size-5: 1rem;
    --font-size-6: 1.125rem;
    --font-size-7: 1.25rem;
    --font-size-8: 1.5rem;
    --font-size-9: 2rem;
    --font-size-10: 2.5rem;
    --font-size-11: 3rem;
}
