.datarollup {
  color: var(--color-ey-confident-grey);
  //d-flex flex-row justify-content-center
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  & > button {
    //override button style
    border: none;
    background-color: transparent;
  }
}

.datarollup__card {
  $margin-card: 2.8125rem;
  cursor: pointer;
  width: 9.5rem;
  // d-flex flex-column justify-content-center
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 1.063rem $margin-card 0 $margin-card;
  position: relative;

  &::after {
    content: " ";
    width: 0.0625rem;
    height: 3.625rem;
    background: var(--color-ey-gray-border-1);
    position: absolute;
    top: 0;
    right: -#{$margin-card};
  }
}
.datarollup__card:first-child {
  margin-left: 0;
}
.datarollup__card:last-child {
  margin-right: 0;
  &::after {
    display: none;
  }
}
.datarollup__number {
  height: 1.875rem;
  font-size: var(--font-size-10);
  font-family: var(--font-family-light) !important;
  font-weight: 300;
  text-align: center;
  line-height: 1.875rem;
  .datarollup__card--active > & {
    font-family: var(--font-family) !important;
    font-weight: 400;
  }
}
.datarollup__state {
  height: 0.75rem;
 font-size: var(--font-size-3);
  font-family: var(--font-family-light) !important;
  font-weight: 300;
  text-align: center;
  //other
  margin-top: 0.8125rem;
  .datarollup__card--active > & {
    font-weight: 600;
  }
}
.datarollup__status {
  height: 0.4375rem;
  background: transparent;
  border-radius: 0;
  //other
  margin: 1.063rem 0.1875rem 0 0.1875rem;
  .datarollup__card--active > & {
    background: var(--color-ey-yellow);
  }
}

.datarollup__spacer {
  width: 5.938rem;
  //d-flex flex-row justify-content-center
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  &::after {
    content: " ";
    width: 0.0625rem;
    height: 3.625rem;
    background: var(--color-ey-gray-border-1);
    position: absolute;
    transform: translateY(1.3rem);
  }
}
.datarollup__bar {
  width: 0.0625rem;
  height: 3.625rem;
  background: var(--color-ey-gray-border-1);
}

//******* Fast Filters style  ************

//Style for the Fast Filters (component only)
.fastfilter {
  height: 2.813rem;

  //vertical center all
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  align-content: center !important;
  flex-wrap: wrap !important;
  -webkit-box-align: center !important;
  align-items: center !important;

  //margin-padding
  margin: 0 var(--spacing-02) 0 0;
  padding: 0 var(--spacing-02) 0 var(--spacing-02);

  //text style
  color: var(--color-ey-confident-grey); //#444555; //no token
 font-size: var(--font-size-3);
  font-family: var(--font-family-light) !important;
  font-weight: 300;
  letter-spacing: 0;

  //default
  border-width: 0;
  background: var(--color-white);

  //clickable
  cursor: pointer;
  & > * {
    pointer-events: none;
  }

  //Main Borders
  &.fastfilter--state1 {
    border-width: 0.0625rem;
    border-style: solid;
    border-color: var(--color-disabled);
    background: var(--color-ey-grey-4);
    &.fastfilter--active {
      border-color: rgba(var(--color-ey-grey-1-rgb), 0.5);
      background: var(--color-white);
      border-bottom-width: 0.125rem;
      .fastfilter__number {
        margin-top: 0.0625rem; //adjust due to bottom border
      }
      &.fastfilter--all {
        border-bottom-color: var(--color-ey-grey-1);
      }
      &.fastfilter--complete {
        border-bottom-color: var(--color-success);
      }
      &.fastfilter--pending {
        border-bottom-color: var(--color-warning);
      }
      &.fastfilter--expired {
        border-bottom-color: var(--color-red-06);
      }
      &.fastfilter--light {
        border-bottom-color: var(--color-ey-confident-grey);
      }
      &.fastfilter--dark {
        border-bottom-color: var(--color-ey-confident-grey);
      }
    }
  }
}

//number (90)
.fastfilter__number {
  height: 2rem;
  color: var(--color-white);
  border-radius: 1rem;
  font-size: var(--font-size-4);
  text-align: center;
  letter-spacing: 0;
  line-height: 1.1875rem;
  margin: 0 var(--spacing-01) 0 0;
  border-width: 0.0625rem;
  border-style: solid;
  padding: 0.25rem 1.0625rem;
  font-family: var(--font-family-light) !important;
  :not(.fastfilter--active):not(.fastfilter--state1) > & {
    background: var(--color-white);
    color: var(--color-ey-confident-grey);
    border-width: 0.0625rem;
    border-style: solid;
    border-radius: 1rem;
  }
  .fastfilter--all & {
    background: var(--color-ey-grey-1);
    border-color: var(--color-ey-grey-1);
  }
  .fastfilter--complete & {
    background: var(--color-success);
    border-color: var(--color-success);
  }
  .fastfilter--pending & {
    background: var(--color-warning);
    border-color: var(--color-warning);
    color: var(--color-ey-confident-grey);
  }
  .fastfilter--expired & {
    background: var(--color-red-06);
    border-color: var(--color-red-06);
  }
  .fastfilter--light & {
    background: var(--color-white);
    color: var(--color-ey-confident-grey);
  }
  .fastfilter--dark & {
    background: var(--color-ey-confident-grey);
    color: var(--color-white);
  }
}

//Style for the Wrapper (not for the component)
.wrapper__fastfilters {
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
}
.wrapper__fastfilters--row {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
}

.wrapper__fastfilters-label {
  width: 5.938rem;
  height: 1.875rem;
  color: var(--color-ey-gray-tint-1); //#8a8b97; //no token
  font-size: var(--font-size-2);
  font-family: var(--font-family-light);
  font-weight: 300;
  line-height: 1.875rem;
  text-align: left;
}
