dynamic-bootstrap-form-control {
    label {
        margin-bottom: 0;
    }

    .ng-dynamic-forms-input,
    .ng-dynamic-forms-select,
    .ng-dynamic-forms-textarea,
    .ng-dynamic-forms-date {
        > label {
            display: none;
        }
    }

    ey-dynamic-text-area {
        .ey-dynamic-textarea-container {
            .textarea-group {
                overflow: hidden; //override for Edge

                .textarea-group__label.asterisk::after {
                    font-family: var(--font-family);
                    content: '*';
                    color: var(--color-red-05);
                    font-size: var(--font-size-2);
                    padding-left: 0.0625rem;
                    vertical-align: 25%;
                }

                & .textarea-group__label {
                    font-size: var(--font-size-4) !important;
                }
                & .textarea-group__textarea {
                    padding-top: 0;
                    margin-top: var(--spacing-01);
                    font-size: var(--font-size-3) !important;
                    outline: none; //override for IE (unset not supported)
                }
            }
        }
    }

    ey-dynamic-input-form-control {
        .textinput-type {
            width: 100%;

            .textinput-group__label.active {
                font-family: var(--font-family-light) !important;
                line-height: 1.125rem !important;
               font-size: var(--font-size-3) !important;
                height: 0.625rem;
            }
            .textinput-group__errmsg {
                display: none;
            }
        }
    }

    ey-dynamic-date-form-control {
        .textinput-group__label.active {
            font-family: var(--font-family-light) !important;
            line-height: 1.125rem !important;
           font-size: var(--font-size-3) !important;
            height: auto;
        }

        .textinput-group__errmsg {
            display: none;
        }
    }

    &.has-error {
        ey-dynamic-input-form-control {
            .textinput-type {
                .textinput-group {
                    .textinput-group__textinput {
                        border-bottom-color: var(--color-red-06);
                    }
                    .textinput-group__errmsg {
                        display: flex !important;
                        align-items: center;
                    }
                }
            }
        }
        ey-dynamic-text-area {
            .ey-dynamic-textarea-container {
                .textarea-group {
                    border-bottom-color: var(--color-red-06);
                }

                .textarea-group__errmsg {
                    @include fonts-col__field-labels-on-focus;
                    font-size: var(--font-size-3) !important; //override
                    width: 100%;
                    text-align: left;
                    display: flex !important;
                    align-items: center;
                    line-height: 1.8 !important;
                    margin-top: 0.25rem;                   
                    &:before {
                        @include material-icons(1rem);
                        content: "error";
                        color: inherit;
                        margin-right: 0.5rem;
                      }                      
                }

            }
        }

        ey-dynamic-select-form-control {
            .ng-select-container {
                outline: none !important;
                border-bottom: var(--color-red-06) solid 0.0625rem !important;
            }
            .ey-select-wrapper{
                .textinput-group__errmsg{
                    display: flex !important;
                    align-items: center;
                }  
            }        
        }

        ey-dynamic-date-form-control {
            .ey-dynamic-date-container {
                .textinput-group {
                    .textinput-group__errmsg {
                        display: flex !important;
                        align-items: center;
                        line-height: 1.8 !important;
                        margin-top: 0.25rem;
                      
                    }
                    .textinput-group__textinput {
                        border-bottom-color: var(--color-red-06);
                    }
                }
            }
        }
    }

    & ul.has-error {
        list-style: none;
        padding-left: 0;
        & li.help-block {
            color: var(--color-red-06);
           font-size: var(--font-size-3);
        }
    }

    ey-dynamic-radio-group {
        .rbcontainer__wrapper {
            padding: 15px;
        }

        .rbcontainer__label input {
            top: 0;
            left: 0.125rem;
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}

dynamic-bootstrap-form-array {
    [role='group'] {
        &:last-of-type {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}

.theme-default .bs-datepicker-head {
    background-color: var(--color-ey-dark-gray);

}

.bs-datepicker-body table td {
    color: var(--color-ey-dark-gray);
}

.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span, .theme-default .bs-datepicker-body table td span[class*=select-]:after, .theme-default .bs-datepicker-body table td[class*=select-] span:after {
    background-color: var(--color-ey-dark-gray);
}
