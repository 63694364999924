@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./fonts/material-icons-outlined.woff2) format("woff2");
}

.material-icons {
  font-family: var(--font-family-materialIcons);
  font-weight: normal;
  font-style: normal;
  font-size: var(--font-size-8);
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}

@font-face {
  font-family: 'Material Icons Filled';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./fonts/material-icons.woff2) format("woff2");
}

.material-icons-filled {
  font-family: var(--font-family-materialIconsFilled);
  font-weight: normal;
  font-style: normal;
  font-size: var(--font-size-8);
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}