//Global variables
$secondary-element-height: 3.125rem;
$z-index-modal: 9999;
@mixin navigation(
  $parentClass,
  $background-color-ul,
  $color-li-a,
  $font-size-li-a,
  $color-link-active,
  $color-link-disabled,
  $color-link-active-label,
  $ul-height: auto
) {
  $this: $parentClass;
  .btn-close {
    display: none;
  }
  ul {
    list-style-type: none;
    height: $ul-height;
    margin: 0rem;
    padding-left: 2.5rem;
    padding-right: 0.3125rem;
    background-color: $background-color-ul;
    & li {
      min-width: 6rem;
      float: left;
      font-size: var(--font-size-5);
      line-height: 1.5rem;
      height: 100%;
      & a:not([href]),
      & a,
      & button {
        padding: 0 1.25rem;
        height: 100%;

        &:not(.disabled) {
          cursor: pointer;
        }
        display: block;
        color: $color-li-a;
        font-family: var(--font-family);
        font-size: $font-size-li-a;
        text-align: center;

        &#{$this}__nav-link {
          background-color: transparent;
          border-bottom: 0.25rem solid transparent;
        }
        &:not(.disabled) {
          &:hover,
          &:focus {
            background-color: transparent;
            border-bottom: 0.25rem solid $color-link-active;
          }
        }
        &.active {
          background-color: transparent;
          color: $color-link-active-label;
          border-bottom: 0.25rem solid $color-link-active;
          font-family: var(--font-family-bold) !important;
          font-weight: bold;
          outline: none;
          text-decoration: none;
        }
      }
      & a:not([href]).disabled,
      & a.disabled,
      & button.disabled {
        color: $color-link-disabled;
        cursor: default;
      }
      & button {
        // override to standard button style
        background-color: transparent;
        border: none;
        padding: 0 1.25rem;
      }
    }
    & a,
    & button {
      &:hover,
      &:focus,
      &:active {
        background-color: var(--color-ey-interaction-overlays);
        outline: none;
        text-decoration: none;
      }
    }
  }

  @media (max-width: var(--break-point-large)) {
    ul {
      height: auto;
      display: flex;
      flex-direction: row;
      li {
        float: none;
      }
    }
  }
}
/* NAVIGATION BAR PRIMARY*/
.navigation-title {
  padding: var(--spacing-06) 0;
  font-size: var(--font-size-6);
  font-family: var(--font-family-bold) !important;

  &--margin-l {
    margin-left: 2.25rem !important;
  }
}
.navigation-bar-primary {
  position: relative;
  &--stack {
    margin-bottom: 3.125rem;
  }
  .navigation-bar-secondary {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    @media (max-width: var(--break-point-large)) {
      position: static;
    }
  }

  @include navigation(
    ".navigation-bar-primary",
    var(--color-ey-confident-grey),
    var(--color-white),
    1rem,
    var(--color-ey-yellow),
    var(--color-ey-gray-tint-1),
    var(--color-white),
    3.6875rem
  );
  &__button-container {
    float: right;
    & button {
      background: 0 0;
      border: none;
      padding: 0 0 0 0.625rem;
      & i {
        display: inline-block;
        color: var(--color-white);
        font-size: var(--font-size-8);
        text-align: center;
        line-height: 1.5rem;
        padding-top: 1.3125rem;

        &:hover {
          color: var(--color-ey-yellow);
        }
      }
    }
  }
  & .navigation-bar-primary__nav-link {
    @include fonts-col__heading-6(3.6875rem);
    @media (max-width: var(--break-point-large)) {
      @include fonts-col__main-nav-default-state(3.125rem);
    }
  }
}

/* NAVIGATION BAR UTILTY*/

.navigation-bar-utility {
  @include navigation(
    ".navigation-bar-utility",
    var(--color-ey-confident-grey),
    var(--color-white),
    1rem,
    var(--color-ey-yellow),
    var(--color-ey-gray-tint-1),
    var(--color-white),
    $secondary-element-height
  );
  @media (max-width: var(--break-point-large)) {
    &__button {
      &--desktop {
        display: none;
      }
    }
  }
  &__button-container {
    float: right;
    & button {
      background: 0 0;
      border: none;
      padding: 0;
      margin: 0;
      height: $secondary-element-height;
      width: $secondary-element-height;
      & i {
        display: inline-block;
        color: var(--color-white);
        font-size: var(--font-size-6);
        text-align: center;
        line-height: 1.5rem;
        width: 1.5rem;
        position: relative;
      }
      & .badge {
        background: var(--color-ey-yellow);
        color: var(--color-ey-dark-gray);
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        top: -0.4375rem;
        position: absolute;
        font-size: var(--font-size-1);
        padding-top: 0.3rem;
        right: -0.25rem;
      }
    }
  }
  @media (max-width: var(--break-point-large)) {
    ul {
      height: 3.125rem;
    }
  }
}

/* NAVIGATION BAR SECONDARY*/

.navigation-bar-secondary {
  @include navigation(
    ".navigation-bar-secondary",
    var(--color-ey-interaction-overlays),
    var(--color-white),
    0.875rem,
    var(--color-ey-yellow),
    var(--color-ey-gray-tint-1),
    var(--color-white),
    $secondary-element-height
  );
  & .navigation-bar-secondary__nav-link {
    @include fonts-col__sub-nav-default-state(2.5rem);
  }
  @media (min-width: var(--break-point-large)) {
    &.active {
      display: block;
    }
  }
}

/* NAVIGATION BAR TERTIARY*/

.navigation-bar-tertiary {
  overflow: auto;
  @include navigation(
    ".navigation-bar-tertiary",
    var(--color-white),
    var(--color-ey-confident-grey),
    1.125rem,
    var(--color-ey-confident-grey),
    var(--color-ey-gray-tint-1),
    var(--color-ey-confident-grey),
    2.8125rem
  );
  & ul li a:not([href]),
  & ul li a,
  & ul li button {
    padding-bottom: 0.5rem;
  }
  & .navigation-bar-tertiary__nav-link {
    @include fonts-col__sub-nav-default-state(2.8125rem);
  }
  box-shadow: 0 0.1875rem 0.1875rem -0.125rem var(--color-shadow-2);

  @media (max-width: var(--break-point-large)) {
    ul {
      height: $secondary-element-height;
      li {
        float: left;
      }
    }
  }
  @media (max-width: var(--break-point-small)er) {
    ul {
      height: auto;
      li {
        float: none;
      }
    }
  }
}

/* USAGE STACK */

/*Primary Level*/
.navigation-bar-stack-container {
  @media (min-width: var(--break-point-large)) {
    .only-mobile {
      display: none;
    }
  }
  .navigation-bar-primary {
    $width-menu: 85%;
    $speed: 0.5s;
    $self: &;
    //Side right
    @media (max-width: var(--break-point-large)) {
      background: var(--color-ey-confident-grey);
      position: fixed;
      right: -#{$width-menu};
      top: 0;
      width: $width-menu;
      max-width: 29rem;
      height: 100%;
      z-index: $z-index-modal;
      -webkit-transition: right $speed; /* For Safari 3.1 to 6.0 */
      transition: right $speed;
      &.showMobile {
        right: 0%;
        ul li {
          a,
          button {
            border-bottom: none;
          }
          button {
            width: 100%;
          }
        }
      }
      &.hideMobile {
        right: -#{$width-menu};
      }
      overflow-y: auto;
      .btn-close {
        display: block;
        width: 2.25rem;
        height: 2.25rem;
        color: var(--color-white);
        cursor: pointer;
        margin: 0.9375rem 1.25rem 1.875rem 0;
        padding: 0;
        border: 0;
        background: transparent;
        float: right;
        @include header-menu-group-state;
        i {
          width: 2.25rem;
          height: 2.25rem;
          font-size: 2.125rem;
          padding: 0;
          margin: 0;
          line-height: 2.25rem;
        }
      }
      ul {
        clear: both;
        list-style-type: none;
        height: auto;
        margin: 0rem;
        padding-left: 0;
        padding-right: 0;
        display: block;
        & .only-mobile:first-child {
          border-bottom: 0.0625rem solid var(--color-ey-gray-text);
        }
        & .only-mobile:last-child {
          border-top: 0.0625rem solid var(--color-ey-gray-text);
        }
        & li {
          background-color: var(--color-ey-confident-grey);
          float: none;
          font-size: var(--font-size-5);
          width: 100%;
          line-height: 1.5rem;
          height: 100%;

          li {
            background-color: var(--color-ey-interaction-overlays);
            a,
            button {
              &:not([href]):not(.disabled) {
                &:hover,
                &:focus {
                  font-family: var(--font-family-bold) !important;
                  font-weight: bold;
                  border-bottom: none; //override
                }
              }
              &.active {
                border-left: solid 0.1875rem var(--color-ey-yellow);
                border-bottom: none; //override
              }
            }
          }

          & a:nth-last-child(n + 2),
          & button:nth-last-child(n + 2) {
            &::after {
              font-family: var(--font-family-materialIcons);
              content: "keyboard_arrow_down";
              color: var(--color-white);
              font-size: var(--font-size-8);
              width: 2rem;
              height: 100%;
              line-height: 4rem;
              -webkit-font-smoothing: antialiased;
              text-rendering: optimizeLegibility;
              /* Support for Firefox. */
              -moz-osx-font-smoothing: grayscale;
              /* Support for IE. */
              font-feature-settings: "liga";
              position: absolute;
              right: 1.75rem;
              top: 0;
            }

            &[aria-expanded="true"] {
              &::after {
                content: "keyboard_arrow_up";
              }
            }
          }

          & a:not([href]),
          & a,
          & button {
            padding: 0.3125rem 1.875rem;
            height: 100%;
            text-align: left;
            font-size: var(--font-size-5) !important;
            color: var(--color-white) !important;
            font-family: var(--font-family-light) !important;
            font-weight: 300;
            font-size: var(--font-size-5);
            position: relative;

            i {
              vertical-align: middle;
              margin-right: 0.625rem;
            }

            &:not(.disabled) {
              cursor: pointer;
            }

            &:not([href]):not(.disabled) {
              &:hover,
              &:focus {
                color: var(--color-white) !important;
                background-color: var(--color-ey-interaction-overlays);
                border-bottom: none;
                font-family: var(--font-family-bold) !important;
                font-weight: bold;
                outline: none;
                text-decoration: none;
              }
            }

            &:hover {
              text-decoration: none;
            }

            &#{$self}__nav-link {
              border-bottom: none !important;
            }

            &.active {
              color: var(--color-white) !important;
              background-color: var(--color-ey-interaction-overlays);
              border-bottom: none;
              font-family: var(--font-family-light) !important;
            }
            &:hover {
              text-decoration: none;
            }
          }
          & a:not([href]).disabled,
          & a.disabled,
          & button.disabled {
            color: var(--color-ey-gray-tint-1);
          }
        }
      }
    }

    & .navigation-bar-stack-primary__nav-link {
      @include fonts-col__main-nav-default-state;
    }
  }
}

/*Secondary Level*/

.navigation-bar-stack-container .navigation-bar-stack-secondary {
  @include navigation(
    ".navigation-bar-stack-secondary",
    var(--color-gray-alt-1),
    var(--color-white),
    0.875rem,
    var(--color-ey-yellow),
    var(--color-ey-gray-tint-1),
    var(--color-white),
    2.5rem
  );
  & .navigation-bar-stack-secondary__nav-link {
    @include fonts-col__sub-nav-default-state;
  }
}

/*Tertiary Level*/

.navigation-bar-stack-container .navigation-bar-stack-tertiary-container {
  @include navigation(
    ".navigation-bar-stack-tertiary",
    var(--color-ey-grey-4),
    var(--color-ey-dark-gray),
    1.125rem,
    var(--color-ey-link-blue),
    var(--color-ey-gray-tint-1),
    var(--color-ey-confident-grey),
    2.8125rem
  );
  margin: 0rem;
  background-color: var(--color-gray-alt-4);
  border-bottom: 0.125rem solid var(--color-ey-gray-tint-2);
  padding: 1rem 1rem 0 2.5rem;
  & ul {
    padding-left: 0%;
  }
  & h3 {
    text-align: left;
    margin: 2rem 0;
    color: var(--color-ey-dark-gray);
    @include fonts-col__heading-6--bold;
  }
  & .navigation-bar-stack-tertiary__nav-link {
    @include fonts-col__heading-6;
  }
  & .breadcrumbs label {
    display: flex;
    @include fonts-col__sub-nav-default-state;
  }
}

.navigation-bar-stack-container .navigation-bar-utility__button-container {
  $speed: 0.5s;
  &.showMobile {
    height: auto;
    top: 0;
    display: block;
  }
  &.hideMobile {
    height: 0;
    display: none;
  }
  & .btn-close,
  & span:not(.badge) {
    display: none;
  }
  .navigation-bar-utility__button__buttons {
    clear: both;
    float: right;
    margin-right: 0;
  }
  @media (max-width: var(--break-point-large)) {
    background: var(--color-ey-interaction-overlays);
    position: fixed;
    right: 0;
    display: none;
    width: 100%;
    height: 0;
    z-index: $z-index-modal;
    -webkit-transition: height $speed;
    transition: height $speed;
    & .btn-close {
      display: block;
      margin: 0.9375rem 1.25rem 0 0 !important;
    }
    & .navigation-bar-utility__button {
      min-width: 5rem;
      height: 4.6875rem;
      margin-bottom: 0.5rem;
      margin-top: 0;
      text-align: center;
      color: var(--color-white);
      @include fonts-col__instructional-text(1.125rem);
      margin-left: 1.5625rem;
      & span {
        display: block;
      }
    }
  }
}

.navigation-bar-modal {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba($color: black, $alpha: 0.5);
  z-index: ($z-index-modal)-1;
}

/*Vertical Tab*/
.navigation-vertical-tab {
  & ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    & li {
      display: flex;
    }
  }
  &__nav-link {
    padding: 0.875rem 3rem 0.75rem 1.25rem;
    font-family: var(--font-family-light) !important;
    width: 9.5625rem;
    cursor: pointer;
    color: inherit; //override a[href] style
    border: none; //override button style
    border-left: 0.375rem solid transparent; //reset left border
    text-align: left; //override button style
    background-color: transparent;
    &.active {
      background: rgba(var(--color-gray-rgb), 0.6);
      border-left: 0.375rem solid var(--color-ey-confident-grey);
      font-family: var(--font-family-bold) !important;
    }
    &:hover {
      color: inherit; //override a[href] style
      text-decoration: none; //override a[href] style
    }
  }
}
