.bold {
    @include bold();
}

.ey-button-group {
    ey-button {
        margin-left: 8px;
        margin-right: 8px;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.select-container {
    position: relative;
    white-space: nowrap;

    select {
        @include select-field();
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 8px 40px 8px 16px !important;
        display: inline;

        &::-ms-expand {
            display: none;
        }

        &:focus::-ms-value {
            background: inherit !important;
        }
    }

    &::after {
        font-family: var(--font-family-fontAwesome) !important;
        content: '\f0d7';
        line-height: 40px;
        position: absolute;
        right: 16px;
        top: auto;
        bottom: auto;
        pointer-events: none;
        color: var(--color-gray-alt-1);
    }

    &.disabled {
        select {
            color: var(--color-ey-gray-tint-1) !important;
            pointer-events: none;
        }
        &::after {
            color: var(--color-ey-gray-tint-1) !important;
        }
        &.loading {
            &::after {
                font-family: var(--font-family-fontAwesome) !important;
                content: '\f1ce';
                -webkit-animation: fa-spin 2s infinite linear;
                animation: fa-spin 2s infinite linear;
                color: var(--color-gray-alt-1) !important;
            }
        }
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.skip-to-main-content {
    max-width: 16.875rem;
    width: 100%;
    height: 4.375rem;
    padding: 0 2rem;
    background: var(--color-white);
    color: var(--color-ey-confident-grey);
    font-family: var(--font-family-light) !important;
    font-size: var(--font-size-4);
    line-height: 1.5rem;
    z-index: -999;
    margin: 0 auto;
    top: -100rem;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;

    span {
        i {
            padding-top: 0.625rem;
            color: var(--color-blue-06);
        }
    }

    &:focus {
        z-index: 999;
        top: 0.625rem;
    }
}

/*Title*/
.container-page-title {
    padding-left: 2.5rem;
}
/*Content*/
.container-page-content {
    padding: 1.25rem 2.5rem;
    padding-top: 0;
}


.ey-context-overflow-menu{

    .menu-list, .submenuList, ul {
        padding: 0;
        margin: 0;
        list-style: none;

        .menuitem{
            .dropdown-item:hover{
                background: var(--color-ey-grey-2a);
                outline: none;
                cursor: pointer;
            }
        }

        .menuitem, .dropdown-item, li {
            min-height: 2.813rem;
            width: 18.63rem;
            line-height: 1.375rem;
            font-size: var(--font-size-3);
            font-family: var(--font-family-light);
            font-weight: normal !important;
            line-height: 1.25rem;
            color: var(--color-ey-confident-grey);
            overflow: hidden;
            background: var(--color-white);
            display: inherit;
            display: flex;
            flex-wrap: wrap;
            align-content: center;

            
            .submenu{
                display: none;
                position: absolute;
                top: 0;
                left: 100%;
                background: var(--color-white);
                border: 0.0625rem solid var(--color-ey-grey-2);
                box-shadow: 0 0.125rem 0.625rem var(--color-shadow-2);
                border-radius: 0.1875rem;
                padding: 0.5rem 0;
                margin-top: 0.3125rem;
                color: var(--color-ey-dark-gray);
                z-index: 99000;
                display: none;
                min-width: 18.75rem;

            }

            &:hover > .submenu {
                display: block;
            }


            a{
                display: block;
                width: 100%;
                padding: 0.25rem 1rem;
                
                &:hover{
                    color: var(--color-ey-confident-grey);
                }

                &.disabled{
                    color: var(--color-ey-gray-tint-1);
                    cursor: not-allowed;
                }
            }
            .is-subitem{
                display: flex;
                justify-content: space-between;

                &::after{
                    font-family: var(--font-family-materialIcons);
                    font-size: var(--font-size-8);
                    content: 'navigate_next';
                    -webkit-font-smoothing: antialiased;
                    text-rendering: optimizeLegibility;
                    /* Support for Firefox. */
                    -moz-osx-font-smoothing: grayscale;
                    /* Support for IE. */
                    font-feature-settings: "liga";
                }
            }   
    
        }
        

        .menuitem:hover, li:hover {
            background: var(--color-ey-grey-2a);
            outline: none;
            cursor: pointer;
        }
    
    }

}