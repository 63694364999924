.icons-render {
  & .material-icons,
  & .material-icons-outlined,
  & .material-icons-two-tone {
    padding: 0.3rem;
  }

  & .iError {
    color: var(--color-error);
  }

  & .iWarning {
    color: var(--color-warning);
  }

  & .light {
    color: var(--color-white);
  }

  & .tool-tip-label {
    color: var(--color-black);
    margin: 0%;
    &:before {
      max-width: none;
    }
  }

  & label {
    padding: 0.2rem;
  }

  & .searchbar {
    background: var(--color-ey-grey-4);
    border-radius: 0;
    border: 0.0625rem solid var(--color-ey-grey-3);
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 3.438rem;
  }

  & .searchbar__content {
    min-height: 2.813rem;
  }

  & .searchbar__form {
    width: 100%;
    min-height: inherit;
    text-align: justify;
    display: -webkit-box;
    display: flex;
  }
  & .searchbar__input {
    width: 93%;
    min-width: 4.375rem;
    min-height: inherit;
    background-color: var(--color-ey-grey-4);
    font-family: var(--font-family-light) !important;
    font-size: var(--font-size-4);
    line-height: 1.125rem;
    color: var(--color-ey-confident-grey);
    font-style: italic;
    font-weight: 600;
    letter-spacing: 0;
    padding-left: 1.25rem; //override

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance: none;
      display: none;
    }

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
  }

  & .searchbar__input:focus,
  & .searchbar__input:hover,
  & .searchbar__btn:focus,
  & .searchbar__btn:hover {
    box-shadow: none;
  }

  & .searchbar__button {
    min-width: 0%;
    background-color: var(--color-ey-grey-4);
    height: inherit;
    padding: 0;
    margin: 0;
    width: 10%;
  }

  & .searchbar__button:before {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    font-family: var(--font-family-materialIcons);
    line-height: 1rem;
    font-size: var(--font-size-8);
    content: attr(data-content);
    color: var(--color-ey-confident-grey);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-feature-settings: "liga";
    font-feature-settings: "liga";
  }
}
