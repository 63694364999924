// Checkbox Borders: [*__checkmark, *__label, *__input]
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Default Border color
//    - var(--color-disabled) {string} : Disabled Border color
//    - var(--color-error) {string} : Error Border color
@mixin Checkbox-border(
  $color: var(--color-ey-confident-grey),
  $color-disabled: var(--color-ey-grey-2),
  $color-error: var(--color-red-06)
) {
  $parentClass: &;
  //#{$parentClass}

  // default  ----------
  &__checkmark {
    border: 0.0625rem solid $color;
  }

  // Disabled  ----------
  &__checkmark {
    #{$parentClass}__input:disabled ~ &,
    #{$parentClass}--disabled & {
      border: 0.0625rem solid var(--color-disabled);
    }
  }

  // Error  ----------
  &__checkmark {
    #{$parentClass}__input[error] ~ &,
    #{$parentClass}--error & {
      border: 0.0625rem solid var(--color-error);
    }
  }
}

// Checkbox Colors: [*__checkmark, *__label, *__input]
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - var(--color-error) {string} : Error label/msg/icon color
@mixin Checkbox-color(
  $color: var(--color-ey-confident-grey),
  $color-bg: var(--color-ey-confident-grey),
  $color-bg-alt: var(--color-ey-grey-2),
  $color-disabled: var(--color-ey-grey-1),
  $color-error: var(--color-red-06)
) {
  $parentClass: &;
  //#{$parentClass}

  // default  ----------
  &__label {
    color: $color;
  }
  &__checkmark {
    background-color: transparent;
    #{$parentClass}__input:checked ~ & {
      background-color: $color-bg;
    }
  }

  // Disabled  ----------
  &__label {
    #{$parentClass}__input:disabled ~ &,
    #{$parentClass}--disabled & {
      color: var(--color-disabled);
    }
  }
  &__checkmark {
    #{$parentClass}__input:disabled ~ &,
    #{$parentClass}--disabled & {
      background-color: $color-bg-alt;
    }
  }

  // Error  ----------
  // &__label // default color
  &__checkmark {
    #{$parentClass}--error #{$parentClass}__input:checked ~ &,
    #{$parentClass}__input:checked[error] ~ & {
      background-color: var(--color-error);
    }
  }
}

/* checkbox ===================================== */

//Sub nav selected title
.checkbox-title-section {
  text-align: left;
  margin: 0 0 1.25rem 0;
}
/* Customize the label (the container) */
.checkbox-container-section {
  margin-right: var(--spacing-03);
}

.checkbox {
  @include Checkbox-border;
  @include Checkbox-color;

  ///dark theme ---------
  // .dark & {
  //   @include Checkbox-border(......);
  //   @include Checkbox-color(.......);
  // }

  position: relative;
  cursor: pointer;
  font-size: var(--font-size-4);
  user-select: none;
  width: 100%;
  padding-right: 1rem;
  padding-left: 0.9375rem;
  margin: 0 auto;
  &.focus {
    outline: 0.0625rem solid var(--color-border);
    outline-offset: 0.375rem;
  }
  .data-table-standard-container &.focus {
    outline: none;
    outline-offset: 0;
    .checkbox__checkmark {
      outline: 0.09rem solid -webkit-focus-ring-color;
      outline-offset: 0.375rem;
    }
  }
}

/* Hide the browser's default checkbox */

.checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.25rem;
  height: 1.25rem;
}

.checkbox__input:disabled ~ .checkbox__checkmark,
.checkbox__input:checked:disabled ~ .checkbox__checkmark,
.checkbox:hover .checkbox__input:disabled ~ .checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  cursor: not-allowed;
}

/* Style the checkmark/indicator */
.checkbox__checkmark:after {
  content: "";
  /*we should use \e118*/
  position: absolute;
  left: 0.375rem;
  top: 0.0625rem;
  width: 0.375rem;
  height: 0.75rem;
  border: solid white;
  border-width: 0 0.125rem 0.125rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;

  /* Show the checkmark when checked */
  .checkbox__input:checked ~ & {
    display: block;
  }
}

/*NOTE: .checkbox is added to override ".content-padding p" style*/
/*NOTE: .checkbox is also used by multi-select's checkbox items*/
.checkbox {
  margin: 0 auto 1.25rem 0;
  .checkbox__label {
    @include fonts-col__instructional-text(0.9375rem);
    font-size: var(--font-size-4) !important;
    position: relative;
    display: inline;
    left: 0.875rem;
    top: 0;
    padding: 0 !important;
    vertical-align: text-top;
  }
  &__label {
    vertical-align: middle;
    position: static;
    margin-right: 1rem;
  }
  &--disabled .checkbox__label {
    cursor: not-allowed;
  }
  &--error {
    margin-bottom: 0;
    .checkbox__label {
      margin: 0.3125rem 0 0 0.125rem;
    }
  }
}

/* Style the error indicator*/

.checkbox__errormsg {
 font-size: var(--font-size-3);
  line-height: 0.875rem;
  color: var(--chk-error-color);
  vertical-align: top;
  display: inline-block;
  margin: 0.25rem 0 1.25rem 0.5625rem;
}

.checkbox__errormsg .material-icons {
  font-size: var(--font-size-5);
  color: var(--chk-error-color);
  vertical-align: middle;
  padding-right: 0.5rem;
}
