$toast-height: 2.813rem;

.message {
  text-align: justify;
  padding: 0.5625rem 0.125rem;
  display: table;
  & .tool-tip-label {
    @include fonts-col__body-copy(1.5rem);
    font-weight: 300;
    color: var(--color-ey-confident-grey);
    margin: 0%;
    &:before {
      max-width: none;
    }
  }
  & .material-icons {
    height: 1.563rem;
    width: 1.563rem;
    vertical-align: middle;
    margin-right: 0.3125rem;
  }
}
.material-icons {
  .message--warning & {
    color: var(--color-warning);
  }
  .message--error & {
    color: var(--color-error);
  }
  .message--success & {
    color: var(--color-success);
  }
  .message--actions & {
    color: var(--color-success);
  }
  .message--info & {
    color: var(--color-ey-grey-1);
  }
}
.message__actions {
  min-width: 17.75rem;
  &__buttons {
    float: right;
    & .btn {
      font-family: var(--font-family-bold) !important;
      padding: 0.25rem 1.125rem 0.25rem 1.063rem;
      margin: 0;
      height: auto;
      min-width: auto;
      color: var(--color-ey-confident-grey);
      &:focus {
        outline: 0.3125rem auto -webkit-focus-ring-color;
      }
    }
  }
}

.toast {
  margin: auto;

  width: 21.88rem;
  height: $toast-height;
  color: var(--color-ey-confident-grey);
  background: var(--color-white);
  box-shadow: 0 0 1rem 0 rgba(var(--color-black), 0.2);
  border-radius: 0;
  border: none;

  &.toast--default {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  &__body {
    padding: 0;
    height: $toast-height;
    text-align: justify;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    label {
      font-family: var(--font-family-light) !important;
      font-size: var(--font-size-4);
      margin: 0;
      height: $toast-height;
      line-height: $toast-height; //text v-alignment
    }
    .material-icons {
      height: 100%;
      width: $toast-height;
      text-align: center;
      line-height: $toast-height; //icon v-alignment
      color: var(--color-white);
    }
    label .material-icons {
      margin-right: 1.063rem;
    }
    &.message {
      &--info label .material-icons {
        background-color: var(--color-ey-grey-1);
      }
      &--success label .material-icons {
        background-color: var(--color-success);
      }
      &--warning label .material-icons {
        background-color: var(--color-warning);
      }
      &--error label .material-icons {
        background-color: var(--color-error);
      }
    }
  }
  &.toast--default,
  &.toast--bottom {
    border-radius: 0;
    & i {
      vertical-align: bottom;
    }
  }
  &.toast--bottom {
    background-color: var(--color-white);
    width: 21.88rem;
    left: 50%;
    position: fixed;
    transform: translate(-50%, 0);
    z-index: 9999;
    bottom: 2.188rem;
    &.show,
    &.showing {
      left: 50%;
      position: fixed;
      transform: translate(-50%, 0);
      -webkit-animation: show--toast 1s forwards; /* Safari 4.0 */
      animation: show--toast 1s forwards;
      animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    &.show.hidding {
      left: 50%;
      position: fixed;
      transform: translate(-50%, 0);
      -webkit-animation: hide--toast 1s forwards; /* Safari 4.0 */
      animation: hide--toast 1s forwards;
      animation-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    @media (max-width: 45rem) {
      min-width: 0;
      max-width: 21.88rem;
      height: auto;
      & .toast__body {
        text-align: center;
      }
    }
  }
  & .btn {
    float: right;
    height: $toast-height;
    border: none; //btn override
    margin: 0; //btn override
    padding: 0; //btn override
    min-width: auto; //btn override
    &--secondary {
      padding: 0 0.5rem;
    }
    &--dismiss {
      min-width: $toast-height; //btn override
      width: $toast-height;
      .material-icons {
        color: inherit;
        margin: 0;
        padding: 0;
        font-weight: bold;
      }
    }
  }
}

@keyframes show--toast {
  0% {
    bottom: 0;
  }
  100% {
    bottom: 2.188rem;
  }
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes hide--toast {
  0% {
    bottom: 2.188rem;
  }
  100% {
    bottom: 0;
  }
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
