$slider-speed: 0.3s;
.stepper {
  .stepper__controls {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &--horizontal {
    .stepper__controls {
      &::after {
        content: "";
        position: absolute;
        top: 49.5%;
        width: 100%;
        height: 0.125rem;
        background-color: var(--color-ey-grey-2);
        margin-right: 0.3125rem;
      }

      overflow: hidden;
      counter-reset: step;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-flow: row wrap;
      flex-flow: row wrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;

      background-color: var(--color-white);
      position: relative;
      margin-bottom: 2rem;

      .stepper__control {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        flex-wrap: nowrap;
        -webkit-flex-wrap: nowrap;
        align-items: center;
        background-color: var(--color-white);
      }

      .stepper__title {
        padding: 0 0.4375rem;
        background-color: var(--color-white);
        color: var(--color-ey-confident-grey);
        font-size: var(--font-size-3);
        line-height: 0.9375rem;
        z-index: 2;
      }
      .stepper__dot {
        position: relative;
        padding-left: 0.4375rem;
        z-index: 2;
        background: var(--color-white);
        &::before {
          /* CSS for creating steper block before the li item*/
          content: "";
          counter-increment: step;
          height: 1.25rem;
          width: 1.25rem;
          line-height: 1.875rem;
          border: 0.0625rem solid var(--color-ey-confident-grey);
          display: inline-block;
          text-align: center;
          margin: 0;
          border-radius: 50%;
          background-color: var(--color-white);
          vertical-align: middle;
          z-index: 1;
          position: relative;
        }
      }
      .stepper__control:first-child {
        .stepper__dot {
          padding-left: 0;
          &::after {
            left: 0.0625rem;
          }
        }
      }

      .stepper__control.stepper__control--done {
        .stepper__dot {
          &::before {
            font-family: var(--font-family-materialIcons);
            line-height: 1rem;
            font-size: var(--font-size-3);
            content: "check";
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            /* Support for Firefox. */
            -moz-osx-font-smoothing: grayscale;
            /* Support for IE. */
            font-feature-settings: "liga";
          }
        }
      }
      .stepper__control.stepper__control--active {
        .stepper__title {
          font-weight: bold;
          font-family: var(--font-family-bold) !important;
        }

        .stepper__dot {
          &::before {
            background-color: var(--color-green-04);
            box-shadow: inset 0 0 0 0.1875rem var(--color-white);
            content: "";
          }
        }
      }
    }

    .stepper__content {
      .stepper__slide {
        height: 0;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        width: 0;
        opacity: 0;
        -webkit-transition: width $slider-speed, opacity $slider-speed; /* For Safari 3.1 to 6.0 */
        transition: width $slider-speed, opacity $slider-speed;

        &-title {
          margin-bottom: 0;
        }

        .textinput-group {
          margin: 2rem 0 1.5rem 0;
        }
      }

      .stepper__slide--active {
        width: 100%;
        opacity: 1;
        height: 100%;
        overflow: visible;
      }
    }
  }
  &--vertical {
    position: relative;
    .stepper__controls {
      .stepper__control {
        display: flex;
        &:not(:first-child) {
          margin-top: 1.6875rem;
        }
        &:last-child {
          & .stepper__dot {
            height: 1.55rem;
          }
        }
        & .stepper__dot {
          height: 1.25rem;
          width: 1.25rem;
          z-index: 0;
          background-color: var(--color-white);
          line-height: 1rem;
          &::before {
            box-shadow: inset 0 0 0 0.125rem var(--color-white);
            content: "";
            height: 1.25rem;
            width: 1.25rem;
            display: inline-block;
            border: 0.0625rem solid var(--color-ey-confident-grey);
            border-radius: 50%;
          }
        }
        &--active {
          .stepper__dot {
            &::before {
              background-color: var(--color-green-06);
            }
          }
          .stepper__title {
            margin-left: 0.5625rem;
            font-size: var(--font-size-4);
            font-family: var(--font-family-bold) !important;
          }
        }
        &--done {
          .stepper__dot {
            &::before {
              font-family: var(--font-family-materialIcons);
              line-height: 1.2rem;
             font-size: var(--font-size-3);
              content: "check";
              -webkit-font-smoothing: antialiased;
              text-rendering: optimizeLegibility;
              -moz-osx-font-smoothing: grayscale;
              font-feature-settings: "liga";
            }
          }
        }
        &:not(.stepper__control--active) .stepper__title {
          margin-left: 0.5625rem;
          font-size: var(--font-size-4);
          font-family: var(--font-family-light) !important;
        }
      }
      &::before {
        content: " ";
        position: absolute;
        display: block;
        top: 0;
        left: 0.59375rem;
        bottom: 0.0625rem;
        height: 100%;
        width: 0.0625rem;
        background-color: var(--color-ey-confident-grey);
      }
    }
  }
}
