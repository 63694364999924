// mixins

@mixin fonts-col__heading-1 {
  font-size: var(--font-size-9) !important;
  font-family: var(--font-family) !important;
  line-height: 2.5rem !important;
}

@mixin fonts-col__heading-2 {
  font-size: var(--font-size-8) !important;
  font-family: var(--font-family) !important;
  line-height: 1.875rem !important;
}

@mixin fonts-col__heading-3 {
  font-size: var(--font-size-7) !important;
  font-family: var(--font-family) !important;
  line-height: 1.5rem !important;
}

@mixin fonts-col__heading-4($line-height: 1.25rem) {
  font-size: var(--font-size-5) !important;
  font-family: var(--font-family) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__heading-5($line-height: 1.063rem) {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family-bold) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__heading-6($line-height: 1.5rem) {
  font-size: var(--font-size-6) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}
@mixin fonts-col__heading-6--bold($line-height: 1.5rem) {
  font-size: var(--font-size-6) !important;
  font-family: var(--font-family-bold) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__main-nav-default-state($line-height: 1.5rem) {
  font-size: var(--font-size-5) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__main-nav-selected-state {
  font-size: var(--font-size-5) !important;
  font-family: var(--font-family-bold) !important;
  line-height: 1.5rem !important;
}

@mixin fonts-col__sub-nav-default-state($line-height: 1.375rem) {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__sub-nav-selected-state($line-height: 1.375rem) {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family-bold) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__body-copy($line-height: 1.375rem) {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__body-2($line-height: 1.25rem) {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__alt-body-copy {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family-alternate) !important;
  font-weight: lighter;
  line-height: 1.375rem !important;
}

@mixin fonts-col__instructional-text($line-height: 1.25rem) {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__table-data-headers($line-height: 1.25rem) {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family-bold) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__table-data($line-height: 1.25rem) {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family-light) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__form-field-default-state {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family) !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__form-field-inputs {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family-light) !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__form-field-input-labels($font-size: 0.75rem) {
  font-size: var(--font-size) !important;
  font-family: var(--font-family-light) !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__labels {
  font-size: var(--font-size-2) !important;
  font-family: var(--font-family-bold) !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__field-labels-on-focus($line-height: 1.125rem) {
  font-size: var(--font-size-2) !important;
  font-family: var(--font-family) !important;
  line-height: $line-height !important;
}

@mixin fonts-col__field-labels-on-focus-light {
  font-size: var(--font-size-2) !important;
  font-family: var(--font-family-light) !important;
  line-height: 1.125rem !important;
}

@mixin fonts-col__field-validation {
  font-size: var(--font-size-2) !important;
  color: var(--color-error) !important;
  font-family: var(--font-family-light) !important;
  line-height: 1.125rem !important;
}

@mixin fonts-col__subtitle-1 {
  font-size: var(--font-size-5) !important;
  font-family: var(--font-family) !important;
  line-height: 1.563rem !important;
}

@mixin fonts-col__subtitle-2 {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family) !important;
  line-height: 1.375rem !important;
}

@mixin fonts-col__caption {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family) !important;
  line-height: 1.25rem !important;
}

@mixin fonts-col__overline {
 font-size: var(--font-size-3) !important;
  font-family: var(--font-family-bold) !important;
  line-height: 1.25rem !important;
  text-transform: uppercase !important;
}

@mixin material-icons($font-size) {
  font-family: var(--font-family-materialIcons);
  font-weight: normal;
  font-style: normal;
  font-size: var(--font-size);
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.fonts-col {
  *:not(hr):not(.fonts-col__sub-title):not(.fonts-col__box):not(.colors-col__sub-nav-selected-state--last):not(.fonts-col__copy):not(.fonts-col__example) {
    margin: 0 0 1.8rem 0;
  }
}
.fonts-col__copy {
  font-size: var(--font-size-6);
  max-width: 790px;
  margin: 0 0 1.5rem 0;
}
.fonts-col__sub-title {
  font-size: var(--font-size-4);
  margin: 0;
}
.fonts-col__example {
  font-size: var(--font-size-8);
  margin: 0 0 4rem 0;
}
.fonts-col__heading-1 {
  @include fonts-col__heading-1;
}

.fonts-col__heading-2 {
  @include fonts-col__heading-2;
}

.fonts-col__heading-3 {
  @include fonts-col__heading-3;
}

.fonts-col__heading-4 {
  @include fonts-col__heading-4;
}

.fonts-col__heading-5 {
  @include fonts-col__heading-5;
}

.fonts-col__heading-6 {
  @include fonts-col__heading-6;
}
.fonts-col__heading-6--bold {
  @include fonts-col__heading-6--bold;
}
.fonts-col__main-nav-default-state {
  @include fonts-col__main-nav-default-state;
}

.fonts-col__main-nav-selected-state {
  @include fonts-col__main-nav-selected-state;
}

.fonts-col__sub-nav-default-state {
  @include fonts-col__sub-nav-default-state;
}

.fonts-col__sub-nav-selected-state {
  @include fonts-col__sub-nav-selected-state;
}

.fonts-col__body-copy {
  @include fonts-col__body-copy;
}

.fonts-col__alt-body-copy {
  @include fonts-col__alt-body-copy;
}

.fonts-col__instructional-text {
  @include fonts-col__instructional-text;
}

.fonts-col__table-data-headers {
  @include fonts-col__table-data-headers;
}

.fonts-col__table-data {
  @include fonts-col__table-data;
}

.fonts-col__form-field-default-state {
  @include fonts-col__form-field-default-state;
}

.fonts-col__form-field-inputs {
  @include fonts-col__form-field-inputs;
}

.fonts-col__labels {
  @include fonts-col__labels;
}

.fonts-col__field-labels-on-focus {
  @include fonts-col__field-labels-on-focus;
}

.fonts-col__field-labels-on-focus-light {
  @include fonts-col__field-labels-on-focus-light;
}

.fonts-col__field-validation {
  @include fonts-col__field-validation;
}

.fonts-col__subtitle-1 {
  @include fonts-col__subtitle-1;
}

.fonts-col__subtitle-2 {
  @include fonts-col__subtitle-2;
}

.fonts-col__caption {
  @include fonts-col__caption;
}

.fonts-col__overline {
  @include fonts-col__overline;
}

.fonts-col__body-2 {
  @include fonts-col__body-2;
}

.fonts-col__box {
  background-color: var(--color-ey-confident-grey);
  color: var(--color-white);
  padding: 1.6875rem;
  margin: 0 10rem 2rem 0;
  min-width: 25rem;
  float: right;
  hr {
    border-color: var(--color-white);
  }
  @media screen and (max-width: 1024px) {
    float: none;
    width: 100%;
  }
}
.colors-col__sub-nav-selected-state--last {
  margin: 0;
}
