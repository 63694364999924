$modal-width: 800px;
$animation: opacity 0.2s linear, right 0.2s ease-out;

.modal {
  display: none;
  // cross component style
  &__header {
    background: var(--color-white);
    border-radius: 0;
    height: 3.75rem;
    border-bottom: 0.0625rem solid var(--color-ey-grey-2a);
    padding: 1.125rem 1.25rem 1.0625rem 1.25rem;
    &.dark-mode {
      background: var(--color-ey-confident-grey);
    }
  }
  &__icon {
    height: 1.3125rem;
    width: 1.3125rem;
    color: var(--color-red-05);
    line-height: 1.125rem;
    margin: 0 0.5rem 0 0;
    vertical-align: middle;
  }
  &__title {
    color: var(--color-ey-confident-grey);
    font-size: var(--font-size-7);
    margin: 0;
    &.dark-mode {
      color: var(--color-white);
    }
  }
  &__close {
    opacity: 1;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
    text-shadow: none;
    border: none;
    &.close:not(:disabled):hover,
    &.close:not(:disabled):focus {
      opacity: 1;
    }
    & span {
      &::before {
        line-height: 1.5rem;
        //   color: var(--color-white);
        color: var(--color-ey-confident-grey);
        content: "close";
        font-family: var(--font-family-materialIcons);
        font-size: var(--font-size-8);
      }
    }
  }

  &__body {
    padding: 1.875rem 2.5rem 3.125rem 1.25rem;
    p {
      @include fonts-col__sub-nav-default-state;
      text-align: left;
      padding: 0;
      margin: 0;
    }
  }

  &__footer {
    padding: 0.59375rem 0 0.59375rem 1.25rem;
    justify-content: flex-start;
    border-top: 0.0625rem solid var(--color-ey-grey-2a);
    &.modal__footer--center {
      justify-content: center;
    }
    & .btn {
      margin: 0;
      &:not(:first-child) {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    box-shadow: 0 0 0.6875rem 0.5rem rgba(var(--color-black-rgb), 0.38);
    border: none;
    border-radius: 0;
  }
}
.modal-open {
  overflow: inherit;
}
.modal-side {
  display: none;
  &.modal-side--right {
    .modal-dialog {
      position: fixed;
      margin: auto;
      max-width: $modal-width;
      width: 100%;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      z-index: 9999;
    }

    .modal-content {
      height: 100%;
      overflow-y: auto;
      border-radius: 0;
      z-index: 0;
      padding: 0;
    }
    .modal-body {
      padding: 1.875rem 2rem 0 2rem;
    }

    // Right side
    &.modal-side--fade {
      .modal-dialog {
        right: -$modal-width;
        -webkit-transition: $animation;
        -moz-transition: $animation;
        -o-transition: $animation;
        transition: $animation;
      }
    }
    &.modal-side--fade.show {
      .modal-dialog {
        right: 0;
      }
    }
  }

  &__header {
    border-radius: 0;
    //background: var(--color-ey-confident-grey);
    background: var(--color-white);
    padding: 1.125rem 1.25rem 1.0625rem 1.25rem;
  }
  &__title {
    font-size: var(--font-size-7);
    //color: var(--color-white);
    color: var(--color-ey-confident-grey);
    margin: 0;
    line-height: 1.5rem;
  }
  &__close {
    opacity: 1;
    opacity: 1;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
    text-shadow: none;
    &.close:not(:disabled):hover,
    &.close:not(:disabled):focus {
      opacity: 1;
    }
    span {
      &::before {
        line-height: 1.5rem;
        //color: var(--color-white);
        color: var(--color-ey-confident-grey);
        content: "close";
        font-family: var(--font-family-materialIcons);
        font-size: var(--font-size-8);
      }
    }
  }

  &__body {
    text-align: left;

    &-head {
      @include fonts-col__heading-6;
      color: var(--color-ey-confident-grey);
      padding: 0;
    }
    &-content {
      @include fonts-col__sub-nav-default-state;
      color: var(--color-ey-confident-grey);
      padding: 0;
    }
  }

  &__btn {
    margin-right: 1rem !important;
  }

  &__footer {
    $max-width: 470px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.0625rem solid var(--color-ey-grey-2a);
    padding: 0.59375rem 1.25rem 0.59375rem 1.25rem;
    @media (max-width: $max-width) {
      text-align: right;
    }
    .modal-side {
      &__back {
        margin: 0;
        &__button {
          font-size: var(--font-size-4);
          color: var(--color-ey-link-blue);
          border: none;
          background-color: transparent;
          text-align: left;
          @media (max-width: $max-width) {
            display: inline;
            position: static;
            margin: 0.625rem 2rem 0 0;
          }
          &::before {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            vertical-align: middle;
            text-align: center;
            padding: 0;
            margin: 0 0.5rem 0 0;
            font-family: var(--font-family-materialIcons);
            line-height: 1rem;
            font-size: var(--font-size-3);
            content: "keyboard_arrow_left";
            color: var(--color-white);
            background-color: var(--color-ey-link-blue);
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            /* Support for Firefox. */
            -moz-osx-font-smoothing: grayscale;
            /* Support for IE. */
            font-feature-settings: "liga";
          }
        }
      }
      &__buttons {
        margin: 0;
        &__button {
          margin: 0;
          &:not(:first-child) {
            margin-left: 1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-lg-prev {
    max-width: 98%;
  }
}
