@mixin grid-menu-popup-text {
    font-size: var(--font-size-4) !important;
    font-family: var(--font-family-light) !important;
    color: var(--color-ey-confident-grey);
    background: transparent;
    .k-icon {
        color: var(--color-ey-interaction-overlays) !important;
    }
}

@mixin grid-menu-button-styles {
    font-size: var(--font-size-4) !important;
    font-family: var(--font-family-bold) !important;
    border-radius: 0;
    background-color: var(--color-white);
    background: 0 0;
    color: var(--color-ey-confident-grey);
    border: 0.0625rem solid var(--color-ey-confident-grey);
    box-shadow: none;

    margin: 0 !important;
    & + .k-button,
    & + .btn {
        margin-left: 0.46875rem !important;
    }

    &.k-primary,
    &.btn--primary,
    &.k-button-solid-primary {
        color: var(--color-ey-confident-grey);
        background-color: var(--color-ey-yellow);
        border: 0;
        opacity: 1;
    }

    &.active,
    &.focus,
    &.hover,
    &:active,
    &:focus,
    &:hover {
        color: var(--color-white);
        background-color: var(--color-ey-confident-grey);
        box-shadow: none;
        outline: 0;
    }

    &[disabled] &.disabled,
    &:disabled {
        &,
        &:hover,
        &.hover,
        &:focus,
        &.focus,
        &:active,
        &.active {
            color: var(--color-ey-grey-1);
            background-color: var(--color-ey-grey-2a); //no token
            border: 0.0625rem solid transparent; /* all buttons need to have a transparent border to prevent bouncing and support in IE11*/
            opacity: 1;
            cursor: not-allowed;
        }
    }
}

@mixin grid-menu-item-hover-styles {
    cursor: pointer;
    background: var(--color-ey-grey-2a) !important;
    outline: 0;
}

@mixin grid-menu-checkbox-label-style {
    @include grid-menu-popup-text;
    line-height: 0.9375rem !important;
}

@mixin grid-menu-styles {

    kendo-popup {
        .k-popup {
            padding: 0;
        }
        .k-grid-columnmenu-popup.dateFilter{
            max-width: none;
        }
        .k-grid-columnmenu-popup,
        .k-grid-columnmenu-popup {
            font-family: var(--font-family-materialIcons);
            font-feature-settings: 'liga';
            max-height: 40rem;
            overflow-y: auto;

            .k-icon {
                font-family: var(--font-family-webComponentIcons) !important;
            }

            kendo-treelist-columnmenu-item {
                .k-columnmenu-item {
                    padding: 0.5rem 1rem;
                }
            }

            kendo-treelist-columnmenu-chooser.k-columnmenu-item-wrapper,
            kendo-treelist-columnmenu-filter.k-columnmenu-item-wrapper {
                border-top: 0;
            }

            kendo-treelist-columnmenu-filter kendo-treelist-columnmenu-item .k-columnmenu-item-content kendo-treelist-filter-menu-container {
                .k-filter-menu div.k-filter-menu-container {
                    padding: 0;
                }
            }

            kendo-grid-columnmenu-container .k-columnmenu-item-wrapper kendo-grid-columnmenu-item,
            kendo-grid-columnmenu-item,
            kendo-treelist-columnmenu-item {
                border-bottom: 1px solid rgba(var(--color-ey-confident-grey-rgb), 0.15);
                display: block;
            }

            kendo-grid-columnmenu-container .k-columnmenu-item-wrapper:last-child kendo-grid-columnmenu-item,
            kendo-grid-columnmenu-filter kendo-grid-columnmenu-item,
            kendo-treelist-columnmenu-filter kendo-treelist-columnmenu-item {
                border-bottom: none;
            }

            // common element under kendo-popup
            .k-column-list-item,
            .k-columnmenu-item {
                @include grid-menu-popup-text;

                &:hover {
                    @include grid-menu-item-hover-styles;
                }

                .k-checkbox-label {
                    @include grid-menu-checkbox-label-style;
                    overflow-wrap: anywhere;
                }
            }
            .k-columnmenu-item.k-state-selected {
                color: inherit;
                background-color: inherit;
                font-family: var(--font-family-bold) !important;
            }

            // main element under kendo-popup
            kendo-grid-columnmenu-sort,
            kendo-treelist-columnmenu-sort {
                kendo-grid-columnmenu-item,
                kendo-treelist-columnmenu-item {
                    .k-columnmenu-item {
                        .k-icon {
                            &.k-i-sort-asc-sm {
                                position: relative;
                                display: inline-block;
                                &::before {
                                    font-family: var(--font-family-materialIcons);
                                    content: 'arrow_drop_up';
                                }
                            }
                            &.k-i-sort-desc-sm {
                                position: relative;
                                display: inline-block;
                                &::before {
                                    font-family: var(--font-family-materialIcons);
                                    content: 'arrow_drop_down';
                                }
                            }
                        }
                    }
                }
            }
            // main element under kendo-popup
            kendo-grid-columnmenu-chooser,
            kendo-treelist-columnmenu-chooser {
                kendo-grid-columnmenu-item,
                kendo-treelist-columnmenu-item {
                    .k-columnmenu-item {
                        .k-icon {
                            &.k-i-columns {
                                position: relative;
                                display: inline-block;
                                &::before {
                                    font-family: var(--font-family-materialIcons);
                                    content: 'view_column';
                                    -webkit-font-smoothing: antialiased;
                                    text-rendering: optimizeLegibility;
                                    /* Support for Firefox. */
                                    -moz-osx-font-smoothing: grayscale;
                                    /* Support for IE. */
                                    font-feature-settings: 'liga';
                                }
                            }
                        }
                    }
                    .k-columnmenu-item-content {
                        .k-column-list-item {
                            padding-left: 1.5rem;
                        }
                    }
                }
            }
            kendo-grid-date-filter-menu {
                .k-filter-and {
                    height: 1.5rem;
                    margin-bottom: 0.625rem !important;
                    span span {
                        height: 1.5rem;
                    }
                }
                + .k-actions {
                    border-top: solid 0.0625rem var(--color-ey-grey-1);
                    padding: 0 0.625rem;
                    height: 3.8125rem;
                    justify-content: center;
                    flex-flow: unset;
                    gap: 0;
                    button {
                        flex: auto !important;
                        padding: 0;
                        height: 2.8125rem;
                    }
                }
            }
            kendo-grid-date-filter-menu-input {
                kendo-dropdownlist {
                    margin-top: 0.1875rem;
                    .k-input-button {
                        width: 2.625rem;
                        padding: 0;
                        .k-icon {
                            &::before {
                                word-wrap: normal;
                                font-family: var(--font-family-materialIcons);
                                content: 'keyboard_arrow_down';
                                font-size: var(--font-size-8);
                                color: var(--color-ey-confident-grey);
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                /* Support for Firefox. */
                                -moz-osx-font-smoothing: grayscale;
                                /* Support for IE. */
                                font-feature-settings: 'liga';
                            }
                        }
                    }
                    &[aria-expanded='true'] {
                        .k-input-button {
                            .k-icon {
                                &::before {
                                    content: 'keyboard_arrow_up';
                                }
                            }
                        }
                    }
                }
                kendo-datepicker {
                    margin-bottom: 0.5rem !important;
                    padding-bottom: 0 !important;
                }
                span span {
                    height: 1.5rem;
                    font-size: 0.9375rem;
                }
                kendo-dateinput {
                    padding-bottom: 0 !important;
                    input {
                        height: 1.5rem;
                    }
                }
            }

            kendo-grid-date-filter-menu-input + kendo-dropdownlist {
                margin-top: 0.1875rem;
                .k-input-button {
                    width: 2.625rem;
                    padding: 0;
                    .k-icon {
                        &::before {
                            word-wrap: normal;
                            font-family: var(--font-family-materialIcons);
                            content: 'keyboard_arrow_down';
                            font-size: var(--font-size-8);
                            color: var(--color-ey-confident-grey);
                            -webkit-font-smoothing: antialiased;
                            text-rendering: optimizeLegibility;
                            /* Support for Firefox. */
                            -moz-osx-font-smoothing: grayscale;
                            /* Support for IE. */
                            font-feature-settings: 'liga';
                        }
                    }
                }
                &[aria-expanded='true'] {
                    .k-input-button {
                        .k-icon {
                            &::before {
                                content: 'keyboard_arrow_up';
                            }
                        }
                    }
                }

                &:hover,
                &:focus {
                    border-color: transparent !important;
                    border-bottom: 0.0625rem solid var(--color-ey-gray) !important;
                    background-color: var(--color-ey-grey-2a) !important;
                }
            }

            // main element under kendo-popup
            kendo-grid-columnmenu-filter,
            kendo-treelist-columnmenu-filter {
                .k-columnmenu-item-content {
                    .k-filter-menu {
                        .k-filter-menu-container {
                            padding: 0;
                            gap: 0;

                            .k-actions {
                                padding: 1rem 0;
                            }
                            .filter-options-container,
                            .k-actions {
                                justify-content: center;

                                .k-button {
                                    flex: 0 0 40%;
                                }
                                .k-button[type="reset"]:hover {                                   
                                    color: var(--color-white);
                                    background-color: var(--color-ey-confident-grey) !important;
                                    border-color: 0.0625rem solid transparent !important;
                                }
                                .k-textbox {
                                    &:focus {
                                        border-color: unset;
                                        box-shadow: none;
                                    }
                                }
                            }
                            ul {
                                padding: 0;
                                li.checkbox {
                                    padding-left: 3.6rem;
                                    .checkbox__checkmark {
                                        left: 1.5rem !important;
                                    }
                                }
                            }
                        }
                    }
                }

                kendo-grid-columnmenu-item,
                kendo-treelist-columnmenu-item {
                    .k-columnmenu-item {
                        height: 2.75rem;
                        display: flex;
                        padding: 5px !important;
                        align-items: center;
                        .k-icon {
                            &.k-i-filter {
                                position: relative;
                                display: inline-block;
                                width: 1rem;
                                height: 1rem;
                                padding: 0;
                                margin: 0 0.75rem 0 0.625rem;
                                &::before {
                                    font-family: var(--font-family-materialIcons);
                                    font-size: 1rem;
                                    height: 1rem;
                                    width: 1rem;
                                    content: 'filter_list';
                                    -webkit-font-smoothing: antialiased;
                                    text-rendering: optimizeLegibility;
                                    /* Support for Firefox. */
                                    -moz-osx-font-smoothing: grayscale;
                                    /* Support for IE. */
                                    font-feature-settings: 'liga';
                                }
                            }
                        }
                    }
                }
            }

            // kendo-grid-columnlist under kendo-grid-columnmenu-chooser/kendo-treelist-columnmenu-chooser
            kendo-grid-columnlist,
            kendo-treelist-columnlist {
                .k-actions {
                    .k-button,
                    .btn {
                        @include grid-menu-button-styles;
                    }
                }
            }
            kendo-grid-filter-menu-container {
                margin: 0;
            }
            kendo-treelist-filter-menu-container {
                margin: 0 0.9375rem 0.625rem 0.9375rem;
            }
            // kendo-grid-filter-menu-container under kendo-grid-columnmenu-filter/kendo-treelist-columnmenu-filter
            kendo-grid-filter-menu-container,
            kendo-treelist-filter-menu-container {
                .k-filter-menu {
                    padding: 0;
                    margin: 0.3125rem 0 0 0;
                    border: none;

                    ul {
                        li.checkbox {
                            &:hover {
                                @include grid-menu-item-hover-styles;
                            }
                            &:focus-within {
                                outline: 0.0625rem solid var(--color-border);
                                outline-offset: -0.1rem;
                                &:hover {
                                    @include grid-menu-item-hover-styles;
                                }
                            }
                            .checkbox__label {
                                overflow-wrap: anywhere;
                            }
                        }
                    }

                    .k-actions {
                        justify-content: center;

                        .k-button,
                        .btn {
                            flex: 0 0 40%;
                            @include grid-menu-button-styles;
                        }
                    }
                }
            }

            kendo-grid-date-filter-menu,
            kendo-treelist-date-filter-menu {
                & &.k-filter-menu-container {
                    min-width: 15.63rem;
                }

                .k-filter-menu,
                .k-widget {
                    display: block;
                    width: 100%;
                    margin-bottom: 0.5rem;
                    margin: 0;
                    padding: 0 0.625rem 0.3125rem;
                }

                .k-datepicker,
                .k-filter-and {
                    margin-bottom: 1rem;
                }
            }

            kendo-grid-date-filter-menu {
                gap: 0rem;

                & > kendo-dropdownlist {
                    height: 44px;
                    display: flex;
                    border: var(--color-ey-grey-2) solid 0.0625rem;
                    margin: 0 0.625rem 0.625rem 0.625rem;
                    max-width: 7.875rem;

                    &.k-filter-and {
                        width: 100%;
                        background: var(--color-ey-grey-4);
                        border-radius: 0;
                        height: 2.8125rem;
                        box-shadow: none !important;
                        overflow: visible;
                        border: 0 solid transparent;
                        border-bottom: 0.0625rem solid var(--color-ey-gray);
                        outline: none;

                        &:hover {
                            background-color: var(--color-ey-grey-2a);

                            .ng-value-container {
                                .ng-placeholder {
                                    &::after {
                                        background-color: var(--color-ey-grey-2a);
                                    }
                                }
                            }
                        }
                    }
                    .k-input-inner {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        .k-input-value-text {
                            padding: 0;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            color: var(--color-ey-confident-grey);
                            font-family: var(--font-family-light) !important;
                            line-height: 1.25rem !important;
                            font-size: var(--font-size-4) !important;
                        }
                    }
                    .k-input-button {
                        height: 100%;
                        width: 2.625rem;
                    }
                }

                kendo-grid-filter-menu-input-wrapper {
                    padding: 0 10px 2px;
                    display: block;
                    kendo-dropdownlist {
                        width: 100%;
                        display: flex;
                        border: var(--color-ey-grey-2) solid 0.0625rem;
                        margin: 0 0 0.625rem 0;
                        background: var(--color-ey-grey-4);
                        border-radius: 0;
                        height: 2.8125rem;
                        box-shadow: none !important;
                        overflow: visible;
                        border: 0 solid transparent;
                        border-bottom: 0.0625rem solid var(--color-ey-gray);
                        outline: none;
                        &:hover,
                        &:focus {
                            border-bottom: 0.0625rem solid var(--color-ey-gray);
                            background-color: var(--color-ey-grey-2a);
                            cursor: pointer;
                        }

                        .k-input-inner {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            .k-input-value-text {
                                padding: 0;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                color: var(--color-ey-confident-grey);
                                font-family: var(--font-family-light) !important;
                                line-height: 1.25rem !important;
                                font-size: var(--font-size-4) !important;
                            }
                        }
                        .k-input-button {
                            height: 100%;
                        }
                    }
                    kendo-datepicker {
                        padding: 0 !important;
                        width: 100%;
                        display: flex;
                        border: var(--color-ey-grey-2) solid 0.0625rem;
                        margin: 0 0 0.625rem 0;
                        background: var(--color-ey-grey-4);
                        border-radius: 0;
                        height: 2.8125rem;
                        box-shadow: none !important;
                        overflow: visible;
                        border: 0 solid transparent;
                        border-bottom: 0.0625rem solid var(--color-ey-gray);
                        outline: none;

                        &:hover,
                        &:focus,
                        &:focus-within,
                        &.k-focus {
                            border-color: transparent;
                            border-bottom: 0.0625rem solid var(--color-ey-gray);
                            background-color: var(--color-ey-grey-2a);
                        }

                        .k-input-inner {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            padding: 0.375rem 0.75rem;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            color: var(--color-ey-confident-grey);
                            font-family: var(--font-family-light) !important;
                            line-height: 1.25rem !important;
                            font-size: var(--font-size-4) !important;
                        }

                        .k-icon-button {
                            width: 42px;
                            background: transparent;
                            border-color: transparent;
                            &:hover {
                                border-color: transparent;
                            }

                            .k-icon.k-i-calendar {
                                width: 100%;
                                justify-content: center;

                                &::before {
                                    word-wrap: normal;
                                    font-family: var(--font-family-materialIcons);
                                    content: 'date_rangen';
                                    font-size: var(--font-size-8);
                                    color: var(--color-ey-confident-grey);
                                    -webkit-font-smoothing: antialiased;
                                    text-rendering: optimizeLegibility;
                                    /* Support for Firefox. */
                                    -moz-osx-font-smoothing: grayscale;
                                    /* Support for IE. */
                                    font-feature-settings: 'liga';
                                    width: 1.5rem;
                                }
                            }
                        }

                        .k-input::placeholder {
                            color: var(--color-ey-grey-1);
                        }
                        .k-picker-wrap {
                            .k-select {
                                width: 1.5rem;
                            }
                        }
                    }
                }
            }

            grid-column-chooser {
                .k-column-list {
                    .checkbox.k-columnmenu-item {
                        border-bottom: none;
                        padding-left: 3.5rem;
                    }
                    .checkbox .checkbox__checkmark {
                        left: 1.563rem !important;
                    }
                    .checkbox {
                        &:hover {
                            @include grid-menu-item-hover-styles;
                        }
                        &:focus-within {
                            outline: 0.0625rem solid var(--color-border);
                            outline-offset: -0.1rem;
                            &:hover {
                                @include grid-menu-item-hover-styles;
                            }
                        }
                    }
                }
                ey-button.btn-flex > button.btn {
                    height: 2.188rem !important;
                }
            }
        }
        .dateFilter {
            min-width: fit-content;
            width: 100%;

            .k-columnmenu-item {
                pointer-events: none;
            }

            .k-actions {
                padding: 0.75rem 1.875rem 0.75rem 1.875rem !important;
                border-top: solid 1px var(--color-ey-grey-2a);

                .k-button {
                    flex: 0 0 50% !important;
                    height: 2.75rem;
                }
            }
        }
    }

    kendo-grid-column-menu,
    kendo-treelist-column-menu {
        .k-grid-column-menu {
            &:hover {
                color: var(--color-ey-confident-grey);
            }

            &.k-grid-filter {
                &.k-state-active {
                    background: auto;
                    background: initial;
                }
                &:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
