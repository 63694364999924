/* Only for design system */
.active-pg-show {
  color: var(--color-ey-confident-grey);
  font-family: var(--font-family);
}

.pagination-wrapper {
  margin-top: 2rem;
}

/* ++++++++++ PAGINATION STYLES ++++++++++ */

.pagination-block {
  color: var(--color-ey-confident-grey);
  font-size: var(--font-size-4);
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  & .pagination-block__page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.8125rem;
    width: 2.8125rem;
    border-bottom: 0.1875rem solid transparent;
    &:hover {
      border-bottom: 0.1875rem solid var(--color-ey-confident-grey);
    }
    &--numeration {
      width: 5.9375rem;
      font-family: var(--font-family-bold);
      & span {
        font-family: var(--font-family-bold);
      }
      &:hover {
        border-bottom: 0.1875rem solid transparent;
      }
    }
    & > button {
      @include fonts-col__instructional-text;
      line-height: 0 !important; //override
      width: 100%;
      height: 100%;
      text-align: center;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      &:focus {
        outline: 0.3125rem auto -webkit-focus-ring-color;
      }
      & i {
        font-size: 1.6875rem;
      }
    }
  }

  & &__active {
    border-bottom: 0.1875rem var(--color-ey-confident-grey) solid;
  }

  & &__prev-next {
    color: var(--color-ey-grey-1);
    top: 0.4375rem;
    padding: 0.125rem 0 0 0;
  }

  & &__ellipses {
    color: var(--color-ey-grey-1);
    font-size: var(--font-size-4);
    padding: 0.3125rem;
  }
}

/* ++++++++++ PAGINATION Items Per Page STYLES ++++++++++ */
// Note: this component is also using dropdown style and script

$pagination-dropdown-width: 6.4375rem;
$pagination-dropdown-height: 2.5rem;
@mixin bordered($color: transparent) {
  outline: none;
  border: 0.0625rem solid $color;
}

.dropdown.dropdown--pagination {
  height: $pagination-dropdown-height;
  min-height: $pagination-dropdown-height;
  width: $pagination-dropdown-width;
  .dropdown-toggle {
    height: $pagination-dropdown-height;
    min-height: $pagination-dropdown-height;
    width: $pagination-dropdown-width;

    .textinput-group__textinput {
      @include fonts-col__form-field-inputs;
      font-size: var(--font-size-4) !important; //override size important
      padding-top: 0.15rem;
      padding-left: 0.5625rem;
      @include bordered(transparent);
      cursor: pointer;
      background-color: var(--color-white);
    }

    .textinput-group__label {
      cursor: pointer;
      .dropdown__expand_less,
      .dropdown__expand_more {
        top: 1.625rem;
      }
    }
  }

  .dropdown-toggle[aria-expanded="true"]
    > .textinput-group__textinput:not(.error):not(.disabled) {
    font-size: var(--font-size-4) !important; //override dropdown important style
  }

  .dropdown-menu {
    width: $pagination-dropdown-width;
    min-width: $pagination-dropdown-width;
    @include bordered(#2e2e38);
    box-shadow: 0 0.125rem 0.1875rem 0 var(--color-shadow-2);
    margin-top: 0.0625rem;
    border-radius: 0; //override bootstrap

    .dropdown-item {
      @include fonts-col__sub-nav-default-state;
      color: var(--color-ey-confident-grey);
      height: $pagination-dropdown-height;
      width: 100%;
      line-height: 1.4rem;
      padding-left: 0.9375rem;
      cursor: pointer;
      &:hover,
      &.active {
        background: var(--color-ey-grey-2a);
      }
    }
  }
}
.dropdown--pagination__label {
  color: var(--color-ey-confident-grey);
  text-align: left;
  line-height: 2.625rem;
  width: fit-content;
  margin-left: 1.25rem;
  span {
    @include fonts-col__instructional-text;
    margin-right: 1.063rem;
  }
}
