  @font-face {
    font-family: 'outline-illustration-icons';
    src:  url('./fonts/outline-illustration-icons.eot?34g4pi');
    src:  url('./fonts/outline-illustration-icons.eot?34g4pi#iefix') format('embedded-opentype'),
      url('./fonts/outline-illustration-icons.ttf?34g4pi') format('truetype'),
      url('./fonts/outline-illustration-icons.woff?34g4pi') format('woff'),
      url('./fonts/outline-illustration-icons.svg?34g4pi#outline-illustration-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  $icomoon-font-family: "outline-illustration-icons" !default;

  $icon-Employee-Badge: "\e90b";
  $icon-Phone-Call: "\e90a";
  $icon-Chat: "\e909";
  $icon-Comparison-Bars: "\e908";
  $icon-Financial-Analysis: "\e907";
  $icon-Download-Folder: "\e906";
  $icon-Search-icon: "\e915";
  $icon-Hardware: "\e913";
  $icon-Error: "\e912";
  $icon-Checklist: "\e911";
  $icon-Candlestick-Chart: "\e910";
  $icon-User-Icon: "\e90f";
  $icon-Team-Icon: "\e90e";
  $icon-Community: "\e90d";
  $icon-Vision: "\e914";
  $icon-Contacts: "\e90c";
  $icon-Export-file: "\e900";
  $icon-Script: "\e902";
  $icon-Script-Edit: "\e901";
  $icon-Paperwork: "\e903";
  $icon-Building: "\e904";
  $icon-Financial-Management: "\e905";
  $icon-Speed-Optimization: "\e916";
  
  .outline-illustration-icons {
    font-family: var(--font-family-outline-illustration-icons) !important;
    font-weight: normal;
    font-style: normal;
    font-size: var(--font-size-8);
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga";

    &.employee_badge {
      &:before {
        content: $icon-Employee-Badge; 
      }
    }
    &.phone_call {
      &:before {
        content: $icon-Phone-Call; 
      }
    }
    &.chat {
      &:before {
        content: $icon-Chat; 
      }
    }
    &.comparison_bars {
      &:before {
        content: $icon-Comparison-Bars; 
      }
    }
    &.financial_analysis {
      &:before {
        content: $icon-Financial-Analysis; 
      }
    }
    &.download_folder {
      &:before {
        content: $icon-Download-Folder; 
      }
    }
    &.search_icon {
      &:before {
        content: $icon-Search-icon; 
      }
    }
    &.hardware {
      &:before {
        content: $icon-Hardware; 
      }
    }
    &.error {
      &:before {
        content: $icon-Error; 
      }
    }
    &.checklist {
      &:before {
        content: $icon-Checklist; 
      }
    }
    &.candlestick_chart {
      &:before {
        content: $icon-Candlestick-Chart; 
      }
    }
    &.user_icon {
      &:before {
        content: $icon-User-Icon; 
      }
    }
    &.team_icon {
      &:before {
        content: $icon-Team-Icon; 
      }
    }
    &.community {
      &:before {
        content: $icon-Community; 
      }
    }
    &.vision {
      &:before {
        content: $icon-Vision; 
      }
    }
    &.contacts {
      &:before {
        content: $icon-Contacts; 
      }
    }
    &.export_file {
      &:before {
        content: $icon-Export-file; 
      }
    }
    &.script {
      &:before {
        content: $icon-Script; 
      }
    }
    &.script-edit {
      &:before {
        content: $icon-Script-Edit; 
      }
    }

    &.paperwork {
      &:before {
        content: $icon-Paperwork; 
      }
    }
    &.building {
      &:before {
        content: $icon-Building; 
      }
    }
    &.financial_management {
      &:before {
        content: $icon-Financial-Management; 
      }
    }
    &.speed_optimization {
      &::before {
        content:  $icon-Speed-Optimization
      }
    }

}

  
  
