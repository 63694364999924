$btn-with: 3.125rem;
.search {
  position: relative;
  width: 100%;
  label ~ & {
    width: 16.88rem;
  }
  // border: solid 0.0625rem var(--color-border-2) !important;

  &.focus input,
  input:focus,
  input:active {
    padding-top: 0.0625rem;
  }
  label.focus {
    display: none;
  }

  &__filter-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: $btn-with;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    div {
      font-size: var(--font-size-3);
      color: var(--color-ey-gray-alt);
      font-family: var(--font-family-light) !important;
      position: absolute;
      bottom: 0.125rem;
      left: 0;
      width: 100%;
    }

    i {
      font-size: var(--font-size-8);
      color: var(--color-ey-gray-alt);
      position: absolute;
      top: 0.125rem;
      left: 0;
      width: 100%;
    }
  }
  &__result {
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 32.375rem;
    overflow-y: auto;
    background-color: var(--color-white);
    border: solid 0.0625rem var(--color-ey-gray-tint-1);
    text-align: left;

    &__people,
    &__info {
      .main-title {
        position: relative;
        padding: var(--spacing-04) var(--spacing-03) 0 var(--spacing-03);
        font-family: var(--font-family-bold);
        font-size: var(--font-size-2);
        color: var(--color-ey-grey-2);
        line-height: 0.75rem;
        margin: 0 0 var(--spacing-01) 0;

        &::before {
          content: " ";
          position: absolute;
          display: block;
          width: 95%;
          height: 0.0625rem;
          top: 0;
          left: 2.5%;
          background: var(--color-ey-gray-tint-1);
          margin: var(--spacing-02) 0;
        }
      }
    }

    &__item {
      line-height: var(--font-size-3);
      font-family: var(--font-family-light) !important;
      font-size: var(--font-size-3);
      color: var(--color-ey-confident-grey);
      padding: var(--spacing-03);
      border: solid 0.0625rem var(--color-white);
      text-transform: lowercase;
      display: flex;
      align-items: center;
      .title {
        font-family: var(--font-family-light) !important;
        font-size: var(--font-size-5);
        line-height: var(--spacing-04);
      }
      .desc {
        font-family: var(--font-family-light) !important;
        font-size: var(--font-size-3);
        text-transform: capitalize;
        span {
          font-family: var(--font-family-light) !important;
          font-size: var(--font-size-3);
          color: var(--color-ey-gray-tint-1);
          font-style: italic;
          font-weight: normal;
          text-transform: capitalize;
        }
      }
      .avatar--image {
        width: 2.5rem;
        height: 2.5rem;
      }
      span {
        font-weight: bold;
      }
      &:hover {
        background-color: var(--color-ey-grey-4);
        border: solid 0.0625rem var(--color-border);
        cursor: pointer;
        text-decoration: none;
        color: var(--color-ey-confident-grey);
        border-left: none;
        border-right: none;
      }
    }
  }

  &::after {
    @include material-icons(1.5rem);
    content: "search";
    line-height: 2.8125rem;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--spacing-08);
    height: 100%;
    background-color: transparent;
    cursor: pointer;
  }
  &.focus::after {
    content: "close";
  }
  &.disabled::after {
    color: var(--color-ey-grey-2);
    cursor: not-allowed;
  }
}

.search.search--wbtn {
  //override default search bar
  &::after {
    display: none;
    content: "";
  }
  //END OF: override default search bar

  .search__button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    color: inherit;
    &:focus {
      outline: none;
    }
    &::after {
      @include material-icons(1.5rem);
      content: "search";
      line-height: 2.8125rem;
      width: var(--spacing-08);
      background-color: transparent;
      cursor: pointer;
    } 
  }

  &.focus .search__button::after {
    content: "close";
  }
  &.disabled .search__button::after {
    color: var(--color-ey-grey-2);
    cursor: not-allowed;
  }
}

.search--filters {
  padding-left: $btn-with;

  .textinput-group__label {
    padding-left: $btn-with;
  }

  .search__result {
    width: calc(100% - 3.125rem) !important;
  }
}
