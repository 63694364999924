/* Global Styles */
* {
    font-family: var(--font-family);
}

html {
    font-size: var(--font-size-5);
}

body, p {
    font-size: var(--font-size-4);
}

html, body {
    color: var(--color-ey-dark-gray);
    height: 100%;
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: var(--font-size-8);
}

h3 {
    font-size: var(--font-size-6);
}

h4 {
    font-size: var(--font-size-4);
}

a {
    text-decoration: none !important;
    cursor: pointer;
}