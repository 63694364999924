/* begin global button styles */
@mixin icons-buttons($icon, $font-size, $margin: "0 0.5rem 0 0") {
  margin: $margin;
  font-family: var(--font-family-materialIcons);
  font-size: $font-size;
  content: $icon;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
  line-height: 0.75rem;
  vertical-align: bottom;
}
/* button primary start --------------------------------------- */
.btn.btn--primary {
  color: var(--color-ey-confident-grey);
  background-color: var(--color-ey-yellow);
}

/* the btn class should be universal css styling for most buttons */
.btn {
  @include fonts-col__sub-nav-selected-state;
  color: var(--color-ey-confident-grey);
  transition: all 0.3s ease;
  border-radius: 0;
  //all buttons need to have a transparent border to prevent bouncing and support in IE11
  //this will default value for all btn, to override outside btn, use border-color only
  border: 0.0625rem solid transparent;
  height: 2.813rem;
  vertical-align: middle;
  padding-top: 0.0625rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 0;
  margin: 0.3125rem;
  /*   buttons should not be smaller then 6.875rem */
  min-width: 6.875rem;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;

  /* fighting bootstrp in IE11  */
  &:hover,
  &.hover,
  &.focus,
  &:active,
  &.active {
    color: var(--color-white);
    background-color: var(--color-ey-confident-grey);
    border-color: 0.0625rem solid transparent; //default - prevent bouncing
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  /* disabled buttons start ------------------------------*/
  &.disabled,
  &:disabled {
    &,
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: var(--color-ey-grey-1);
      background-color: var(--color-ey-grey-2a); //no token
      border: 0.0625rem solid transparent; /* all buttons need to have a transparent border to prevent bouncing and support in IE11*/
      opacity: 1;
      cursor: not-allowed;
    }
  }

  /* all buttons that hosts icons need more margin */
  i {
    margin: 0 0.5rem 0 0;
  }

  & .material-icons {
    font-size: var(--font-size);
    vertical-align: bottom;
    line-height: inherit;
  }
}


/* button primary (dark option) start ------------------------- */
.btn--primary-dark {
  color: var(--color-white);
  background-color: var(--color-ey-confident-grey);
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: var(--color-ey-confident-grey);
      background-color: var(--color-ey-yellow);
    }
  }
}

/* button secondary starts here ------------------------ */
.btn--secondary {
  background: none;
  color: var(--color-ey-confident-grey);
  border-color: var(--color-ey-confident-grey);
}

/* button yellow starts here*/
.btn--yellow {
    background: rgb(250,231,38);
    color: var(--color-ey-confident-grey);
    border-color: var(--color-ey-confident-grey);
}

/* button tertiary starts here ------------------------ */
.btn--tertiary,
.btn--icon {
  background: none;
  color: var(--color-ey-confident-grey);
  border-color: var(--color-border-2);
  -webkit-box-shadow: none;
  box-shadow: none;
  /* all buttons active should have a grey border on selection/active */
  &:hover,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: var(--color-ey-grey-4);
    color: var(--color-ey-confident-grey);
    border-color: var(--color-border);
  }
}

/* button previous & next starts here ----------------- */
@mixin btn--previous-next($type) {
  border-color: var(--color-ey-confident-grey);
  padding: 0;
  @if $type==right {
    min-width: 5.0625rem;
    padding-left: 0.875rem;
    &::after {
      @include icons-buttons("chevron_right", 1.5rem);
      vertical-align: middle;
    }
  } @else if $type==left {
    min-width: 5.125rem;
    padding-right: 0.875rem;
    &::before {
      @include icons-buttons("chevron_left", 1.5rem, "0 0 0 0.5rem");
      vertical-align: middle;
    }
  }
}

.btn--next {
  @include btn--previous-next("right");
  background: none;
  color: var(--color-ey-confident-grey);
  border-color: var(--color-ey-confident-grey);
  -webkit-box-shadow: none;
  box-shadow: none;  
}
.btn--next-primary {
  @include btn--previous-next("right");
  border-color: var(--color-ey-confident-grey);
  color: var(--color-ey-confident-grey);
  background-color: var(--color-ey-yellow);
}

.btn--back {
  @include btn--previous-next("left");
}
.btn--final-step {
  background: var(--color-ey-yellow);
  min-width: 4.875rem;
  padding: 0;
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    border-color: transparent;
  }
}
/* button approved & deny starts here ----------------- */
@mixin btn--approved-deny($active-bgColor, $icon) {
  border-color: var(--color-ey-confident-grey);
  min-width: 9.9375rem;
  &:before {
    @include icons-buttons($icon, 1.5rem);
  }
  // &:hover ==> use default
  &:focus {
    border-color: transparent;
    background: $active-bgColor;
    color: var(--color-white);
  }
  //&:disabled ==> use default
}
.btn--approved {
  @include btn--approved-deny(#168736, "check");
}
.btn--deny {
  @include btn--approved-deny(#b9251c, "block");
}
/* button progress starts here ------------------------ */
@mixin btn--progress {
  &.in-progress:after,
  &.finished:after {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    padding-top: inherit;
    color: var(--color-white);
    left: 0;
  }

  & .tz-bar {
    background-color: var(--color-ey-confident-grey);
    height: 0.1875rem;
    bottom: -0.0625rem;
    left: -0.0625rem;
    width: 0;
    position: absolute;
    z-index: 1;
    -webkit-transition: width 0.5s, height 0.5s, background-color 0ms, color 0ms;
    -moz-transition: width 0.5s, height 0.5s, background-color 0ms, color 0ms;
    transition: width 0.5s, height 0.5s, background-color 0ms, color 0ms;
  }

  & .tz-bar.background-horizontal {
    height: 100%;
  }

  & .tz-bar.background-vertical {
    height: 0;
    top: 0;
    width: 100%;
  }
}

.btn--progress {
  @include btn--progress;
  position: relative;
  $parentClass: &;
  &#{$parentClass}-primary {
    background-color: var(--color-ey-yellow);
    border-color: var(--color-ey-confident-grey);
    color: var(--color-ey-confident-grey);
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: var(--color-ey-confident-grey);
      color: var(--color-white);
      & .tz-bar {
        background-color: var(--color-white);
      }
    }
  }
  &#{$parentClass}-primary-dark {
    background-color: var(--color-ey-confident-grey);
    color: var(--color-white);
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: var(--color-ey-yellow);
      color: var(--color-ey-confident-grey);
      & .tz-bar {
        background-color: var(--color-ey-confident-grey);
      }
    }
    & .tz-bar {
      background-color: var(--color-white);
    }
  }
  &#{$parentClass}-secondary {
    background: var(--color-white);
    border-color: var(--color-ey-confident-grey);
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: var(--color-ey-confident-grey);
      color: var(--color-white);
      border-color: transparent;
      & .tz-bar {
        background-color: var(--color-white);
      }
    }
  }
  &#{$parentClass}-tertiary {
    position: relative;
    background-color: var(--color-white);
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: var(--color-ey-grey-4);
      color: var(--color-ey-confident-grey);
      border-color: var(--color-border);
    }
  }
}

/* button utilitiy starts here ------------------------- */
.btn--utility {
  border-color: transparent;
  background: var(--color-ey-grey-2);
  color: var(--color-ey-confident-grey);
}

/* button delete, warning starts here ------------------------ */
.btn--delete,
.btn--warning {
  background: var(--color-red-06);
  color: var(--color-white);
}

/* start button link css ---------------------------- */

.btn--link {
  background-color: transparent;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-4);
  padding: 0;
  vertical-align: middle;
  span {
    font-family: var(--font-family-bold) !important;
  }
  i {
    font-size: var(--font-size-5);
  }

  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: var(--color-ey-confident-grey);
      background-color: transparent;
      span {
        text-decoration: underline;
      }
    }
  }

  &:disabled,
  &.disabled {
    background-color: transparent;
    color: var(--color-ey-grey-1);
    &:hover,
    &.hover,
    &:focus,
    &.focus {
      background-color: transparent;
    }
  }
}

.btn--link-blue {
  color: var(--color-ey-link);

  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &:not(:disabled):not(.disabled) {
      color: var(--color-ey-link);
    }
  }
}

.btn--icon {
  width: 3.125rem;
  height: 3.25rem;
  min-width: 3.125rem;
  min-height: 3.25rem;
  padding: 0;
  & i {
    margin: 0;
  }
}
