// Form Borders: [*__textinput *__label, *__input],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Default Border color
//    - var(--color-disabled) {string} : Disabled Border color
@mixin form-input-border(
  $color: var(--color-ey-confident-grey),
  $color-disabled: var(--color-gray-disabled-alpha),
  $color-error: var(--color-red-06)
) {
  $parentClass: &;

  border: 0 solid transparent;
  border-radius: 0;

  &__textinput,
  &__textarea,
  &__input {
    border: 0 solid transparent;
    border-bottom: 0.0625rem solid $color;
    outline: none;

    //set :hover, :focus, :active, .error, :disabled in this order
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-bottom-width: 0.125rem;
    }

    &.error,
    #{$parentClass}.error & {
      border-bottom-color: var(--color-error);
    }

    &:disabled,
    &.disabled,
    #{$parentClass}.disabled & {
      border: 0.0625rem solid var(--color-disabled);
    }
  }
}

// Form Colors: [*__textinput *__label *__input],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - var(--color-error) {string} : Error label/msg/icon color
@mixin form-input-color(
  $color: var(--color-ey-confident-grey),
  $color-bg: var(--color-ey-grey-4),
  $color-bg-alt: var(--color-ey-grey-2a),
  $color-error: var(--color-red-06)
) {

  $parentClass: &;

  background-color: transparent;

  // set background and font color
  &__textinput,
  &__textarea,
  &__input {
    cursor: pointer;

    //set :hover, :focus, :active, .error, :disabled in this order
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      &,
      &.error,
      #{$parentClass}.error & {
        background-color: $color-bg-alt;
      }
    }

    &,
    &.error,
    &:disabled,
    &.disabled,
    #{$parentClass}.error &,
    #{$parentClass}.disabled & {
      background-color: $color-bg;
      color: $color;
    }

    &:disabled,
    &.disabled,
    #{$parentClass}.disabled & {
      opacity: 0.6;
      cursor: not-allowed;
      ~ #{$parentClass}__label {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  &__label {
    background-color: transparent;
    color: $color;
    #{$parentClass}:disabled &,
    #{$parentClass}.disabled & {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__errmsg {
    color: var(--color-error);
  }
}

// Label required style
// use by: .textinput-group__label
//         .authentication-input__label
@mixin form-input-label-required() {
  &.asterisk,
  &:required {
    &::after {
      content: "(required)";
      padding-left: 0.4rem;
    }
  }
}

/* INPUT TEXT ---css start here----------*/
/* Note: this style is also used by the dropdown component*/

/*--for wrapper element only--*/
.textinput-type__type {
  width: 2.813rem;
  height: 2.813rem;
  margin-right: 1.375rem;
  font-size: var(--font-size-2);
  color: var(--color-ey-confident-grey);
  text-align: right;
}

/*--main group of the component (DIV)*/
.textinput-group {
  @include form-input-border;
  @include form-input-color;

  ///dark theme ---------
  .dark & {
    @include form-input-border(
      $color: var(--color-ey-confident-grey),
      $color-disabled: var(--color-ey-grey-1)
    );
    @include form-input-color(
      $color: var(--color-ey-confident-grey),
      $color-bg: var(--color-white),
      $color-bg-alt: var(--color-ey-grey-2a),
      $color-error: var(--color-red-06)
    );
  }

  width: 100%;
  position: relative;
  z-index: 0;
}

/*-- component's input box  */
.textinput-group__textinput {
  @include fonts-col__form-field-inputs;
  font-size: var(--font-size-4) !important;
  height: 2.813rem;
  width: 100%;
  text-align: left;
  padding-left: 0.75rem;
  padding-top: 0.75rem;
}

select.textinput-group__textinput {
  padding: 0 0.75rem;
}

/*-- component's label  */
.textinput-group__label {
  @include fonts-col__form-field-input-labels(0.875rem);
  @include form-input-label-required;

  position: absolute;
  left: 0.75rem;
  top: 0.75rem;
  transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin-bottom: 0;
}

.textinput-group__label.focus {
  @include fonts-col__field-labels-on-focus-light;
  font-size: var(--font-size-3) !important; //override
  height: 0.625rem;
  top: 0;
}

/*-- component's error message  */
.textinput-group__errmsg {
  @include fonts-col__field-labels-on-focus;
  font-size: var(--font-size-3) !important; //override
  width: 100%;
  text-align: left;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  line-height: 1.8 !important;
  margin-top: 0.25rem;
  &:before {
    @include material-icons(1rem);
    content: 'error_outline';
    color: inherit;
    margin-right: 0.5rem;
  }
}

/* INPUT FILE */
.textinput-group {
  &__fileinput-wrapper {
    margin-right: var(--spacing-02);
  }
  & > .textinput-group__textinput[type="file"] {
    display: none;
  }
}

.textinput-group--full {
  height: 2.813rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: var(--font-size-4);
  font-family: var(--font-family-light) !important;
  color: var(--color-ey-confident-grey);
  .textinput-group__textinput {
    padding-top: 0;
  }

  .textinput-group__errmsg {
    padding: 0;
    width: auto;
    position: static;
    margin: 0;
    font-size: var(--font-size-3);
    font-family: var(--font-family-light) !important;
    color: var(--color-ey-confident-grey);
    margin-right: var(--spacing-02);
    @media only screen and (max-width: var(--break-point-medium)) {
      position: absolute;
      left: 0;
      bottom: -1.25rem;
      top: auto;
    }
    &::before {
      content: "";
    }
  }
  .textinput-group__textinput--error {
    outline: var(--color-red-06) solid 0.0625rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 0 0 0 3.4375rem;
  }
  .textinput-group__icon {
    position: absolute;
    left: var(--spacing-05);
    top: 25%;
  }
  .textinput-group__btn {
    border: none;
    font-size: var(--font-size-4);
    font-family: var(--font-family-bold) !important;
    font-size: var(--font-size-4);
    margin-right: var(--spacing-03);
    background: transparent;
  }
}

.btn.btn--primary.textinput-group__button {
  outline: var(--color-ey-yellow) solid 0.0625rem;
  outline-offset: 0;
  border-radius: 0;
  height: 2.813rem;
  width: 8.875rem;
  margin: 0 0 0 0.04rem;
  border-width: 0;
  &:hover,
  &:focus {
    outline: var(--color-ey-confident-grey) solid 0.0625rem;
  }
}

/* INPUT PASSWORD */
.textinput-group__textinput {
  &[type="password"] {
    //override, EYFonts not showing standard password masking symbol
    font-family: var(--font-family-notoSans) !important;
    font-size: var(--font-size-5) !important;
  }
}

// for format: <i></i> (old format, compatibility)
.textinput-group__togglepwdtext {
  color: var(--color-ey-gray-alt2);
  position: absolute;
  z-index: 999;
  font-size: var(--font-size-7);
  transform: translate(calc(-100% + -0.625rem), 0.925rem);
  cursor: pointer;

  &.show:before {
    content: "";
    width: 0.125rem;
    height: 100%;
    border-left: 0.0625rem solid var(--color-ey-gray-alt2);
    border-right: 0.0625rem solid var(--color-ey-grey-4);
    position: absolute;
    transform: rotate(-45deg) translate(0.4rem, 0.4rem);
  }

}
// for format: <button><i></i></button> (accessibility-tabstop)
button.textinput-group__togglepwdtext {
  top: 0;
  right: 0;
  border: none;
  background-color: transparent;
  transform: translate(-0.1875rem, 0.4375rem);
  position: absolute;
  height: 2rem;
  width: 2rem;
  padding: 0;
  line-height: 0;
  .material-icons {
    font-size: inherit;
  }
  &.show:before {
    height: 65%;
    // transform: rotate(-45deg) translate(0.2rem, 0.5rem);
  }
}

// -------------- Text Area Input --------------------
$textarea_height: 6.125rem;
$textarea_labelheight: 1.875rem;

// Form Colors for TextArea: [*__textinput *__label],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - var(--color-error) {string} : Error label/msg/icon color
@mixin form-textarea-color(
  $color: var(--color-ey-confident-grey),
  $color-bg: var(--color-ey-grey-4),
  $color-bg-alt: var(--color-ey-grey-2a),
  $color-error: var(--color-red-06)
) {
  $parentClass: &;

  // set background and font color
  //set :hover, :focus, :active, .error, :disabled in this order
  &:hover,
  &.hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    &,
    &.error {
      background-color: $color-bg-alt;
    }
  }

  &,
  &.error,
  &:disabled,
  &.disabled {
    background-color: $color-bg;
    #{$parentClass}__textarea,
    #{$parentClass}__label {
      color: $color;
    }
  }

  &__textarea,
  &__label {
    background-color: transparent;
    cursor: pointer;
    #{$parentClass}:disabled &,
    #{$parentClass}.disabled & {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &__errmsg {
    color: var(--color-error);
  }
  &.completed {
    border-bottom-width: 0.0625rem;
    background-color: $color-bg;
  }
  &:hover {
    background-color: $color-bg-alt;
  }
}

// Form Borders for TextArea: [*__textarea *__label],
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Default Border color
//    - var(--color-disabled) {string} : Disabled Border color
@mixin form-textarea-border(
  $color: var(--color-ey-confident-grey),
  $color-disabled: var(--color-gray-disabled-alpha),
  $color-error: var(--color-red-06)
) {
  & {
    $parentClass: &;
    border: 0 solid transparent;
    border-bottom: 0.0625rem solid $color;
    outline: none;

    //set :hover, :focus, :active, .error, :disabled in this order
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-bottom-width: 0.125rem;
    }

    &.error,
    #{$parentClass}.error & {
      border-bottom-color: var(--color-error);
    }

    &:disabled,
    &.disabled,
    #{$parentClass}.disabled & {
      border: 0.0625rem solid var(--color-disabled);
    }
  }

  &__textinput,
  &__textarea {
    border: 0 solid transparent;
    border-radius: 0;
    &:focus,
    &.focus,
    &:active,
    &.active {
      outline: none;
    }
  }
}

.textarea-group {
  @include form-textarea-border;
  @include form-textarea-color;

  // Dark Theme ------
  .dark & {
    @include form-textarea-color(
      $color: var(--color-ey-confident-grey),
      $color-bg: var(--color-white),
      $color-bg-alt: var(--color-ey-grey-2a),
      $color-error: var(--color-red-06)
    );
    @include form-textarea-border(
      $color: var(--color-ey-confident-grey),
      $color-disabled: var(--color-ey-grey-1)
    );
  }

  display: flex !important;
  flex-direction: column !important;
  width: 21.75rem;
  height: $textarea_height;
  z-index: 0;

  /*-- component's label  */
  .textarea-group__label {
    display: flex !important;
    @include fonts-col__form-field-input-labels;
    line-height: 0.75rem !important; //override
    width: 100%;
    text-align: left;
    position: static;
    padding-left: 0.75rem;
    vertical-align: top;
    margin: 0;

    //dynamic based on element state
    padding-top: 1rem;
    height: $textarea_labelheight;
    -webkit-transition: padding-top 0.15s;
    transition: padding-top 0.15s;
  }
  .textarea-group__errmsg {
    @include fonts-col__field-labels-on-focus;
   font-size: var(--font-size-3) !important; //override
    font-weight: normal;
    padding-left: 0.75rem;
    width: 100%;
    text-align: left;
    position: relative;
    top: -1.8rem;
    display: none;
  }
}
/*-- component's textarea box  */
.textarea-group__textarea {
  display: flex !important;
  @include fonts-col__form-field-inputs;
  font-size: var(--font-size-4) !important; //override
  font-weight: 300;
  width: 100%;
  text-align: left;
  padding-left: 0.75rem;
  padding-top: 0.625rem;
  margin-top: 0;
  overflow: auto;
  resize: none;

  //dynamic based on element state
  height: calc(#{$textarea_height} - #{$textarea_labelheight});
}

.textarea-group.focus,
.textarea-group[focus],
text-area-input.focus .textarea-group,
text-area-input[focus] .textarea-group {
  $textarea_height: 6.125rem;
  $textarea_labelheight: 1.125rem;
  .textarea-group__textarea {
    height: calc(#{$textarea_height} - #{$textarea_labelheight});
  }
  .textarea-group__label {
    padding-top: 0.25rem;
    height: $textarea_labelheight;
  }
}

.textarea-group.active,
.textarea-group[active],
text-area-input.active .textarea-group,
text-area-input[active] .textarea-group {
  $textarea_height: 6.125rem;
  $textarea_labelheight: 1.125rem;
  .textarea-group__textarea {
    height: calc(#{$textarea_height} - #{$textarea_labelheight});
  }
  .textarea-group__label {
    padding-top: 0.25rem;
    height: $textarea_labelheight;
  }
}

.textarea-group.error,
.textarea-group[error],
text-area-input.error .textarea-group,
text-area-input[error] .textarea-group {
  margin-bottom: 1.125rem;
  .textarea-group__errmsg {
    display: inline-block;
    position: static;
  }
}
// END OF-------- Text Area Input --------------------

// -------------- Authentication Numeric Input --------------------

.authentication-input {
  @include form-input-border;
  @include form-input-color;

  // Insert dark theme style here
  ///dark theme ---------
  // .dark & {
  //   @include form-input-border(......);
  //   @include form-input-color(.......);
  // }

  &__input {
    -webkit-appearance: textfield;
    -moz-appearance: textfield; /* Firefox */
    appearance: textfield;

    padding: 0.5rem;
    text-align: center;
    width: 2.813rem;
    height: 2.813rem;
    font-family: var(--font-family) !important;
   font-size: var(--font-size-3);
    font-weight: 300;
    margin-right: 0.625rem;

    &:hover,
    &.hover,
    &:focus,
    &.focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
  }

  &__label {
    @include fonts-col__form-field-input-labels($font-size: 0.75rem);
    line-height: 0.75rem !important; //override
    font-weight: 300;
    height: 0.75rem;
    text-align: left;
  }

  &__errmsg {
    @include fonts-col__field-labels-on-focus($line-height: 0.75rem);
   font-size: var(--font-size-3) !important; //override
    font-weight: 300;
    width: 100%;
    text-align: left;
    padding-top: 0.3125rem;
    display: none;
    .error > & {
      display: block;
      &:after {
        @include material-icons(1rem);
        content: "error";
        color: inherit;
        position: relative;
        left: calc(50% + 0.53rem);
        top: -2.2rem;
      }
    }
  }
}
// END OF-------- Authentication Numeric Input --------------------

// --------------
.richtext-editor {
  border: 0.0625rem solid var(--color-ey-grey-2);
  border-radius: 0;

  .navbar {
    justify-content: flex-start;
    background: var(--color-ey-grey-4);
    border-radius: 0;
    padding: 0.125rem 0.375rem !important;
    border-bottom: 0.0625rem solid var(--color-border-2);

    .navbar__menu {
      display: flex;
      padding: 0 0.25rem;
      .navbar_link {
        padding: 0.25rem 0.46875rem 0 0.46875rem;
        margin: 0;
      }
    }

    & .navbar__pipe {
      width: 0.0625rem;
      height: 1.625rem;
      border-left: 0.0625rem solid var(--color-border-2);
      margin-top: 0.4375rem;
      margin-left: 0.25rem;
    }
  }
  .material-icons {
    font-size: var(--font-size-7);
    width: 1.5rem;
    height: 1.25rem;
  }
  &__textarea {
    height: 7.625rem;
    color: var(--color-ey-confident-grey);
    font-size: var(--font-size-4);
    line-height: 1.375rem;
    margin: 0.8125rem 0.9375rem;
    border: none;
    resize: none;
  }
}

@media (max-width: 1500px) and (min-width: 576px) {
  .richtext-editor {
    .navbar .navbar__menu {
      display: flex !important;
    }
  }
}
