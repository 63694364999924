.spinner {
  $border-color: var(--color-gray-alt-2);
  $active-area-color: var(--color-ey-confident-grey);
  $spinner-speed: 0.7s;
  $animation: linear;
  display: flex;
  align-items: center;
  &__label {
    color: var(--color-ey-grey-1);
    font-size: var(--font-size-3);
    font-weight: normal;
    margin: 1.25rem 0 0 0;
    text-align: center;
  }
  &__icon {
    &::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      border-radius: 50%;
      animation: spinner $spinner-speed $animation infinite;
    }

    &--small {
      width: 1rem;
      height: 1rem;
      &::before {
        height: 1rem;
        width: 1rem;
        border: 0.125rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }

    &--medium {
      width: 1.5rem;
      height: 1.5rem;
      &::before {
        height: 1.5rem;
        width: 1.5rem;
        border: 0.1875rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }

    &--large {
      width: 2.5rem;
      height: 2.5rem;
      &::before {
        height: 2.5rem;
        width: 2.5rem;
        border: 0.25rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }
    &--xxlarge {
      width: 6.25rem;
      height: 6.25rem;
      margin: 0 auto;
      &::before {
        height: 6.25rem;
        width: 6.25rem;
        border: 0.25rem solid $border-color;
        border-top-color: $active-area-color;
      }
    }
    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }
}

.progress-group {
  & .progress-group__label {
    font-size: var(--font-size-3) !important;
    font-family: var(--font-family-light) !important;
    height: 1.1875rem;
    color: var(--color-ey-confident-grey);
    text-align: left;
    margin-bottom: 0.125rem;
  }
  & .progress {
    //override bootstrap
    height: 0.375rem;
    background: var(--color-ey-grey-3);
    border-radius: 0;
  }
  & .progress-bar {
    //override bootstrap
    background: var(--color-ey-confident-grey);
  }
}

.progress-group--fileloader {
  border: 0.0625rem solid var(--color-border-2);
  height: 2.8125rem;

  & .progress-group__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include fonts-col__body-copy;
    color: var(--color-ey-confident-grey);
    background: var(--color-ey-grey-5);
    text-align: left;
    padding: 0.8125rem 1.25rem;
    height: 2.5rem;
    margin: 0;
    & .material-icons {
      width: 1.5rem;
      height: 1.5rem;
      font-size: var(--font-size-8);
      margin-right: 0.625rem;
      color: var(--color-ey-confident-grey);
    }
    & div,
    & i {
      color: var(--color-ey-confident-grey);
    }
    & div {
      @include fonts-col__body-copy(0.75rem);
    }
    & div:nth-child(2) {
      flex-grow: 1;
    }
    & .progress-group__label-size{
     font-size: var(--font-size-3) !important;
    }

    & .progress-group__close{
      width: 1.5rem;
      height: 1.5rem;
      font-size: var(--font-size-4);
      border: none;
      background-color: transparent;
      color: var(--color-ey-confident-grey);
      padding: 0;
      margin: 0 0 0 0.9375rem;
      i{
        margin: 0;
      }
    }
  
  }
  & .progress {
    //override bootstrap
    height: 0.1875rem;
    border-radius: 0;
    background-color: var(--color-ey-grey-5);
  }
  & .progress-bar {
    //override bootstrap
    background:var(--color-ey-confident-grey);
  }
}
