@mixin header-menu-group($padding-top: 0.5625rem) {
  background-color: transparent;
  border: none;
  height: 2.813rem;
  display: -webkit-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: $padding-top;
}

@mixin header-menu-group-state() {
  &:hover,
  &:focus,
  &:active {
    background-color: var(--color-ey-interaction-overlays);
    outline: none;
  }
}

/* header */
.page-header {
  position: relative;
  background: var(--color-ey-confident-grey);
  width: 100%;
  display: flex;
  grid-area: head;
  justify-content: space-between;
  align-items: center;
  & .header-group {
    display: -webkit-box !important;
    display: flex !important;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    flex-direction: row !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    &__labels-container {
      margin-top: 0;
      padding-right: var(--spacing-03);
      padding-top: 0.5625rem;
      text-align: left;
      @media (max-width: var(--break-point-large)) {
        min-height: 2rem;
        margin-top: 0;
        margin-bottom: 0;
      }
      & .header-group__labels {
        padding: 0;
        margin: 0;
      }
      & .header-group__sub-label {
        sup {
          top: 0.3rem;
        }
       font-size: var(--font-size-3) !important;
        font-family: var(--font-family-default) !important;
        display: flex;
        margin: 0;
        padding: 0;
        line-height: 0.875rem !important;
        font-style: italic;
        color: var(--color-ey-grey-2);
        display: none;
        @media (max-width: var(--break-point-large)) {
          font-size: var(--font-size-1) !important;
          line-height: 0 !important;
          sup {
            top: 0;
          }
        }
        @media (max-width: 438px) {
          line-height: 1 !important;
        }
      }
    }
    &__aux-responsive {
      display: none;
      margin: auto;
      vertical-align: middle;
      margin-right: 1.0625rem;
      color: var(--color-white);
      @media (max-width: var(--break-point-large)) {
        @include header-menu-group(0);
        button {
          @include header-menu-group-state;
        }
      }
      & .header-group__show-options,
      & .header-group__show-menu {
        background: transparent;
        border: none;
        height: 3.125rem;
        width: 3.125rem;
        color: var(--color-white);
        display: inline-block;
        line-height: 2;
        font-size: 1.1875rem;
      }
      & .header-group__show-options:before {
        font-family: var(--font-family-materialIcons);
        content: "build";
      }
      & .header-group__show-menu:before {
        font-family: var(--font-family-materialIcons);
        content: "menu";
      }
    }
  }
  & .userprofile-group {
    @include header-menu-group(0);
    margin-right: 0.3125rem;
    position: absolute;
    right: 0;
    & a {
      padding-top: 0.5625rem;
      padding-left: 0.5625rem;
      padding-right: 0.5625rem;
      height: 100%;
      @include header-menu-group-state;
    }
  }
  &:not(.page-header--sample) .userprofile-group {
    @media (max-width: var(--break-point-large)) {
      display: none;
    }
  }

  @media (max-width: var(--break-point-large)) {
    height: 3.125rem;
  }
}

.header-group__image {
  margin: 0.6875rem 0.9375rem 0.75rem 0.9375rem;
  height: 1.688rem;
  width: 1.563rem;
  vertical-align: middle;
  border-style: none;

  @media (max-width: var(--break-point-large)) {
    height: 1.688rem;
    width: 1.563rem;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.header-group__labels {
  font-family: var(--font-family-light) !important;
  font-size: 0.9375rem !important;
  line-height: 0.6875rem !important;
  font-weight: 400;
  line-height: 11px;
  height: 11px;
  color: var(--color-white);
  padding: 0 0.9375rem;
  @media (max-width: var(--break-point-large)) {
    padding-left: var(--spacing-03);
    padding-right: 0.375rem;
    padding-top: 0;
  }
  & .material-icons {
    @media (max-width: var(--break-point-large)) {
      vertical-align: middle;
      font-size: 0.9375rem;
    }
  }
}

button.header-group__labels {
  //override button styles
  @include header-menu-group;
  @include header-menu-group-state;
}

.header-group__divider {
  width: 0;
  height: 2rem;
  margin: 0 0 0 0.5rem;
  border-left: 0.0625rem solid var(--color-ey-grey-1);
  @media (max-width: var(--break-point-large)) {
    height: 2rem;
    margin: 0;
  }
}

/* User Profile on header */

.userprofile-group__help {
  @include fonts-col__sub-nav-default-state;
  color: var(--color-white);
  float: left;
  &:hover {
    color: var(--color-white);
    text-decoration: none;
    & > .material-icons {
      color: var(--color-white);
    }
  }

  .material-icons {
    color: var(--color-white);
    font-size: var(--font-size-7);
    position: relative;
    top: 0.3rem;
    margin-right: 0.5rem;
  }
}

/* User Logout dropdown component */

.userprofile__dropdown {
  @include header-menu-group(0);
  background: var(--color-ey-confident-grey);
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  & a {
    @include fonts-col__sub-nav-default-state;
    color: var(--color-white);
  }
  .dropdown-toggle {
    &:active,
    &:focus,
    &:visited {
      color: var(--color-white);
    }
    &:hover {
      color: var(--color-white);
      text-decoration: none;
      .material-icons {
        color: var(--color-white);
      }
    }
    &::after {
      margin-left: 0.255em;
      vertical-align: 0.255em;
    }
    .material-icons {
      color: var(--color-white);
      font-size: var(--font-size-7);
      position: relative;
      top: 0.3rem;
      margin-right: 0.5rem;
    }
  }

  .dropdown-menu {
    padding: 0;
    min-width: 12.5rem;
    background: var(--color-white);
    border: 1px solid var(--color-ey-confident-grey);
    border-radius: 0px;
    box-shadow: -0.125rem -0.125rem 0.3125rem 0 rgba(var(--color-ey-interaction-overlays-rgb), 0.2);
  }

  .dropdown-item,
  a.dropdown-item {
    font-family: var(--font-family-light);
    font-size: var(--font-size-4);
    color: var(--color-ey-confident-grey);
    line-height: 1.313rem;
    height: 2.813rem;
    padding-top: 0.625rem;
    padding-left: 0.875rem;
    width: calc(100% - 0.0625rem);
    &:hover,
    &:focus,
    &:active {
      &,
      .page-header & {
        background: var(--color-ey-grey-2a);
        outline: none;
      }
    }
    .material-icons {
      font-size: var(--font-size-5);
      position: relative;
      top: 0.1875rem;
      padding-right: 0.375rem;
    }
  }
}

/* User Profile as sample */

.page-header.page-header--sample {
  margin: auto;
  width: fit-content;
  .userprofile-group {
    position: relative;
    margin: 3rem;
    width: auto;
  }
}
