.story-card-rollup-container {
  display: inline-flex;
}

.story-card-rollup {
  margin: 0.5rem 1rem;
  color: var(--color-ey-dark-gray);

  &__amount {
    font-size: var(--font-size-9);
    font-weight: bold;
    line-height: 1.875rem;
    padding: 0;
    margin: 0;
    &--active {
      color: var(--color-ey-link-blue);
    }
    & .material-icons {
      font-size: var(--font-size);
      color: var(--color-ey-red);
      margin: 0.25rem 0rem 0rem 0rem;
      vertical-align: top;
    }
  }

  &__sub-title {
   font-size: var(--font-size-3);
    line-height: 1.0625rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0.25rem 0rem 0rem 0rem;
    display: block;
    &--active {
      color: var(--color-ey-link-blue);
    }
  }
}
