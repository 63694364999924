@import "../colors";

.avatar {
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: solid 0.125rem var(--color-white);
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
  &::after {
    text-transform: none;
    position: absolute;
    top: -0.125rem;
    right: -0.5rem;
    background-color: var(--color-white);
    border-radius: 50%;
    display: block;
    width: 0.8125rem;
    height: 0.8125rem;
    line-height: 0.8125rem;
    font-family: var(--font-family-materialIcons);
    font-size: var(--font-size-4);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: "liga";
  }
  &--description {
    display: block;
    color: var(--color-white);
    font-size: var(--font-size-4);
    font-family: var(--font-family-light) !important;
  }
  &--image {
    box-sizing: content-box;
  }
  &--small {
    width: 1.875rem;
    height: 1.875rem;
    margin: 0.4375rem 0.625rem 0.4375rem 0;
    @include fonts-col__body-copy(1.5625rem);
  }
  &--medium {
    width: 2.4375rem;
    height: 2.4375rem;
    margin: 1.1875rem 0.625rem 0.4375rem 0;
    @include fonts-col__body-copy(1.5625rem);
  }
  &--large {
    width: 3.125rem;
    height: 3.125rem;
    margin: 0.4375rem 0.625rem 0.4375rem 0;
    font-family: var(--font-family-light) !important;
    font-size: 1.625rem;
    line-height: 2.625rem;
  }

  &--approved {
    box-shadow: 0 0 0 0.125rem var(--color-green-06);
    box-sizing: content-box;
    margin-right: 1.5625rem;
    &::after {
      color: var(--color-green-06);
      content: "check_circle";
    }
  }

  &--failed {
    box-shadow: 0 0 0 0.125rem var(--color-red-06);
    margin-right: 1.5625rem;
    box-sizing: content-box;
    &::after {
      color: var(--color-red-06);
      content: "cancel";
    }
  }

  &--on-hold {
    box-shadow: 0 0 0 0.125rem var(--color-orange-02);
    box-sizing: content-box;
    margin-right: 1.5625rem;
    &::after {
      color: var(--color-orange-02);
      content: "error";
    }
  }

  &--default {
    box-shadow: 0 0 0 0.125rem rgba(var(--color-ey-grey-2), 0.35);
    box-sizing: content-box;
    margin-right: 1.5625rem;
    &::after {
      display: none;
    }
  }
  &:hover {
    color: var(--color-white);
    text-decoration: none;
  }
}
