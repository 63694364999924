.breadcrumb {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  border-radius: 0;
  background-color: var(--color-ey-confident-grey);
  min-height: 4.6875rem;
  padding: 0;
  width: 100%;
  a {
    color: var(--color-white);
    text-decoration: none;
  }
  &__item:first-child {
    padding: 0px 0.625rem 0 0.3125rem;
  }
  &__item {
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    position: relative;
    padding: 0 var(--spacing-04);
    &::after {
      content: "";
      width: 1px;
      height: 75%;
      position: absolute;
      top: 15%;
      right: 0;
      background-color: var(--color-gray-alt-1);
    }
  }

  &__section {
    font-size: var(--font-size-3);
    font-family: var(--font-family-light) !important;
    cursor: pointer;
    padding-right: 0.75rem;
    &--back-btn {
      &::before {
        width: 1.5625rem;
        display: inline-block;
        vertical-align: middle;
        font-family: var(--font-family-materialIcons);
        content: "keyboard_arrow_left";
        font-size: var(--font-size-9);
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: "liga";
      }
    }
  }

  &__page {
    font-size: var(--font-size-5);
    font-family: var(--font-family) !important;
    cursor: pointer;
  }

  &--light {
    background-color: var(--color-white);

    a {
      color: var(--color-ey-confident-grey);
    }
    .breadcrumb__item {
      color: var(--color-ey-confident-grey);
      &::after {
        background-color: var(--color-border-2) !important;
      }
    }
  }
}
