:root {
    
    /* Primary EY Color Palette */

    --color-ey-interaction-overlays: #1a1a24;
    --color-ey-confident-grey: #2e2e38;
    --color-ey-grey-1: #747480;
    --color-ey-grey-2: #c4c4cd;
    --color-ey-grey-2a: #e1e1e6;
    --color-ey-grey-3: #e7e7ea;
    --color-ey-grey-3a: #dee2e6;
    --color-ey-grey-3b: #dbdbdb;
    --color-ey-grey-4: #f6f6fa;
    --color-ey-grey-5: #fafafc;
    --color-ey-grey-5a: #f8f9fa;
    --color-ey-grey-6: #3c3c49;
    --color-ey-grey-7: #969696;
    --color-ey-grey-8: #2e2f30;
    --color-ey-grey-9: #353441;
    --color-ey-grey-10: #656579;
    --color-ey-grey-11: #dedee2;
    --color-ey-grey-12: #eaeaf2;
    --color-ey-grey-13: #D7D7DC;
    --color-ey-grey-14: #5F5F72;
    --color-ey-grey-15: #E6E6E9;
    --color-ey-grey-16: #E3EBF6;
    --color-ey-grey-17: #EEEEF3;
    --color-ey-grey-18: #7F7F91;
    --color-ey-grey-19: #BFBFC8;
    --color-ey-grey-20: #d1d1d1;
    --color-gray: #6c757d;
    --color-white: #ffffff;
    --color-ey-yellow: #ffe600;
    --color-ey-yellow-alt: #fdce3f;
    

    /* Secondary EY Color Palette */

    /* maroon */

    --color-maroon-01: #c981b2;
    --color-maroon-02: #b14891;
    --color-maroon-03: #922b73;
    --color-maroon-04: #750e5c;
    --color-maroon-05: #5a0a42;
    --color-maroon-06: #42152d;
    --color-maroon-07: #351c21;
    --color-maroon-08: #DA5BB3;

    /* red */

    --color-red-01: #ff9a91;
    --color-red-02: #ff736a;
    --color-red-03: #f95d54;
    --color-red-04: #ff4136;
    --color-red-05: #e0362c;
    --color-red-06: #b9251c;
    --color-red-06a: #ae120f;
    --color-red-07: #7a130d;
    --color-red-08: #FDBAAB;
    --color-red-09: #C70117;
    --color-red-10: #EA011D;

    /* orange */

    --color-orange-01: #ffb46a;
    --color-orange-02: #ff9831;
    --color-orange-03: #ff810a;
    --color-orange-04: #ff6d00;
    --color-orange-05: #f76900;
    --color-orange-06: #eb4f00;
    --color-orange-07: #bc2f00;
    --color-orange-08: #D07200;
    --color-orange-09: #FDBC84;  
    --color-orange-10: #EE8300;
    --color-orange-11: #EBC8A9;
    --color-orange-12: #ED6500;
    --color-ey-orange-alt: #f1951c;

    /* green */

    --color-green-01: #8ce8ad;
    --color-green-02: #57e188;
    --color-green-03: #34c768;
    --color-green-04: #2db757;
    --color-green-05: #189d3e;
    --color-green-06: #168736;
    --color-green-07: #13652a;
    --color-green-08: #7ed321;
    --color-green-09: #1F893F;
    --color-green-10: #48E674;
    --color-green-11: #ACE2BF;
    --color-green-12: #26A04B;

    /* teal */


    --color-teal-01: #93f0e6;
    --color-teal-02: #60e6e1;
    --color-teal-03: #42c9c2;
    --color-teal-04: #27acaa;
    --color-teal-05: #109090;
    --color-teal-06: #0d7575;
    --color-teal-07: #004f4f;

    /* blue */

    --color-blue-01: #87d3f2;
    --color-blue-02: #4ebeeb;
    --color-blue-03: #35a4e8;
    --color-blue-03a: #00a0dc;
    --color-blue-04: #188ce5;
    --color-blue-05: #1777cf;
    --color-blue-05a: #0d6efd;
    --color-blue-06: #155cb4;
    --color-blue-07: #082c65;
    --color-blue-08: #007bff;
    --color-blue-09: #20202b;
    --color-blue-10: #1275C1;


    /* purple */

    --color-purple-01: #9c82d4;
    --color-purple-02: #724bc3;
    --color-purple-03: #542ea5;
    --color-purple-04: #3d108a;
    --color-purple-05: #240d75;
    --color-purple-06: #0a095a;
    --color-purple-07: #15173e;

    /* States EY Color Palette */
    --color-prompt-label-text: hsl(240 10% 20% / 0.8);
    --color-inactive-icon-text: hsl(240 10% 20% / 0.5);
    --color-error: var(--color-red-06);
    --color-warning: var(--color-orange-02);
    --color-success: var(--color-green-06);
    --color-ey-link: var(--color-blue-06);
    --color-validation-error: var(--color-error);

    /* Colors from GTP UI Guide */

    /* Primary Grays */

    --color-black: #000000;
    --color-black-container: #1c1c27;
    --color-ey-dark-gray: #333333;
    --color-ey-gray-alt: #646464;
    --color-ey-gray-alt2: #585861;
    --color-ey-gray: #808080;
    --color-ey-gray-tint-1: #999999;
    --color-ey-gray-tint-2: #c0c0c0;
    --color-ey-gray-tint-3: #f0f0f0;
    --color-ey-gray-border-1: #ececec;
    --color-ey-gray-border-2: #e8e8e8;
    --color-ey-gray-border-3: #a8a8a8;
    --color-ey-gray-text: #3f3f48;
    --color-ey-dark-grey: #343440;
    --color-ey-lightgrey: #d3d3d3;
    --color-aliceblue: #f0f8ff;

    /*Dark*/
    --color-ey-dark-gray: #2e2e3c;
    --color-ey-dark-gray2: #3a3a4a;
    --color-ey-dark-gray3: #23232f;
    

    /* Secondary Colors */

    --color-ey-red: #f04c3e;
    --color-ey-link-blue: #336699;
    --color-ey-yellow-50: #fff27f;

    /* Tertiary Color */

    --color-gray-alt-1: #4a4a4a;
    --color-gray-alt-2: #d8d8d8;
    --color-gray-alt-4: #f6f6f6;

    /* Disabled Color */

    --color-disabled: hsl(240 8% 79% / 0.35);

    /* Shadow Color */

    --color-shadow: hsl(208 6% 54% / 0);
    --color-shadow-2: hsl(0 0% 0% / 0.15);

    /* Border Color */

    --color-border: #aaaab1;
    --color-border-2: var(--color-ey-grey-3);
    --color-border-3: #b9b9b9;
    --color-border-4: #e4e4e4;

    /* form  default color */
    --form-color-default: var(--color-ey-confident-grey);
    --form-bg-default: var(--color-ey-grey-4);
    --form-bg-alternative: var(--color-ey-grey-2a);
    --form-border-default: var(--color-ey-confident-grey);
    --form-border-disabled: hsl(240 5% 48% / 0);
    --form-error-color: var(--color-red-06);
    --form-dpdn-bg-default: var(--color-white);
    --form-dpdn-alternative: var(--color-ey-grey-2a);

    /* form grey color */
    --darkform-color-default: var(--color-ey-confident-grey);
    --darkform-bg-default: var(--color-white);
    --darkform-bg-alternative: var(--color-ey-grey-2a);
    --darkform-border-default: var(--color-ey-confident-grey);
    --darkform-border-disabled: var(--color-ey-grey-1);
    --darkform-error-color: var(--color-red-06);
    --darkform-dpdn-bg-default: var(--color-white);
    --darkform-dpdn-alternative: var(--color-ey-grey-2a);

    /* checkbox default color */
    --chk-border-default: var(--color-ey-confident-grey);
    --chk-border-disabled: var(--color-ey-grey-2);
    --chk-border-error: var(--color-red-06);
    --chk-color-default: var(--color-ey-confident-grey);
    --chk-bg-default: var(--color-ey-confident-grey);
    --chk-bg-alternative: var(--color-ey-grey-2);
    --chk-color-disabled: var(--color-ey-grey-1);
    --chk-error-color: var(--color-red-06);

     /* alpha colors */
     --color-black-alpha: #000000b3;
     --color-black-alpha2: #0000005e;
     --color-black-alpha3: #00000061;
     --color-gray-alpha: #e0e0e03d;
     --color-gray-alpha2: #9e9e9eb5;
     --color-gray-alpha3: #dfdddd33;
     --color-gray-alpha4: #b8b8b862;
     --color-orange-02-alpha: #ff9831be;
     --color-gray-disabled-alpha: #74748100;
     --color-gray-shadow: #5a5e6666;

     /*RGB Format*/
    --color-blue-08-a25: 0 123 255 / 25%;
    --color-black-rgb: 0,0,0;
    --color-black-rgb-a5: 0 0 0 / 5%;
    --color-black-rgb-a10: 0 0 0 / 10%;
    --color-black-rgb-a15: 0 0 0 / 15%;
    --color-black-rgb-a20: 0 0 0 / 20%;
    --color-black-rgb-a40: 0 0 0 / 40%;
    --color-black-rgb-a50: 0 0 0 / 50%;
    --color-blue-rgb: 0,0,255;
    --color-ey-grey-1-rgb: 116,116,128;
    --color-ey-grey-2-rgb: 196,196,205;
    --color-ey-grey-3-rgb: 207, 207, 207;
    --color-ey-interaction-overlays-rgb: 26,26,36;
    --color-red-rgb: 255,0,0;
    --color-red-rgb-a40: 220 53 69 / 25%;
    --color-white-rgb: 255,255,255;
    --color-red-06-rgba: 185,37,28,0.12;
    --color-yellow-rgba: 255,153,51,0.051;
    --color-ey-confident-grey-rgba-a50: 46 46 56 / 50%;
    --color-ey-card-rgb-a30: 160 174 196 / 30%;
    --color-ey-grey-rgb: 245, 245, 250;
    --color-ey-grey-dark-rgb: 33, 37, 41;
    --color-blue-06-rgb: 21, 92, 180;
    --color-gray-alt-4-rgb: 246, 246, 246;
    --color-ey-confident-grey-rgb: 46, 46, 56;
    --color-ey-grey-4-rgb: 246, 246, 250;
    --color-gray-rgb: 128, 128, 128;
    --color-blue-rgb-for-tile: 18, 117, 193;
}
