/* You can add global styles to this file, and also import other style files */
@use './typography/material-icons.scss';
@use './typography/outline-illustration-icons.scss';
@import '../../../../common/ui/styles/src/lib/styles/main.scss';
@import '../../../../common/ui/styles/src/lib/styles/grid';
@import '../../../../common/ui/styles/src/lib/styles/modal';
@import '../../../../common/ui/styles/src/lib/styles/inputs';
@import '../../../../common/ui/styles/src/lib/styles/forms';

a {
    &:hover {
        text-decoration: none !important;
        color: inherit;
    }
}

.app-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .app-loading-logo {
        background-image: url('/assets/images/logo/ey-logo-dark.svg');
        background-size: 128px 128px;
        height: 128px;
        width: 128px;
    }
}

.modal-report-viewer {
    .btn {
        margin-top: 0 !important;
        margin-bottom: 20px !important;
    }
}
.modal-backdrop {
    background-color: var(--color-black);
    opacity: 0.5;
}

.modal-backdrop.show {
    height: 100vh;
}

.modal.show {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    .modal-dialog{
        margin-top: auto;
        margin-bottom: auto; 
    }
}

.data-label {
    font-size: var(--font-size-3);
    color: var(--color-ey-gray-alt);
    white-space: nowrap;

    kendo-dropdownbutton {
        padding: 0;
        margin: 0;
        border-radius: 0;
        vertical-align: top;
        background-color: transparent;

        button.k-button {
            padding: 0;
            margin: 0;
            vertical-align: top;
            background: none;
            background-color: transparent;
            border: none;
            font-size: var(--font-size-3);
            font-family: var(--font-family-default);
            color: var(--color-ey-gray-alt);
            white-space: nowrap;

            i {
                margin-left: 5px;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.data-value {
    cursor: default;

    &[data-type='status'] {
        font-weight: bold;
        font-size: 0.8em;
        color: var(--color-ey-gray-tint-3);
        padding: 0.4em 1em;
        background-color: var(--color-ey-gray-alt);
        display: inline-block;
        white-space: nowrap;

        &[data-status='NotStarted'] {
            background-color: var(--color-warning);
            color: var(--color-ey-confident-grey);
            border: none;
        }
        &[data-status='NotApplicable'] {
            background-color: var(--color-maroon-04);
            color: var(--color-white);
            border: none;
        }

        &[data-status='InProgress']{
            background-color: var(--color-maroon-01);
            color: var(--color-ey-confident-grey);
            border: none;
        }

        &[data-status='DataCollected'] {
            background-color: var(--color-ey-confident-grey);
            color: var(--color-white);
            border: none;
        }

        &[data-status='InReview'] {
            background-color: var(--color-ey-confident-grey);
            color: var(--color-white);
            border: none;
        }

        &[data-status='Completed'],
        &[data-status='Accepted'] {
            background-color: var(--color-green-06);
            color: var(--color-white);
            border: none;
        }

        &[data-status='Draft'] {
            background-color: var(--color-ey-link);
            color: var(--color-white);
            border: none;
        }

        &[data-status='Disabled'] {
            background-color: var(--color-red-06);
        }

        &[data-status='Uploaded'] {
            background-color: var(--color-ey-confident-grey);
            color: var(--color-white);
            border: 1px solid var(--color-ey-confident-grey);
        }

        &[data-status='New'] {
            background-color: var(--color-white);
            color: var(--color-ey-confident-grey);
            border: 1px solid var(--color-ey-confident-grey);
        }

        &[data-status='Rejected'] {
            background-color: var(--color-red-06);
            color: var(--color-white);
            border: none;
        }
    }

    &[data-type='userRole'] {
        // INTERNAL USERS: EY Client Admin, EY User, Data Reader
        &[data-role-id='1'],
        &[data-role-id='4'],
        &[data-role-id='11'] {
            background-color: var(--color-blue-07);
        }
        // EXTERNAL USERS: Client User, Client Data Reader, Client Editor
        &[data-role-id='2'],
        &[data-role-id='14'],
        &[data-role-id='7'] {
            background-color: var(--color-teal-05);
        }
        // Data Provider
        &[data-role-id='10'] {
            background-color: var(--color-green-07);
        }
    }
}

.clickable {
    cursor: pointer;
}

.page-filters {
    padding-top: 16px;
    padding-bottom: 16px;

    label {
        margin-bottom: 0.5rem;
    }
}

.label-column {
    font-weight: bold;
    vertical-align: bottom;
    padding-top: 0.3rem;
}

.input-column,
.input-column-small {
    input,
    select {
        margin: 0.2rem;
        padding: 0.2rem;
        width: 100%;
        background-color: var(--color-ey-gray-tint-3);
        border: 1px solid var(--color-ey-gray-tint-2);
    }

    input[disabled],
    select[disabled] {
        background-color: var(--color-ey-gray-tint-2);
    }
}

/* Kendo Tab override styles */
.white-tabs {
    .k-tabstrip {
        .k-content {
            background-color: var(--color-white);
            padding: 1rem 2rem;
        }

        & > .k-tabstrip-items {
            border-bottom: none;
            margin-bottom: 0;

            .k-item {
                width: 15rem;
                margin-right: 3px;
                border-radius: 0;
                font-size: 0.85rem;
                text-align: center;
                color: var(--color-ey-gray);
                background-color: var(--color-ey-gray-tint-3);
                border-color: transparent;

                i {
                    margin-right: 0.25em;
                }

                .k-link {
                    padding: 0.5rem 1.5rem 0.3rem 1.5rem;
                    border-color: transparent;
                }

                &.k-state-active {
                    color: var(--color-ey-gray);
                    font-weight: normal;
                    background-color: var(--color-white);
                    border-color: transparent;
                }
            }
        }
    }
}

/* Kendo Dropdownlist styles*/
kendo-dropdownlist {
    &.k-dropdown {
        .k-dropdown-wrap {
            border-radius: 0;
            line-height: 1;
        }
    }
}

a.ey-link {
    color: var(--color-blue-06) !important;
    font-family: var(--font-family-bold) !important;
    font-weight: 400;
}

.end-buttons ey-button {
    padding-left: 15px;
    margin: 0px !important;
}

.modal-body .nav-tabs {
    border-bottom: none;
}

.modal-body .nav-tabs .nav-item {
    width: 50%;
    text-align: center;
}

.modal-body .nav-tabs .nav-link.active,
.modal-body .nav-tabs .nav-item.show .nav-link {
    border-top: none;
    border-right: none;
    border-left: none;
}

.modal-body a.nav-link {
    box-shadow: inset 0 -4px 0 var(--color-ey-gray-border-2) !important;
    border-color: var(--color-ey-gray-border-2);
    color: var(--color-gray-alt-1) !important;
    border: none;
}

.modal-body a.nav-link.active {
    box-shadow: inset 0 -4px 0 var(--color-ey-link) !important;
    color: var(--color-ey-link) !important;
}

input[type='text'].ng-invalid.has-danger {
    border: 1px solid var(--color-red-06) !important;
}

.modal-body .nav-tabs a.nav-link {
    color: var(--color-gray-alt-1);
    font-size: var(--font-size-6);
    line-height: 24px;
}

.modal-dialog-agreement {
    margin: 0;
    max-width: 100%;
}

.modal-dialog-privacy {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    max-width: 70%;
    max-height: 80%;
    height: 90%;
    overflow: hidden;
    margin: 0 !important;
    gtp-privacy-notice-modal {
        width: 100%;
        height: 100%;
    }
}

.pl-20 {
    padding-left: 20px;
}

.pr-20 {
    padding-right: 20px;
}

.mt-1 {
    margin-top: 1em !important;
}

.ml-20 {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.bg-primary {
    background-color: var(--color-ey-yellow) !important;
}

#fileSelect {
    cursor: pointer;
}

/* For Admin sync history grid, the below is specific styles */
.admin-reporting-sync-history-container {
    .grid-wrapper kendo-grid.k-grid tbody > tr td.k-hierarchy-cell .k-icon.k-minus::before {
        font-family: var(--font-family-materialIcons);
        content: 'keyboard_arrow_down' !important;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        color: var(--color-ey-dark-gray) !important;
    }

    .grid-wrapper kendo-grid.k-grid tbody > tr td.k-hierarchy-cell .k-icon.k-plus::before {
        font-family: var(--font-family-materialIcons);
        content: 'keyboard_arrow_right' !important;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        color: var(--color-ey-dark-gray) !important;
    }

    .grid-wrapper kendo-grid.k-grid .k-grid-header {
        height: 0 !important;
    }

    .green-color {
        color: var(--color-green-04);
    }
}

.k-popup {
    border-color: var(--color-ey-gray-tint-1);
    background-color: var(--color-white);
}

.grid-context-menu-item,
.grid-context-menu-sub-item {
    &.k-menu-item:not([aria-haspopup='true']) > span.k-link {
        padding: 0;

        & div {
            padding: 0.5rem 2.75rem;
            width: 100%;
            font-family: var(--font-family-light);
            color: var(--color-ey-confident-grey);
        }
    }

    &.k-menu-item[aria-haspopup='true'] > span.k-link {
        padding: 0.5rem 2.75rem;
        width: 100%;
        &:active,
        &.k-active {
            background-color: var(--color-ey-gray-border-1) !important;
            color: var(--color-black) !important;

            .grid-newcontext-menu-item {
                color: var(--color-black) !important;
            }
        }
    }
    display: block;
    color: var(--color-gray-alt-1);
    font-size: var(--font-size-3) !important;
    font-weight: normal;
    background-color: var(--color-white);

    &:hover {
        background-color: var(--color-ey-yellow-50);
    }

    & > .k-menu-link > span.k-menu-expand-arrow {
        font-size: var(--font-size-5);
        font-weight: bold;
        margin-bottom: 2px;
        display: inline-block;

        &::before {
            font-family: var(--font-family-materialIcons);
            content: 'keyboard_arrow_right';
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            /* Support for Firefox. */
            -moz-osx-font-smoothing: grayscale;
            /* Support for IE. */
            font-feature-settings: 'liga';
        }

        & .k-icon {
            &::before {
                content: '';
            }
        }
    }

    &.k-state-disabled {
        opacity: 1;
        color: var(--color-ey-gray-tint-1);
    }
}

/* Plus '+' icon style for grid tool bar */
.k-grid-toolbar {
    overflow: visible;
    i.add-page-config {
        font-size: var(--font-size-6) !important;
        margin: 3px !important;
    }

    .grid-action-row {
        flex-wrap: wrap-reverse;

        .button-group {
            display: inline-flex;
            white-space: nowrap;
            flex-direction: row;

            .button-container {
                border-color: var(--color-white) !important;
                white-space: nowrap;

                .ey-export-icon {
                    background-image: url('/assets/images/entities/excel-export.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    height: 24px;
                    width: 24px;
                    margin: -4px -2px 0 -4px;
                    display: block;
                }
            }
        }

        .search-group {
            display: inline-flex;
            padding: 8px 40px;
            flex-direction: row;
            flex: 1;

            @media screen and (max-width: 85.625rem) {
                max-width: 40.9375rem;
            }

            advanced-search {
                width: 100%;
            }
        }

        .actions-group {
            padding-left: 16px;
            flex-wrap: nowrap;
            white-space: nowrap;
            flex-direction: row;
        }
    }
}

// TODO move to the component library
.form-control:focus {
    box-shadow: none;
}

// Style for making the NGX-App-Tour popovers work with modals
body > div.cdk-overlay-container {
    z-index: 2001;
}

// actity toaster
@keyframes activityPopUp {
    from {
        bottom: 0px;
    }
    to {
        bottom: 100px;
    }
}

.activity-toaster-component {
    position: fixed;

    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);
    width: 500px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 24px 0 rgba(var(--color-black-rgb), 0.5);

    z-index: 9000;

    animation: activityPopUp 0.5s ease-in;
}

// fix for tooltip floating over notification panel
.k-chart-tooltip-wrapper {
    z-index: 99 !important;
}

.form-error-message {
    color: var(--color-red-06);
    margin-bottom: 1rem;
}

.stepper-modal-slide {
    padding: 0;
    background-image: radial-gradient(closest-side at 69% 116%, var(--color-blue-08) 43%, var(--color-ey-confident-grey) 100%);
    border: 0;
}

.grid-header-overflow-clip .k-grid th {
    text-overflow: clip !important;
}

.content {
    input {
        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            color: var(--color-ey-dark-gray);
        }
    }

    select::-ms-expand {
        display: none;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;

        &::-ms-expand {
            display: none;
        }

        &:not([multiple]) {
            background-image: url(/assets/images/caret-down/caret-down.png);
            background-repeat: no-repeat;
            background-position: 98% 50%;

            option {
                background-color: var(--color-white);
            }

            optgroup {
                background-color: var(--color-white);
            }
        }
    }
}

// Material Icon

.material-icons.mi-0875rem, .material-icons-filled.mi-0875rem {
    font-size: var(--font-size-4) !important;
    height: 0.875rem !important;
    width: 0.875rem !important;
    vertical-align: middle;
    margin-right: 0.1875rem;
    line-height: 0.75rem;
}
.material-icons.mi-09375rem, .material-icons-filled.mi-09375rem {
    font-size: 0.9375rem !important;
    height: 0.9375rem !important;
    width: 0.9375rem !important;
    vertical-align: middle;
    margin-right: 0.1875rem;
    line-height: 0.75rem;
}

.material-icons.mi-1rem, .material-icons-filled.mi-1rem {
    font-size: var(--font-size-5) !important;
    height: 1rem !important;
    width: 1rem !important;
}

.material-icons.md-18, .material-icons-filled.md-18 {
    font-size: var(--font-size-6) !important;
    height: 18px !important;
    width: 18px !important;
}

.material-icons.md-24, .material-icons-filled.md-24 {
    font-size: var(--font-size-8) !important;
    height: 24px !important;
    width: 24px !important;
}

.material-icons.md-36, .material-icons-filled.md-36 {
    font-size: 36px !important;
    height: 36px !important;
    width: 36px !important;
}

.material-icons.md-48, .material-icons-filled.md-48 {
    font-size: var(--font-size-11) !important;
    height: 48px !important;
    width: 48px !important;
}

// Material Icon

.flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
