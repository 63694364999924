@font-face {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Noto Sans"), local("NotoSans"),
    url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5TRA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "EYInterstate-Light";
  src: local("EYInterstate-Light");
  src: url("../fonts/EYInterstate-Light.woff2") format("woff2"),
    url("../fonts/EYInterstate-Light.eot"),
    url("../fonts/EYInterstate-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EYInterstate-Light.ttf") format("truetype"),
    url("../fonts/EYInterstate-Light.woff") format("woff"),
    url("../fonts/EYInterstate-Light.svg") format("svg");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "EYInterstate-Regular";
  src: local("EYInterstate-Regular");
  src: url("../fonts/EYInterstate-Regular.woff2") format("woff2"),
    url("../fonts/EYInterstate-Regular.woff") format("woff"),
    url("../fonts/EYInterstate-Regular.ttf") format("truetype"),
    url("../fonts/EYInterstate-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EYInterstate-Regular.woff2") format("woff2"),
    url("../fonts/EYInterstate-Regular.svg") format("svg");
    font-display: swap;
}

@font-face {
  font-family: "EYInterstate-Bold";
  src: local("EYInterstate-Bold");
  src: url("../fonts/EYInterstate-Bold.woff2") format("woff2"),
    url("../fonts/EYInterstate-Bold.woff") format("woff"),
    url("../fonts/EYInterstate-Bold.ttf") format("truetype"),
    url("../fonts/EYInterstate-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/EYInterstate-Bold.woff2") format("woff2"),
    url("../fonts/EYInterstate-Bold.svg") format("svg");
    font-display: swap;
}
