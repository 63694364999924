.pill {
  //text
  color: var(--color-white);
 font-size: var(--font-size-3);
  letter-spacing: 0;
  text-align: center;
  line-height: 1.125rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  display: inline-block;

  //box
  border-radius: var(--spacing-02);
  height: 1.25rem;
  min-width: fit-content;

  //margin
  margin: var(--spacing-02) var(--spacing-06) var(--spacing-02) 0;

  &.pill--approved {
    background: var(--color-green-06);
  }
  &.pill--failed {
    background: var(--color-red-06);
  }
  &.pill--hold {
    background: var(--color-orange-02);
    color: var(--color-ey-confident-grey);
  }
  &.pill--open {
    background: var(--color-ey-confident-grey);
  }
  &.pill--closed {
    background: var(--color-white);
    color: var(--color-ey-confident-grey);
    border: solid 0.0625rem var(--color-ey-confident-grey);
  }
  &.pill--green {
    background: var(--color-green-04); //not found-->#2eb757;
  }
  &.pill--teal {
    background: var(--color-teal-04); //not found-->#28aca9;
  }
  &.pill--blue {
    background: var(--color-blue-04); //not found-->#188be5;
  }
  &.pill--purple {
    background: var(--color-purple-04); //not found-->#3d1089;
  }
  &.pill--maroon {
    background: var(--color-maroon-04); //not found-->#750e5d;
  }
  &.pill--red {
    background: var(--color-red-04); //not found-->#ff4236;
  }
  &.pill--orange {
    background: var(--color-orange-04);
  }
  &.pill--group-by {
    color: var(--color-ey-grey-1);
    background: var(--color-white);
  }
}

.pill-wrapper {
  margin: 0 0 2.6875rem 0;
}
