.upload-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;


  .upload-group__filestatus-label {
    height: 2.8125rem;
  }
  &
    > *:not(:last-child):not(.upload-group__delete):not(.upload-group__filestatus--menu) {
    margin-bottom: 1.25rem;
  }

  &__dragdrop {
    width: 100%;
    height: 6.25rem;
    background: var(--color-white);
    border: 0.0625rem solid var(--color-ey-grey-2);
    border-style: dashed;
    border-radius: 0;

    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.25rem;

    .material-icons {
      color: var(--form-color-default);
      height: 2.188rem;
      font-size: var(--font-size-9);
      margin-bottom: 0.625rem;
      width: 2.1875rem;
      height: 1.5rem;
    }
  }
  &__dragdrop-label {
    @include fonts-col__body-copy(1.375rem);
    height: 1.375rem;
    color: var(--color-ey-confident-grey);
    font-weight: 300;
    text-align: center;
    & a {
      color: var(--color-ey-link);
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__filestatus {
    $height: 2.813rem;
    border: 0.0625rem solid var(--color-border-2);
    background: var(--color-ey-grey-5);

    & > *:first-child {
      padding: 0.8125rem 1.25rem;
    }

    &--spinner,
    &--menu {
      display: flex;
      flex-direction: row;
    }

    &--menu {
      margin-bottom: 0.3125rem;
      align-items: center;
    }

    &-label {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;

      @include fonts-col__body-copy;
      height: 1rem;
      color: var(--color-ey-confident-grey);
      background: inherit;
      text-align: left;
      padding-left: 0.875rem;
      height: $height;
      line-height: $height !important; //override
      margin: 0;
      & .material-icons {
        font-size: var(--font-size-8);
        margin-right: 0.3125rem;
        height: $height;
        line-height: $height; //override
      }
      & div,
      & i {
        color: inherit;
      }
      & div {
        font-family: var(--font-family-light) !important;
        font-size: var(--font-size-4);
        line-height: $height; //override
      }
      & div:nth-child(2) {
        flex-grow: 1;
      }
      & div:last-child {
        margin-right: 1.25rem;
      }
      & .upload-group__filestatus-size{
        font-size: var(--font-size-3);
      }
      & .progress-group__close{
        width: 1.5rem;
        height: 1.5rem;
        font-size: var(--font-size-4);
        border: none;
        background-color: transparent;
        color: var(--color-ey-confident-grey);
        padding: 0;
        margin: 0 0 0 0.9375rem;
        line-height: 1.5rem;
        i{
          margin: 0;
          margin-right: 0;
          height: inherit;
          line-height: 1.5rem;
        }
      }
      & .progress-group__no-icon{
        display: none;
      }
  
    }

    & .spinner {
      margin-right: 0.75rem;
      &__icon {
        width: $height;
        height: $height;
        padding-top: 0.375rem;
        &:before {
          height: 2rem;
          width: 2rem;
        }
        &-progress {
          position: relative;
          height: $height;
          line-height: $height;
          top: calc(-1 * #{$height});
          color: var(--color-ey-confident-grey);
          font-size: 0.6875rem;
        }
      }
    }

    &-size {
      margin-right: 1.25rem;
    }

    & .progress {
      //override bootstrap
      height: 0.1875rem;
      background: inherit;
      border-radius: 0;
    }
    & .progress-bar {
      //override bootstrap
      background: var(--color-ey-confident-grey);
    }

    & .overflow-menu-toggle.dropdown-toggle {
      .material-icons {
        font-size: var(--font-size-9);
        line-height: $height;
        width: $height;
        height: $height;
        background: inherit;
      }
    }

    // for format: <i></i> and <button><i></i></button>
    &-delete.material-icons,
    & button.upload-group__filestatus-delete {
      color: var(--color-ey-confident-grey);
      font-size: var(--font-size-7);
      line-height: $height;
      padding-left: 0.875rem;
      height: $height;
      text-align: left;
      background: inherit;
      &:first-child {
        padding-left: 1.25rem;
      }
    }

    // for format: <button><i></i></button> (accessibility-tabstop)
    & button.upload-group__filestatus-delete {
      // override button style
      border: none;
      background-color: inherit;
      padding: 0 0.7rem;
      margin: 0 0.3rem 0 0.4rem;
      .material-icons {
        height: 1.25rem;
        line-height: 2.8125rem;
        font-size: inherit;
      }
      &:first-child {
        padding-left: 0.7rem;
      }
      & + .upload-group__filestatus-label {
        padding-left: 0;
      }
    }

    & .textinput-group__errmsg {
      padding: 0;
      width: auto;
      font-family: var(--font-family-light) !important;
      margin: 0 0.625rem 0 0;
      &:before {
        position: static;
        float: right;
        margin: 0 0 0 0.3125rem;
      }
    }

    & .textinput-group__btn {
      border: none;
      font-family: var(--font-family-bold) !important;
      font-size: var(--font-size-4);
      margin: 0.9375rem;
      background: transparent;
    }
  }

  &__delete {
    margin-bottom: 0.625rem;
    text-align: left;
    a {
      @include fonts-col__sub-nav-selected-state;
      color: var(--color-ey-link);
      font-weight: bold;
      height: 1.375rem;
      &:hover {
        color: var(--color-ey-link);
        text-decoration: none;
      }
    }
  }

  & .textinput-group__textinput {
    font-size: var(--font-size-4) !important; //override mixin
  }
}
