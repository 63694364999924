$missing-color-blue-01: var(--color-ey-confident-grey);
$missing-color-blue-02: var(--color-blue-05a);
$missing-color-grey-01: var(--color-ey-gray-border-1);
$missing-color-white: var(--color-white);

.fa:not(.fa-spin) {
    animation: none;
}
.dark-theme-filter{
    *,label,.checkbox__label,.k-button-solid-base,.k-button-solid-primary:hover
    {
        color: white !important;
    }
    .checkbox__checkmark, .k-button-solid-base,.k-i-filter::before{
        border-width: 1px;
        border-style: solid;
        border-color: white!important;
    }
    * :not(.checkbox__label),.k-button-solid-primary:hover{
        background: var(--color-ey-interaction-overlays)!important;
    }
    .k-button-solid-primary:not(:hover),div.k-columnmenu-item:hover,li.checkbox:hover > *
    {
        color:black!important;
    }
    .k-button-solid-primary:not(:hover){
        background-color: var(--color-ey-yellow)!important;
    }
    .k-button-solid-primary:disabled{
        color: var(--color-ey-grey-1)!important;
        background-color: var(--color-ey-grey-2a)!important;
    }
}
/*Kendo Inputs*/
.k-column-list {
    label.k-column-list-item {
        padding-right: 0; //checkbox label wrapping
        display: flex;
        align-items: center;
        .k-checkbox {
            box-shadow: none;
            flex-shrink: 0;
            &:focus {
                border-color: var(--color-ey-confident-grey);
            }
        }
    }
}

.k-checkbox-label {
    &::before {
        border-radius: 0;
    }
}

.k-columnmenu-item-content {
    .k-column-list-item {
        height: 2.3rem;
    }
}

.k-column-list-item:focus,
.k-column-list-item:focus-within {
    // outline: 5px auto -webkit-focus-ring-color;
    outline: 0.0625rem solid var(--color-border);
    outline-offset: -0.025rem;
}

.k-checkbox {
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--color-white);
    border: 0.0625rem solid var(--color-ey-confident-grey);
    &:hover {
        border: 0.0625rem solid var(--color-ey-confident-grey);
    }
    &:focus,
    &:checked:focus {
        box-shadow: none;
    }
    &:before {
        width: 0.875rem;
        height: 0.875rem;
        font-size: var(--font-size-4);
    }
    & + .k-checkbox-label {
       font-size: var(--font-size-3) !important;
        font-family: var(--font-family-light) !important;
        line-height: 1.2375rem !important;
        color: var(--color-ey-confident-grey);
        margin-left: 1.7rem;
        display: inline;

        &::before {
            width: 1.25rem;
            height: 1.25rem;
            background-color: var(--color-white);
            border: 0.0625rem solid var(--color-ey-confident-grey);
        }
    }

    &:checked {
        background-color: var(--color-ey-confident-grey);
        border: 0.0625rem solid var(--color-ey-confident-grey);
        & + .k-checkbox-label {
            &::before {
                background-color: var(--color-black);
                border: 0.0625rem solid var(--color-ey-confident-grey);
            }
        }
    }

    &:indeterminate + .k-checkbox-label {
        &::after {
            background-color: var(--color-black);
            width: 0.75rem;
            height: 0.75rem;
        }
    }
}

.k-radio:checked + .k-radio-label {
    &::before {
        background-color: var(--color-white);
        border: 0.0625rem solid var(--color-ey-link);
    }

    &::after {
        background-color: var(--color-ey-link);
    }
}

.k-textbox {
    border-radius: 0;
}
.grid-wrapper {
    kendo-grid.k-grid {
        .k-grid-header {
            border-left: 0.0625rem solid var(--color-ey-grey-3);
            .k-header {
                .k-cell-inner {
                    .k-link {
                        padding: 0;
                    }
                }
            }
        }
    }
}
/*Kendo Tabstrip*/
.k-tabstrip {
    padding-top: 1rem;

    & > .k-content {
        background: transparent;
        color: var(--color-ey-dark-gray);
        border: none;
        padding: 1.25rem 2.5rem;
        padding-top: 0;
        overflow: hidden;
    }

    .k-tabstrip-items-wrapper {
        border: none;
        z-index: auto;

        & > .k-tabstrip-items {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            border-bottom: 0.0625rem solid var(--color-ey-gray-tint-2);
            width: 100%;
            margin-bottom: 0.125rem;

            .k-item {
                color: var(--color-ey-confident-grey);
                font-size: var(--font-size-5);
                border: none;

                .k-link {
                    padding: 0 1.25rem 1.25rem 1.25rem;

                    .tab-title-label {
                        font-family: var(--font-family-light) !important;
                        font-weight: 400;
                    }
                }

                &[role='tab'].k-active .k-link {
                    box-shadow: inset 0 -0.25rem var(--color-ey-confident-grey);
                }
            }
        }
    }
}

// Kendo Calendar
.k-calendar .k-calendar-th{
    padding: 0 0.5rem;
}

.k-animation-container {
    border-radius: 0;

    .k-menu-popup {
        border-radius: 0;
        width: 17.25rem;
        background: var(--color-black);
        margin: 0;
        padding: 0;

        .k-menu-group {
            .k-menu-item {
                &.ey-menu-item {
                    color: var(--color-white);
                }

                &.ey-menu-list-item {
                    color: var(--color-white);
                    padding: 1rem 0;
                    margin: 0 1rem;
                    border-bottom: 0.0625rem solid var(--color-white);

                    &:last-of-type {
                        border: 0;
                    }

                    &:hover {
                        color: var(--color-ey-yellow);
                    }
                }

                .k-link{
                    &:hover {
                        background-color: var(--color-ey-grey-2a);
                    }
                }
            }
        }
    }

    .k-popup-transparent {
        border-radius: 0;
        background: transparent;
        border: 0;
        /* Tooltip */
        .hidden-k-tooltip{
            display: none;
        }
        .k-tooltip {
            max-width: 21.88rem;
            color: var(--color-white);
            background: var(--color-ey-dark-gray);
            border-radius: 0;
            border: 0;
            padding: 0.5rem !important;
           font-size: var(--font-size-3) !important;

            .k-callout {
                color: var(--color-ey-dark-gray);
            }
        }
    }
    
    &.utility-bar-popup-container {
        left: auto !important;
        right: 0;
        height: 92%;
        box-shadow: -0.125rem 0.125rem 0.25rem 0 rgba(var(--color-black-rgb), 0.5);
        z-index: 100000;
        background: var(--color-ey-interaction-overlays);
        
        .utility-bar-popup {
            background: var(--color-ey-gray-tint-3);
            width: 25rem;
            height: 100%;
            border-radius: 0;
            border: 0;
            padding: 0;
            margin: 0;

            & > :first-child {
                height: 100%;
                overflow-y: auto;
            }
        }
    }
    &.utility-bar-popup-container--dark {
        .utility-bar-popup {
            background: var(--color-ey-interaction-overlays);
        }
    }
}

.k-drag-clue {
    .k-icon {
        font-feature-settings: 'liga';

        &.k-i-cancel {
            font-family: var(--font-family-materialIcons) !important;
            position: relative;
            display: inline-block;
            color: var(--color-ey-confident-grey) !important;

            &::before {
                content: 'block';
            }
        }

        &.k-i-plus {
            font-family: var(--font-family-materialIcons) !important;
            position: relative;
            display: inline-block;
            color: var(--color-ey-confident-grey) !important;

            &::before {
                content: 'add';
            }
        }
    }
}

.k-menu,
.k-context-menu {
    .k-item,
    .k-menu-item {
        min-height: 2.313rem;
        border: solid 0.0625rem transparent;
        &:hover {
            background-color: var(--color-ey-grey-2a) !important;
        }
        .k-state-active {
            height: 100%;
            color: inherit !important;
            background-color: inherit !important;
        }
    }
}

.k-button-solid-base.k-selected{
    border-color: var(--color-ey-grey-2) !important ;
    color: var(--color-white) !important;
    background-color: var(--color-ey-interaction-overlays) !important;

    .k-button-text{
        color: var(--color-white) !important;
    }
}


.btn {
    margin: 0.3125rem 0.3125rem;
    ey-button:last-child & {
        margin-right: 0;
    }
    ey-button:first-child & {
        margin-left: 0;
    }

    & span {
        font-family: var(--font-family-bold) !important;
        font-weight: 400;
    }
    &:not(.btn--delete) span {
        color: var(--color-ey-confident-grey);
    }
    &.btn--delete span {
        color: var(--color-white);
    }
}

.k-button,
ey-button.btn-flex > button.btn,
ey-button > button.btn {
    .multi-select-buttons > & {
        font-size: var(--font-size-4) !important;
        font-family: var(--font-family-bold) !important;
        line-height: 1.375rem !important;
        transition: all 0.3s ease;
        border-radius: 0;
        height: 2.813rem !important; //override !important on .btn-flex > button.btn
        vertical-align: middle;
        padding: 0.0625rem 1.25rem 0;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:not(:disabled):not([disabled]) {
            cursor: pointer;
        }
        &[disabled] {
            opacity: 0.65;
        }

        &:not(.k-primary):not(.btn--primary) {
            //secondary
            background-color: var(--color-white);
            background: 0 0;
            color: var(--color-ey-confident-grey);
            border: 0.0625rem solid var(--color-ey-confident-grey);
            box-shadow: none;
        }

        &:focus {
            outline: -webkit-focus-ring-color auto 0.0625rem;
            box-shadow: unset;
        }

        kendo-grid-filter-menu-container .k-filter-menu & {
            &:not(.k-primary):not(.btn--primary) {
                //secondary
                background-color: var(--color-white);
                background: 0 0;
                color: var(--color-ey-confident-grey);
                border: 0.0625rem solid var(--color-ey-confident-grey);
                box-shadow: none;
            }
        }

        kendo-grid-columnlist.k-column-list-wrapper &,
        kendo-grid-filter-menu-container .k-filter-menu & {
            margin: 0 0.46875rem !important; //override !important, specific for kendo grid
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
        }
    }
}

ey-button.btn-flex {
    .k-columnmenu-actions > & {
        margin: 0 0.46875rem !important;
        &:first-child {
            margin-left: 0 !important;
        }
        &:last-child {
            margin-right: 0 !important;
        }
        & > .btn {
            margin: 0;
        }
    }
}

ey-button {
    .multi-select-container .k-animation-container .popup-content & {
        margin: 0 0.46875rem 0.46875rem 0.46875rem !important;
        & > .btn {
            margin: 0;
        }
    }
}

@media (max-width: 992px) {
    .navigation-bar-stack-container {
        .navigation-bar-primary.showMobile {
            ul {
                flex-flow: column;
                li a.active:only-child,
                li button.active:only-child {
                    border-left: solid 0.1875rem var(--color-ey-yellow);
                    border-bottom: none;
                }
            }
            .navigation-bar-primary__nav-link {
                &:hover {
                    font-family: var(--font-family-bold) !important;
                }
            }
        }
    }
}

//Bootstrap
.offset-xs-right-12 {
    margin-right: 100%;
}

.offset-xs-right-11 {
    margin-right: 91.66666667%;
}

.offset-xs-right-10 {
    margin-right: 83.33333333%;
}

.offset-xs-right-9 {
    margin-right: 75%;
}

.offset-xs-right-8 {
    margin-right: 66.66666667%;
}

.offset-xs-right-7 {
    margin-right: 58.33333333%;
}

.offset-xs-right-6 {
    margin-right: 50%;
}

.offset-xs-right-5 {
    margin-right: 41.66666667%;
}

.offset-xs-right-4 {
    margin-right: 33.33333333%;
}

.offset-xs-right-3 {
    margin-right: 25%;
}

.offset-xs-right-2 {
    margin-right: 16.66666667%;
}

.offset-xs-right-1 {
    margin-right: 8.33333333%;
}

.offset-xs-right-0 {
    margin-right: 0;
}

@media (min-width: 768px) {
    .offset-sm-right-12 {
        margin-right: 100%;
    }

    .offset-sm-right-11 {
        margin-right: 91.66666667%;
    }

    .offset-sm-right-10 {
        margin-right: 83.33333333%;
    }

    .offset-sm-right-9 {
        margin-right: 75%;
    }

    .offset-sm-right-8 {
        margin-right: 66.66666667%;
    }

    .offset-sm-right-7 {
        margin-right: 58.33333333%;
    }

    .offset-sm-right-6 {
        margin-right: 50%;
    }

    .offset-sm-right-5 {
        margin-right: 41.66666667%;
    }

    .offset-sm-right-4 {
        margin-right: 33.33333333%;
    }

    .offset-sm-right-3 {
        margin-right: 25%;
    }

    .offset-sm-right-2 {
        margin-right: 16.66666667%;
    }

    .offset-sm-right-1 {
        margin-right: 8.33333333%;
    }

    .offset-sm-right-0 {
        margin-right: 0;
    }
}

@media (min-width: 992px) {
    .offset-md-right-12 {
        margin-right: 100%;
    }

    .offset-md-right-11 {
        margin-right: 91.66666667%;
    }

    .offset-md-right-10 {
        margin-right: 83.33333333%;
    }

    .offset-md-right-9 {
        margin-right: 75%;
    }

    .offset-md-right-8 {
        margin-right: 66.66666667%;
    }

    .offset-md-right-7 {
        margin-right: 58.33333333%;
    }

    .offset-md-right-6 {
        margin-right: 50%;
    }

    .offset-md-right-5 {
        margin-right: 41.66666667%;
    }

    .offset-md-right-4 {
        margin-right: 33.33333333%;
    }

    .offset-md-right-3 {
        margin-right: 25%;
    }

    .offset-md-right-2 {
        margin-right: 16.66666667%;
    }

    .offset-md-right-1 {
        margin-right: 8.33333333%;
    }

    .offset-md-right-0 {
        margin-right: 0;
    }
}

@media (min-width: 1200px) {
    .offset-lg-right-12 {
        margin-right: 100%;
    }

    .offset-lg-right-11 {
        margin-right: 91.66666667%;
    }

    .offset-lg-right-10 {
        margin-right: 83.33333333%;
    }

    .offset-lg-right-9 {
        margin-right: 75%;
    }

    .offset-lg-right-8 {
        margin-right: 66.66666667%;
    }

    .offset-lg-right-7 {
        margin-right: 58.33333333%;
    }

    .offset-lg-right-6 {
        margin-right: 50%;
    }

    .offset-lg-right-5 {
        margin-right: 41.66666667%;
    }

    .offset-lg-right-4 {
        margin-right: 33.33333333%;
    }

    .offset-lg-right-3 {
        margin-right: 25%;
    }

    .offset-lg-right-2 {
        margin-right: 16.66666667%;
    }

    .offset-lg-right-1 {
        margin-right: 8.33333333%;
    }

    .offset-lg-right-0 {
        margin-right: 0;
    }
}

.bs-popover-right {
    &.popover-menu-hide {
        display: none !important;
    }
    &.popover-menu {
        margin-left: 0.25rem;
        border-radius: 0;
        height: 2.8125rem;
        width: 14.375rem;
        border: none;
        left: -0.25rem !important;

        .popover-arrow {
            display: none;
        }
        .popover-body {
            padding: 0;
            height: 100%;
        }
    }
}

.bs-datepicker-body {
    table {
        th {
            color: var(--color-ey-dark-gray);
        }
    }
}

.toast {
    height: auto;
}

//DesignSystem
.switch {
    top: 0.25rem;
    &-label {
        position: static;
        margin-left: 0.625rem;
        vertical-align: middle;
        &:before {
            content: '';
        }
        &--off {
            font-family: var(--font-family-bold);
            margin-right: 0.625rem;
            vertical-align: middle;
        }
    }
}

.k-popup {
    .k-no-data {
        height: 12.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.k-autocomplete {
    margin: 0;
    padding: 0;
    height: 2.5rem;

    & .k-input-inner {
        margin: 0;
        padding: 0.5rem 0.75rem;
        width: 100%;
    }
}

.k-list {
    width: 100%;
}

.k-list-ul {
    padding: 0;
    list-style: none;
    margin: 0;
    max-height: inherit;
    overflow: auto;

    .k-list-item {
        padding: 0.25rem 1rem;
        min-height: 2.5em;
        line-height: 1.5em;
        white-space: normal;
        transition-property: color, background-color, outline-color, box-shadow;
        transition-duration: 200ms;
        transition-timing-function: ease;
        display: flex;
        align-items: center;
        align-content: center;
        cursor: pointer;

        &:hover {
            color: $missing-color-blue-01;
            background-color: $missing-color-grey-01;
        }
        &.k-selected {
            color: $missing-color-white;
            background-color: $missing-color-blue-02;
        }
    }
}

.k-treeview {
    .k-treeview-group {
        margin: 0;
        padding: 0;
        background: none;
        list-style: none;
        position: relative;
    }
    .k-treeview-item {
        outline-style: none;
        margin: 0;
        padding: 0 0 0 16px;
        border-width: 0;
        display: block;
    }

    &.ey-tree-navigation {
        overflow-x: hidden;
        .k-treeview-item {
            > div {
                height: 50px;
                &:hover {
                    background-color: #e9ecef;    
                }
                > span {
                    height: 100%;
                    &:hover {
                        background-color: transparent;
                    }
                    &.k-focus {
                        box-shadow: none;
                    }
                    &:not(.k-treeview-toggle) {
                        flex: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        > span {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    &.k-selected {
                        color: #212529;
                        background-color: #e9ecef;   
                    }
                }
            }
            .k-i-loading {
                &::before {
                    margin-top: -0.91em;
                    margin-left: -0.9em;
                    width: 2em;
                    height: 2em;
                }
            }
            .k-icon {
                font-size: 24px;
                &.k-i-caret-alt-down {
                    &::before {
                        font-family: var(--font-family-materialIcons);
                        content: "expand_more";
                    }
                }
                &.k-i-caret-alt-right {
                    &::before {
                        font-family: var(--font-family-materialIcons);
                        content: "chevron_right";
                    }
                }
            }
            &[ng-reflect-is-selected="true"] {
                > div {
                    &  .k-treeview-toggle {
                        background-color: #e9ecef;   
                        z-index: 2;
                    }
                }
            } 
        }
    }
    
    .k-checkbox-wrapper {
        margin-left: 0.5rem;
    }
    .k-treeview-mid,
    .k-treeview-top,
    .k-treeview-bot {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        padding-top: 2px;
        padding-bottom: 2px;

        .k-treeview-toggle {
            margin-left: 0;
        }
    }
}

.popover {
    border: 1px solid var(--color-ey-grey-3a);
    box-shadow: 0 .5rem 1rem #00000026;
    &.bs-popover-right > .arrow:before, .bs-popover-auto[x-placement^=right] > .arrow:before {
        border-right-color: var(--color-ey-grey-3a);
    }
    &.bs-popover-left > .arrow:before, .bs-popover-auto[x-placement^=left] > .arrow:before {
        border-left-color: var(--color-ey-grey-3a);
    }
    &.bs-popover-top > .arrow:before, .bs-popover-auto[x-placement^=top] > .arrow:before {
        border-top-color: var(--color-ey-grey-3a);
    }
    &.bs-popover-bottom > .arrow:before, .bs-popover-auto[x-placement^=bottom] > .arrow:before {
        border-bottom-color: var(--color-ey-grey-3a);
    }
}
