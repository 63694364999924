.slider {
  $height: 0.125rem;
  $handlerHeight: 1.563rem;
  $handlerWidth: 1.563rem;
  display: block;
  position: relative;
  height: $handlerHeight;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  &__start,
  &__end {
    color: var(--color-ey-grey-1);
    font-size: var(--font-size-3);
    font-family: var(--font-family-light) !important;
    position: absolute;
    bottom: -1.563rem;
  }
  &__start {
    left: 0;
  }
  &__end {
    right: 0;
  }
  &__line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    width: calc(100% - 2.25rem);
    left: 1.125rem;
    top: 0.75rem;
    height: $height;
    border-radius: $height;
    background: var(--color-ey-confident-grey);
    z-index: 0;
    overflow: hidden;
    span {
      display: block;
      height: 100%;
      background: var(--color-ey-grey-1);
    }

    &__right {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__control {
    &--left,
    &--right {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      position: absolute;
      height: $handlerHeight;
      width: $handlerWidth;
      z-index: 2;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }

      span {
        display: block;
        width: 100%;
        height: 100%;
        background: var(--color-white);
        border: 0.0625rem solid var(--color-ey-confident-grey);
        border-radius: 50%;
        &:hover,
        &:focus,
        &:active {
          background: var(--color-ey-confident-grey);
          border-color: transparent;
          outline: none;
        }
      }
      // set disabled after other state pseudo-classes
      &.disabled {
        cursor: not-allowed;
        span {
          background: var(--color-ey-grey-2);
          border-color: transparent;
        }
      }
      .slider__value-indicator {
        display: none;
        pointer-events: none;
        position: absolute;
        top: -1.625rem;
        left: 50%;
        color: var(--color-ey-confident-grey);
        font-size: var(--font-size-3);
        font-family: var(--font-family-light) !important;

        &--active {
          display: block;
        }
      }
    }
  }
}
