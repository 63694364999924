.cards {
  background: var(--color-white);
  border: 1px solid rgba(var(--color-ey-grey-2-rgb), 0.1);
  box-shadow: 0.125rem 0.125rem 0.5rem 0rem
    rgba(var(--color-ey-interaction-overlays-rgb), 0.2);
  height: 180px;
  max-width: 320px;
  width: 100%;
  padding: 1.5625rem;
  position: relative;
  overflow: hidden;
  margin: 0 1.25rem 1.25rem 0;
  display: inline-block;
  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  &__header {
    color: var(--color-blue-06);
    font-family: var(--font-family-light) !important;
    font-size: var(--font-size-5);
    line-height: 1.3125rem;
    margin: 0 0 0.4375rem 0;
  }

  &__content {
    font-family: var(--font-family-light) !important;
    font-size: var(--font-size-3);
    line-height: 1.125rem;
    color: var(--color-ey-confident-grey);
    max-height: 4.375rem;
    overflow-y: auto;
  }
  &__footer {
    background-color: var(--color-white);
    padding: 0 1.5625rem 1.5625rem 1.5625rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0;

    &-icon {
      justify-content: flex-end;
    }
  }
  &__icon {
    widows: 2rem;
    height: 2rem;
    font-size: var(--font-size-9);
  }
  &__link {
    width: 1.5rem;
    height: 1.5rem;
    font-size: var(--font-size-5);
    color: var(--color-ey-confident-grey);
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
    &:hover {
      color: var(--color-blue-06) !important;
    }
    i {
      font-size: var(--font-size-5);
    }
  }
}
