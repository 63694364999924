/*----- label only - not part of the component  -----*/
$max-width: 1200px;
.card__wrapper__label {
  width: 7rem;
  margin: 0.1875rem 0.625rem 0;
  text-align: right;
  font-size: var(--font-size-3);
  color: var(--color-ey-confident-grey);
  @media (max-width: $max-width) {
    display: block;
    margin: 0.5rem 0;
    text-align: center;
    width: 100%;
  }
}

.card__wrapper {
  display: flex;
  justify-content: center;
  margin: 0 0 1.25rem 0;
  @media (max-width: $max-width) {
    display: block;
    margin: 0 auto;
  }
}
/*----- Activity Card - Main/Actual component -----*/

.activity-card {
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  background-color: var(--color-white);
  max-width: 300px;
  height: 75px;
  width: 100%;
  padding: 0.5rem;
}
.activity-card-panel__header {
  @include fonts-col__main-nav-default-state;
}
.activity-card__body {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  color: var(--color-ey-confident-grey);
  text-align: left;
  padding: 0 0 0 0.25rem;
  height: 67px;
  overflow-y: auto;
}

.activity-card__textline1 {
  @include fonts-col__instructional-text;
  & strong {
    @include fonts-col__table-data-headers(1.125rem);
  }
}

.activity-card__textline2 {
  @include fonts-col__field-labels-on-focus(0.9375rem);
  margin-top: 0.1875rem;
  color: var(--color-ey-grey-1);
}

.activity-card__close {
  font-size: var(--font-size-5);
  color: var(--color-ey-confident-grey);
  cursor: pointer;
  text-align: left;
  margin: 0.1875rem 0.5rem 0 0.5rem;
}

.activity-card__cancel {
  margin-top: auto;
 font-size: var(--font-size-3);
  color: var(--color-ey-link-blue);
  line-height: 1.125rem;
  padding: 0.4rem 0.7rem;
  &:hover {
    color: var(--color-ey-link-blue);
    text-decoration: none;
  }
}

.activity-card__icon {
  font-size: var(--font-size-5);
  color: var(--color-ey-gray-tint-1);
  margin-top: 2px;

  .activity-card--warning & {
    //color: #ff6d01; /*color token not found*/
    color: var(--color-orange-04); /*using alternative*/
  }
  .activity-card--success & {
    //color: #32964f; /*color token not found*/
    color: var(--color-green-03); /*using alternative*/
  }
  .activity-card--info & {
    //color: #145cb4; /*color token not found*/
    color: var(--color-blue-03); /*using alternative*/
  }
  .activity-card--error & {
    color: var(--color-error);
  }
  .activity-card--action & {
    //color: #145cb4; /*color token not found*/
    color: var(--color-blue-03); /*using alternative*/
    align-self: flex-start;
    -webkit-animation: do360 5s infinite; /* Chrome, Safari, Opera */
    animation: do360 5s infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
}
/*For Icon animation -- Chrome, Safari, Opera */
@-webkit-keyframes do360 {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
/*For Icon animation -- Standard syntax */
@keyframes do360 {
  100% {
    transform: rotate(360deg);
  }
}
.activity-card-panel {
  max-width: 310px;
  min-width: 280px;
  width: 100%;
  background-color: var(--color-white);
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.0625rem solid var(--color-ey-gray-border-1);
    border-left: 0.0625rem solid var(--color-ey-gray-border-1);
    border-right: 0.0625rem solid var(--color-ey-gray-border-1);
    font-size: var(--font-size-5);
    max-height: 45px;
    color: var(--color-ey-confident-grey);
    padding: 0.8125rem;
    text-align: left;

    &--menu {
      border-radius: 50%;
      height: 1.7rem;
      padding: 0;
      text-align: center;
      width: 1.7rem;
      color: var(--color-ey-grey-2);

      &:hover,
      &:focus,
      &:active {
        //color: var(--color-ey-gray-info-text); /*color token not found*/
        color: var(--color-ey-confident-grey); /*using alternative*/
        background-color: transparent !important;
      }
    }
  }
  &__dropdown {
    border-radius: 0;
    cursor: pointer;
  }
  &__body {
    border-bottom: 0.0625rem solid var(--color-ey-gray-border-1);
    border-left: 0.0625rem solid var(--color-ey-gray-border-1);
    border-right: 0.0625rem solid var(--color-ey-gray-border-1);
    overflow-y: auto;
    max-height: 600px;
  }
  &__collapsible-header {
    color: var(--color-ey-confident-grey);
    background-color: var(--color-ey-grey-4);
    padding: 0.5625rem 0.625rem 0.5625rem 1.4375rem;
    text-align: left;
    font-size: var(--font-size-3);
    cursor: pointer;

    &::after {
      font-family: var(--font-family-materialIcons);
      content: "keyboard_arrow_down";
      font-size: var(--font-size-4);
      float: right;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: "liga";
    }

    &.collapsed::after {
      content: "keyboard_arrow_up";
    }
  }
  &__collapsible-body {
    & > div {
      border-bottom: 0.0625rem solid var(--color-ey-gray-border-1);
    }
  }
  .activity-card {
    max-width: 310px;
  }
}
