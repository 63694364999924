/*
    This file contains common mixins
*/

/*Mixins*/
@mixin base-field() {
    background: var(--color-ey-grey-4) !important;
    color: var(--color-ey-confident-grey);
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none !important;
    padding: 0.5rem 0.75rem;
   font-size: var(--font-size-3);

    &:disabled {
        background: var(--color-gray-alt-4) !important;
        color: var(--color-ey-gray-tint-1) !important;
    }
}

@mixin bold() {
    font-weight: bold;
}

@mixin col-fixed($width) {
    position: relative;
    min-height: 1px;
    padding-right: 16px;
    padding-left: 16px;
    float: left;
    width: $width;
}

@mixin col-fluid($width) {
    position: relative;
    min-height: 1px;
    padding-right: 16px;
    padding-left: 16px;
    float: left;

    @if $width != 100% {
        width: calc(100% - #{$width});
    } @else {
        width: 100%;
    }
}

@mixin fonts-col__field-labels-on-focus($line-height: 1.125rem) {
    font-size: var(--font-size-2) !important;
    font-family: var(--font-family) !important;
    line-height: $line-height !important;
}

@mixin fonts-col__form-field-inputs {
    font-size: var(--font-size-3) !important;
    font-family: var(--font-family-light) !important;
    line-height: 1.25rem !important;
}

@mixin input-error() {
    @include fonts-col__field-labels-on-focus;
    font-size: var(--font-size-3) !important; //override
    color: var(--color-red-05);
    padding-left: 0.75rem;
    width: 100%;
    text-align: left;
    position: relative;
    top: -1.6625rem;
}

@mixin input-field() {
    @include base-field();

    height: 2.5rem !important;

    &::placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: var(--color-ey-dark-gray);
    }
    &:focus {
        outline: none !important;
        box-shadow: none;
    }
    &:active {
        outline: none !important;
        box-shadow: none;
    }
}

@mixin standard-page-padding($hasBreadcrumb: false) {
    padding: 1.25rem 2.5rem;

    @if $hasBreadcrumb {
        padding-top: 0;
    }
}

@mixin select-field() {
    @include base-field();

    height: 2.8125rem !important;

    option {
        background: var(--color-white);
    }
}

@mixin textarea-field() {
    @include base-field();

    display: flex !important;
    @include fonts-col__form-field-inputs;
    $textarea_height: 6.125rem;
    $textarea_labelheight: 1.875rem;
    font-size: var(--font-size-4) !important; //override
    font-weight: 300;
    background-color: transparent;
    border: 0 solid transparent;
    width: 100%;
    color: var(--color-ey-confident-grey);
    text-align: left;
    padding-left: 0.75rem;
    padding-top: 0;
    margin-top: 0.625rem;
    overflow: hidden;
    resize: none;

    //dynamic based on element state
    height: calc(#{$textarea_height} - #{$textarea_labelheight});

    &:focus {
        outline: unset;
    }
}

@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Use to force page to display gray all the way to the bottom of the page.
@mixin footer-white-space() {
    --space: 16px;
    margin-left: calc(var(--space) * -1);
    margin-right: calc(var(--space) * -1);
    padding-left: var(--space);
    padding-right: var(--space);
    display: flex;
    flex-flow: column;
    height: 100%;
    background-color: var(--color-ey-grey-4);
}
