/* DATA TABLE */

@mixin fixPositionFirstColumn() {
  //position: fixed; /* added to support older browsers //notworking */
  position: sticky;
  position: -webkit-sticky;
  left: 0;
}

@mixin tableoverflowmenuOverride() {
  .overflow-menu-toggle.overflow-menu-toggle--datatable {
    & + .dropdown-menu {
      &:before {
        display: none;
      }
    }
  }
}

.data-table-standard {
  border: 0.0625rem solid var(--color-ey-grey-3);
  &-container {
    width: 100%;
  }
  &__row {
   font-size: var(--font-size-3);
    color: var(--color-ey-confident-grey);
    text-align: left;
    line-height: 1.125rem;
    height: 2.813rem;
    vertical-align: middle;

    &:first-child {
      border-top: 0.125rem solid var(--color-border-2);
      &:hover {
        border-top: 0.125rem solid var(--color-border);
      }
    }
    & .btn:focus {
      outline: 0.3125rem auto -webkit-focus-ring-color;
    }
  }
  &__header-row {
    background-color: var(--color-white);
    font-family: var(--font-family-bold);
    font-size: var(--font-size-4);
    color: var(--color-ey-confident-grey);
    line-height: 1.313rem;
    height: 2.5rem;
    & :nth-child(n + 2) {
      min-width: 9rem;
    }
    .table-bordered thead & th {
      border-bottom: none; //override, moved border to tbody 1st-tr for the hover to work
    }
  }
  &__filter-button {
    display: none;
  }
  &__filter-button,
  &__edit-button {
    float: right;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  & button.data-table-standard__edit-button {
    padding: 0;
    margin: 0.0625rem 0.375rem;
  }
  & tbody tr {
    background-color: var(--color-white);
    border-collapse: separate;
    border-spacing: 0.1875rem;
    border: 0.0625rem solid var(--color-border-2);

    &:nth-of-type(odd) {
      background-color: var(--color-ey-grey-5);
    }
    &:hover,
    &:focus,
    &:active {
      cursor: pointer;

      td {
        border-top: 0.0625rem solid var(--color-border);
        border-bottom: 0.0625rem solid var(--color-border);
        outline: none;
        font-family: var(--font-family-bold) !important;
      }
    }
  }
  & td {
    @include fonts-col__table-data(1.25rem);
    padding: 0 0.5rem 0 0.5rem;
    border: none;
    vertical-align: middle;
    &.data-table-standard__actions {
      max-width: 4.75rem;
      background-color: inherit;
      padding: 0;
      @include fixPositionFirstColumn();
      box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2);
      & > div {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        padding-right: 0.5625rem;
      }
      & .container {
        margin: 0;
        padding: 0;
        width: 4.125rem;
        cursor: pointer;
        & .checkbox__checkmark {
          left: 0.75rem;
          top: 0.39rem;
          cursor: pointer;
        }
      }
    }

    .btn--link-blue {
      margin: 0;
      height: 1.375rem;
     font-size: var(--font-size-3) !important;
      text-align: left;
    }
  }
  & thead tr {
    border: 0.0625rem solid var(--color-border-2);
  }
  & th {
    @include fonts-col__table-data-headers(1.25rem);
    padding: 0.5rem;
    border: none;
    text-align: left;
    &.data-table-standard__actions {
      max-width: 4.75rem;
      border: none;
      @include fixPositionFirstColumn();
      &--border {
        background-color: var(--color-white);
        border-bottom: 0.125rem solid var(--color-border-2);
        box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2);
      }
      & label {
        margin: 0;
        padding: 0;
        width: 4.125rem;
        & .checkbox__checkmark {
          position: relative;
          left: 0.25rem;
          top: 0.29rem;
          cursor: pointer;
          min-width: 1.125rem;
          display: block;
        }
      }
    }
    & .data-table-standard__sort-button {
      min-width: 1.125rem !important;
      width: 1.125rem;
      height: 1.5rem;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      color: var(--form-color-default);
      float: right;
      display: flex;
      .material-icons {
        font-size: var(--font-size-6);
        line-height: 1.5rem;
      }
    }
  }

  //Bug#84879: Content is visible to the left of the checkboxes when scrolled.
  & tr {
    td:first-child:after,
    th:first-child:after {
      content: "";
      position: absolute;
      left: -0.0625rem;
      top: 0;
      height: 100%;
      border-left: 0.0625rem solid var(--color-border-4); //no token, must be same as border but 100% opacity
      //this is to hide content on left most portion when scrolling the table.
    }
  }
  @include tableoverflowmenuOverride();
}

/* TOOL BAR */

/*---- Customization for Toolbox start here -------------*/

/* Icons size */

.material-icons {
  &.mi-25rem {
    font-size: var(--font-size-10);
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 0.3125rem;
  }
  &.mi-20rem {
    font-size: var(--font-size-9);
    width: 2rem;
    height: 2rem;
  }
  &.mi-15rem {
    font-size: var(--font-size-8);
    width: 1.5rem;
    height: 1.5rem;
  }
  &.mi-13rem {
    font-size: 1.3rem;
    vertical-align: middle;
    width: 1.3rem;
    height: 1.3rem;
    &--right {
      float: right;
      line-height: 1.5rem;
    }
  }
  &.mi-125rem {
    font-size: var(--font-size-7);
    height: 1.25rem;
    vertical-align: middle;
  }
  &.mi-11rem {
    font-size: 1.1rem;
    height: 1.1rem;
    vertical-align: middle;
  }
}

.ey-export-icon {
  background-image: url(/assets/images/toolbar/excel-export.svg);
  background-size: contain;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  display: inline-flex;
}

.navbar {
  $this: &;
  padding: 0.375rem !important;
  margin: 0 !important;
  justify-content: space-between;
  & #{$this}__menu {
    padding-left: 0.25rem;
    padding-right: 1.5rem;
    @media (min-width: 1240px) and (max-width: 1500px) {
      display: none !important;
    }
  }
  & #{$this}__link {
    color: var(--color-black);
    padding: 0.5rem 0.46875rem;
    margin: 0%;
    padding-bottom: 0;
    &:hover,
    &:focus {
      background-color: var(--color-gray-alt-2);
    }
    &:active {
      background-color: var(--color-gray-alt-2);
    }
    &__responsive {
      color: var(--color-ey-confident-grey);
      padding: 0.5rem 0.46875rem;
      margin: 0%;
      padding-bottom: 0;
      &:hover,
      &:focus {
        color: var(--color-ey-grey-1);
      }
      &:active {
        color: var(--color-ey-grey-1);
      }
    }
  }
  & #{$this}__dropdown {
    margin: 0%;
    & #{$this}__link {
      min-width: min-content;
      height: 3rem;
      margin: 0rem;
      padding: 0.5rem 0.625rem;
    }
  }
  & #{$this}__dropdown-menu {
    @media (min-width: 1240px) and (max-width: 1500px) {
      display: flex !important;
    }
    & .dropdown-item.aOptionsViews {
      color: var(--color-ey-link-blue);
    }
    .dropdown-menu.dropright .dropdown-menu.dropDownMenuSavedViews {
      top: calc(100% - 2.813rem);
    }
  }
  & #{$this}__form {
    max-height: 2.813rem;
    &__content {
      height: auto;
      border: 0.03125rem solid rgba(var(--color-ey-grey-2), 0.35);
      background-color: var(--color-ey-grey-4);
      z-index: 2;
      &.active {
        border-bottom: none;
        box-shadow: 0.125rem 0.125rem 0.25rem 0 rgba(var(--color-black-rgb), 0.3);
        border-right: none;
      }
      & #{$this}__form__render-pills {
        text-align: justify;
        min-height: 2.813rem;
        max-width: 85%;
        & > .pill {
          margin-left: 0.4375rem;
          margin-right: 0.4375rem;
          height: 1.563rem;
          &.pill--dropdown {
            display: inline-block;
            padding: 0;
            position: relative;
            z-index: 99;
            top: 0;
            padding-top: 0.0625rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            margin-top: 0.53125rem;
            margin-bottom: 0.53125rem;
            border-radius: 1.5625rem;
            &.count {
              @include fonts-col__table-data-headers(1rem);
              cursor: pointer;
              color: var(--color-black);
              letter-spacing: 0;
              text-align: right;
              background: none;
              margin-bottom: 0.40625rem;
            }
            &.hidden,
            &.responsive {
              display: none;
            }
            @media (max-width: 992px) {
              &.pill--dropdown {
                display: none;
              }
              &.responsive {
                display: inline-block;
              }
            }
            & .Pill__label {
              line-height: 1.313rem;
              font-size: var(--font-size-4);
            }
          }
        }
      }
    }
    &__form {
      width: 100%;
      text-align: justify;
      display: flex;
      z-index: 2;
    }
    &__input {
      width: 100%;
      min-width: 4.375rem;
      background-color: var(--color-ey-grey-4);
      @include fonts-col__sub-nav-default-state;
      color: var(--color-ey-confident-grey);
      font-style: italic;
      margin: 0.0625rem;
      height: 2.625rem;
    }
    &__button {
      min-width: 0%;
      background-color: var(--color-ey-grey-4);
      height: inherit;
      padding: 0;
      right: 1.25rem;
      position: absolute;
      z-index: 3;
      margin-top: 0.625rem;
      margin-right: 0.1875rem;
      &:before {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        vertical-align: middle;
        text-align: center;
        font-family: var(--font-family-materialIcons);
        line-height: 1rem;
        font-size: var(--font-size-8);
        content: "search";
        color: var(--color-ey-confident-grey);
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-feature-settings: "liga";
        font-feature-settings: "liga";
      }
    }
    &__input:focus,
    &__input:hover,
    &__btn:focus,
    &__btn:hover {
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &__toolbar-menu {
      display: none;
      margin-top: 0;
      background: var(--color-white);
      border-radius: 0;
      border: 0.0625rem solid var(--color-ey-grey-4);
      box-shadow: 0.125rem 0.125rem 0.25rem 0 var(--color-ey-grey-1);
      min-height: 7.938rem;
      min-width: 28.25rem;
      border-top: none;
      padding-top: 0;
      z-index: 999;
      width: inherit;
      text-align: justify;
      & .dropdown--multi-select {
        display: inline-block;
        margin-right: 0.625rem;
        margin-top: 0.625rem;

        & .dropdown-toggle {
          height: 1.875rem;
          width: 6.25rem;
          display: block;
          min-height: 1.875rem;
          // border: 0.0625rem solid var(--color-ey-grey-2); //use default - input fld
          background-color: var(--color-white);
          &.dropdown-filter .textinput-group__textinput {
            background: rgba(var(--color-ey-grey-2), 0.35);
          }
          & .textinput-group__textinput {
            padding: 0;
            padding-left: 0.3125rem;
            // background-color: transparent; //use default - input fld
            outline: none;
            padding-left: 0.75rem;
          }
          & .textinput-group__label {
            @media (max-width: 550px) {
              left: 0%;
            }
            position: absolute;
            top: 0.25rem;
            min-height: 0;
            margin-bottom: 0;
            justify-content: normal;
          }
        }
        & .dropdown__expand_less,
        & .dropdown__expand_more {
          right: 0.275rem;
          top: 0.15rem;
        }
      }
      &__header {
        padding: 1.063rem 1.063rem 0.625rem 1.063rem;
      }
      &__information {
        @include fonts-col__table-data(1.125rem);
        max-width: 12.81rem;
        display: inline-block;
      }
      &__remove-filters {
        @include fonts-col__table-data;
        color: var(--color-ey-link);
        float: right;
        display: none;
        &:hover {
          text-decoration: none;
        }
      }
      &__filters {
        padding: 0 1.063rem 0 1.063rem;
      }
      &__footer {
        margin-bottom: 1.063rem;
        padding: 1.063rem;
        display: block;
      }
      &__close {
        @include fonts-col__table-data;
        color: var(--color-ey-link);
        float: right;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  & #{$this}__buttons {
    & .btn {
      @include fonts-col__sub-nav-selected-state;
      margin: 0.3125rem;
    }
    & .btn--bkwhite {
      background: var(--color-white);
      border: 0.0625rem solid var(--color-black);
    }
  }
  & #{$this}__dropdown {
    & .dropdown-toggle {
      &:after {
        margin-left: 0rem;
        vertical-align: super;
      }
      &.carent-none::after {
        content: none !important;
      }
    }
    & .dropdown-menu {
      color: var(--color-ey-dark-gray);
      &.dropDownMenuSavedViews {
        top: 70%;
        left: 97%;
      }
      & .dropdown-item {
        &:hover,
        &:focus,
        &:active {
          text-decoration: none;
          background-color: var(--color-ey-grey-4);
        }
        &.pl-4 {
          padding-left: 2rem !important;
        }
        &.aOptionsViews {
          color: var(--color-ey-link-blue);
        }
      }
      & .dropdown-header {
        font-size: var(--font-size-4);
        letter-spacing: 0;
        line-height: 1.313rem;
        color: var(--color-ey-dark-gray);
      }
    }
  }
  .tool-tip-definition:focus:before,
  .tool-tip-definition:focus:after,
  .tool-tip-definition:active:before,
  .tool-tip-definition:active:after {
    visibility: hidden;
    opacity: 0;
  }
}
.dropdown-toggle {
  &.carent-none::after {
    content: none;
  }
}
.dropdown-item {
  @include fonts-col__instructional-text(1.65rem);
  &:hover,
  &:focus,
  &:active {
    color: initial;
    text-decoration: none;
    background-color: var(--color-ey-grey-4);
  }
}

/*---------------END OF: Customization for Toolbox -------------*/

/* Group By drag bar */
.data-table-group {
  &--empty,
  &--applied {
    background: var(--color-white);
    border: 0.0625rem solid var(--color-ey-grey-2);
    width: auto;
  }
  &--empty {
    min-height: 2.688rem;
    &__message {
      @include fonts-col__body-copy(2.375rem);
      display: inline-block;
      height: 100%;
      width: 100%;
      padding-left: 0.9375rem;
      padding-right: 0.9375rem;
      font-style: italic;
      text-align: justify;
    }
  }
  &--applied {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    min-height: 3.125rem;
    width: 100%;
    display: inline-block;
    & .pill {
      border-radius: 1rem;
      border: 0.0625rem solid var(--color-ey-grey-1);
      display: block;
      height: 100%;
      margin: 0.5625rem 0.3125rem 0.625rem 0.3125rem;
      float: left;
      padding: 0;
      padding-left: 0.4375rem;
      &__icon,
      &__close {
        & .material-icons {
          font-size: 0.9375rem;
        }
      }
      &__icon {
        float: left;
      }
      &__text {
        margin-left: 0.3125rem;
        float: left;
        line-height: 1;
      }
      &__close {
        float: right;
        margin-right: 0.625rem;
        border: none;
        background: transparent;
        color: var(--color-ey-grey-1);
        padding: 0;
        & .material-icons {
          cursor: pointer;
        }
      }
    }
    & .pill--group-by {
      min-width: 6.25rem;
      font-size: var(--font-size-4);
      padding-top: 0.4375rem;
      padding-bottom: 0.3125rem;
      min-height: 1.875rem;
    }
  }
  @include tableoverflowmenuOverride();
}

/*---- Customization for Data Table Flex -------------
NOTE: Data Table Flex is using .data-table-standard as its base style -------*/

.data-table-standard--flex {
  .data-table-standard__header-row {
    :nth-child(n + 2) {
      min-width: 9.188rem;
    }
    .data-table-standard__actions {
      + th {
        padding-left: 1.25rem;
      }
    }
  }
  .data-table-standard__actions {
    > div {
      padding-left: 0.5625rem;
    }
    + td {
      padding-left: 1.25rem;
    }
  }
  @include tableoverflowmenuOverride();
}

/*----END OF: Customization for Data Table Flex -------------*/
