.filter {
  display: -webkit-inline-box !important;
  display: inline-flex !important;
  -webkit-box-align: center !important;
  align-items: center !important;

  height: 1.25rem;
  background: var(--color-white);
  border: 0.0625rem solid var(--color-ey-confident-grey);
  border-radius: 1rem;
  min-width: 0;

  //text
  color: var(--color-ey-confident-grey);
  font-size: var(--font-size-2);
  font-weight: normal;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.125rem;

  //padding 0, set margins on child elements
  padding: 0 0 0.125rem 0;

  //margin
  margin: 0.875rem;
  &:first-child {
    margin-left: 0;
  }

  &.filter--large {
    height: 2rem;
    border-radius: 1rem;
  }

  &:hover, &:focus{
    background-color: var(--color-ey-confident-grey);
    color: var(--color-white);
    button.filter__close{
      color: var(--color-white);

      &:hover, &:focus{
        background-color: var(--color-ey-gray-alt);
        border-radius: 50%;
      }
    }
  }
}

.filter__label {
 font-size: var(--font-size-3);
  font-family: var(--font-family-light) !important;
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  line-height: 0.875rem;

  //set margin - left,right
  margin: 0;
  &:first-child {
    margin-left: 0.5rem;
  }
  &:only-child {
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  //end of: set margin - left,right

  .filter--large & {
    font-size: var(--font-size-4);

    &:first-child {
      margin-left: 0.625rem;
    }
  }
}
.filter__down {
  margin: 0 0.25rem 0 0.625rem;
  .material-icons {
   font-size: var(--font-size-3);
    .filter--large & {
      font-size: var(--font-size-4);
    }
    vertical-align: middle;
    cursor: pointer;
  }
}

.filter__close {
  margin: 0 0.25rem 0 0.25rem;
  .filter--large & {
    margin: 0 0.625rem 0 0.25rem;
  }
  .material-icons {
   font-size: var(--font-size-3);
    .filter--large & {
      font-size: var(--font-size-7);
    }
    vertical-align: middle;
    cursor: pointer;
  }
}

button.filter__close {
  // override styles for <button>
  color: var(--color-ey-confident-grey);
  background-color: transparent;
  border: none;
  padding: 0;
  width: 1rem;
  height: 1rem;
  line-height: 0;
  margin-top: 0.0625rem;
}
.filter--large{
  button.filter__close {
    color: var(--color-ey-confident-grey);
    background-color: transparent;
    border: none;
    padding: 0;
    width: 1.4375rem;
    height: 1.4375rem;
    line-height: inherit;
    margin-top: 0;
  }
}

