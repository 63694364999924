/* card */
.accordion__card {
  border: none;
}

/* header */
.accordion__cardheader {
  border: none;
  height: 2.813rem;
  padding: 0;
  margin: 0;
}

/* title */
.accordion__title {
  font-size: var(--font-size-4) !important;
  font-family: var(--font-family) !important;
  line-height: 1.375rem !important;
  margin: auto 0 auto 0;
  color: var(--color-ey-confident-grey);
  vertical-align: middle;
  padding-left: 0.625rem;
}

/* button */
.accordion__btn {
  width: 100%;
  height: 2.813rem;
  border: 0.0625rem solid var(--color-ey-grey-2a);
  color: var(--color-ey-confident-grey);
  text-decoration: none;
  padding-left: 0.625rem;
  padding-right: 1rem;
  margin: 0;
  display: flex;
  background: var(--color-white);
}

.accordion__btn {
  &:hover,
  &:focus,
  &:active {
    background-color: inherit;
    color: inherit;
    text-decoration: none;
    outline: none;
    // removed: #164999, #158849
    // & .material-icons {
    //   background-color: var(--color-ey-grey-2a);
    // }
  }
}
.accordion__btn:not(.collapsed) {
  background: var(--color-ey-grey-5);
  border-bottom: none;
}

/* button - toggle add/minus icon */
.accordion__btn .material-icons {
  font-size: var(--font-size-6);
  margin: auto 0 auto 0;
}
.accordion__btn.collapsed .material-icons.add {
  display: inherit;
}
.accordion__btn:not(.collapsed) .material-icons.add {
  display: none;
}
.accordion__btn.collapsed .material-icons.minus {
  display: none;
}
.accordion__btn:not(.collapsed) .material-icons.minus {
  display: inherit;
}

/* card body*/
.accordion__textbox {
  border: none;
}
.accordion__cardbody {
  @include fonts-col__sub-nav-default-state;
  color: var(--color-ey-confident-grey);
  line-height: 1.375rem;
  border: 0.0625rem solid var(--color-ey-grey-2a); // var(--color-ey-grey-2);
  border-top: none;
  text-align: justify;
  padding: 0.75rem 1.25rem 0.75rem 2.4rem;
  background-color: var(--color-ey-grey-5);
}
