/* Overflow Menu */

/*---- Customization for Overflow Menu start here -------------*/

.overflow-menu {
  &-component {
    display: inline-block;
    position: relative;
  }
  &.dropdown-menu {
    margin-top: 0.625rem;
    padding: 0;
    min-width: 12.5rem;
    background: var(--color-white);
    border: 1px solid var(--color-ey-confident-grey);
    border-radius: 0px;
    box-shadow: -0.125rem -0.125rem 0.3125rem 0 rgba(var(--color-ey-interaction-overlays-rgb), 0.2);
    &:before {
      content: "";
      position: absolute;
      height: 1.25rem;
      width: 1.25rem;
      border-left: solid 0.0625rem var(--color-ey-confident-grey);
      border-top: solid 0.0625rem var(--color-ey-confident-grey);
      top: 0%;
      right: 1rem;
      margin-left: -0.625rem;
      margin-top: -0.6875rem;
      transform: rotate(45deg) skew(0deg, 0deg);
      background: var(--color-white);
      z-index: -1;
    }
  }
  .dropdown-menu {
    //for sub-menus
    background: var(--color-white);
    border: solid 0.0625rem var(--color-border);
    border-radius: 0;
    box-shadow: 0.125rem 0 0.375rem 0 rgba(var(--color-black-rgb), 0.15);
    padding: 0;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    width: 9.5rem;
    margin-top: -1rem;
  }
  &--horizontal {
    .dropdown-item {
      .material-icons {
        color: var(--color-ey-confident-grey);
      }
    }
  }
  &--vertical {
    .dropdown-item {
      .material-icons {
        color: var(--color-gray-alt-1);
      }
    }
  }

  .dropdown-item {
    @include fonts-col__body-copy(1rem);
    color: var(--color-ey-confident-grey);
    font-weight: 300;
    border-radius: 0;
    height: 2.813rem;
    width: calc(100% - 0.0625rem);
    padding-left: 1.375rem;
    padding-left: 0.875rem;
    padding-right: 0.875rem;
    align-items: center;
    display: flex;

    &:hover,
    &.hover {
      background: var(--color-ey-grey-2a);
      outline: none;
      cursor: pointer;
    }

    i {
      margin: 0 0.625rem 0 0;
      font-size: var(--font-size-5);
      vertical-align: middle;
    }
    &--next-icon {
      position: relative;
      right: -0.4375rem;
      float: right;
      color: var(--color-ey-gray-alt);
      font-size: var(--font-size-8) !important;
      margin: 0 !important;
    }
  }
}

.overflow-menu-toggle {
  &.dropdown-toggle {
    & .material-icons {
      cursor: pointer;
      width: 3.438rem;
      font-size: var(--font-size-8);
      height: var(--font-size-10);
      color: var(--color-ey-confident-grey);
    }
    &:hover,
    &:active,
    &:focus {
      .material-icons {
        color: var(--color-ey-grey-1);
      }
    }
    // prettier-ignore
    &[aria-expanded=true] {
      .material-icons {
        color: var(--color-ey-grey-1);
        font-size: var(--font-size-8);
      }
    }
    &:after {
      content: none;
    }
  }
  &.overflow-menu-toggle--datatable {
    & .material-icons {
      font-size: var(--font-size-8);
      width: 1.5rem;
      height: 1.5rem;
      margin-top: 0.25rem;
    }
    & + .dropdown-menu {
      margin-left: 0.75rem;
      margin-top: -0.6875rem;
      &:before {
        //left arrow
        top: 2rem;
        right: 9.463rem;
        transform: rotate(136deg) skew(15deg, 15deg);
        border-right: 0.0625rem solid var(--color-border);
        border-bottom: 0.0625rem solid var(--color-border);
        border-left: none;
        border-top: none;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}
