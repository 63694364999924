.footer {
  &__group {
    font-family: var(--font-family);
    color: var(--color-white);
    font-size: var(--font-size-4);
    letter-spacing: 0;
    line-height: 1.5rem;
    text-align: center;
    background: var(--color-ey-confident-grey);
    width: 100%;
    vertical-align: middle;
    padding: 0 2rem 0 2rem;
  }
  &__image {
    margin-top: 2.429rem;
    margin-bottom: 0;
    width: 5.143rem;
    height: 5.143rem;
    vertical-align: middle;
    border-style: none;
  }
  &__parag {
    @include fonts-col__sub-nav-default-state;
    line-height: 1.313rem !important;
    padding: 1rem 1em 3.143rem 1em !important;
    margin-bottom: 0;
    & .material-icons {
      font-size: var(--font-size-5);
      vertical-align: sub;
    }
  }
  &--anchor,
  &--anchor:active,
  &--anchor:hover,
  &--anchor:visited {
    color: var(--color-ey-yellow);
    font-weight: 500;
    text-decoration: none;
  }

  &--sticky {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__sticky-footer {
    display: flex;
    @media screen and (max-width: var(--break-point-small)) {
      display: block;
    }
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -4px 8px 0px rgba(var(--color-black-rgb), 0.05);
    min-height: 4.0625rem;
    width: 100%;
    background: var(--color-white);
    flex-shrink: 0;
    padding: var(--spacing-01) var(--spacing-03);
    button {
      margin: 0 1rem 0 0;
      @media screen and (max-width: var(--break-point-small)) {
        margin-bottom: 0.3125rem;
        display: block;
        width: 100%;
      }
    }
    button:last-child {
      margin: 0;
    }
    i {
      margin: 0;
    }
  }

  &__sticky-body {
    flex-grow: 1;
    padding: var(--spacing-02) var(--spacing-03);
  }
}
