@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}
@mixin badge() {
  & {
    color: var(--color-white);
    background: transparent;
    width: 1rem;
    height: 1rem;
    top: 0.2625rem;
    position: absolute;
    font-size: var(--font-size-1);
    padding-top: 0.6rem;
    right: 0;
    font-family: var(--font-family-bold) !important;
  }
}
.global-header {
  position: relative;
  background: var(--color-ey-confident-grey);
  width: 100%;
  grid-area: head;
  justify-content: space-between;
  align-items: center;
  min-height: 3.125rem;
  &__tittle {
    display: flex;
    &__image {
      width: 1.5625rem;
      height: 1.625rem;
      margin: 0.75rem 0.375rem 0.75rem 0.9375rem;
    }
    &__divider {
      width: 0;
      height: 0.625rem;
      margin: 1.625rem 0 0.875rem 0;
      border-left: 0.0625rem solid var(--color-white);
      display: none; //latest assets
    }
    &__label {
      color: var(--color-white);
      margin: 1.5625rem 0 0.875rem 0.5rem;
      font-size: 0.9375rem;
      height: 0.6875rem;
      line-height: 0.6875rem;
      font-family: var(--font-family-light) !important;
    }
  }
  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    &__button {
      position: relative;
      background: 0 0;
      border: none;
      padding: 0;
      color: var(--color-white);
      width: 3.125rem;
      height: 3.125rem;
      line-height: 4.125rem;
      &:last-child {
        margin-right: 0.25rem;
      }
      &.material-icons.badgepad {
        padding-right: 0.3125rem; //adjust for badge
      }
      & .badge {
        @include badge();
      }
      &:hover,
      &:focus,
      &:active {
        background-color: var(--color-ey-interaction-overlays);
        outline: none;
      }
    }
  }
  &__search {
    position: absolute;
    right: 12.4375rem;
    top: 0;
    display: none;
    z-index: 2;
    @media screen and (max-width: var(--break-point-small)) {
      right: 0;
    }
    &__input {
      background: var(--color-ey-interaction-overlays);
      & input {
        color: var(--color-white);
        position: relative;
        border-radius: 0;
        border: none;
        height: 3.0625rem;
        width: 24.6875rem;
        background-color: transparent;
        &:focus {
          color: var(--color-white);
          background-color: transparent;
        }
      }
      & .material-icons {
        position: absolute;
        top: 0.9375rem;
        right: 0.9375rem;
        color: var(--color-white);
        font-size: var(--font-size-7);
        cursor: pointer;
      }
    }
    &__content {
      background: var(--color-ey-confident-grey);
      border-radius: 0;
      box-shadow: 0 0 0.5625rem 0 rgba(var(--color-black-rgb), 0.09);
      width: 24.6875rem;
      padding-bottom: 1.25rem;
      &__section {
        &__tittle {
          padding-left: 1.25rem;
          padding-right: 1.25rem;
          & span {
            border-bottom: 0.03125rem solid var(--color-ey-grey-2);
            padding-top: 1.25rem;
            padding-bottom: 0.375rem;
           font-size: var(--font-size-3);
            color: var(--color-white);
            display: inline-block;
            width: 80%;
            text-align: justify;
            cursor: pointer;
            & i {
              line-height: 1.5;
              font-size: var(--font-size-4);
              margin-right: 0.8125rem;
            }
          }
          & a.view-all {
            width: 20%;
            display: inline-block;
            color: var(--color-white);
           font-size: var(--font-size-3);
            border-bottom: 0.03125rem solid var(--color-ey-grey-2);
            padding-bottom: 0.375rem;
            cursor: pointer;
            &:hover {
              color: var(--color-white);
            }
          }
        }
        &__options {
          & span {
            display: flex;
            padding-left: 2.875rem;
            padding-top: 0.9375rem;
           font-size: var(--font-size-3);
            color: var(--color-white);
            font-family: var(--font-family-light) !important;
          }
        }
      }
    }
    &__menu {
      width: 26.5625rem;
      height: 100%;
      background: var(--color-ey-confident-grey);
      right: -26.5625rem;
      position: fixed;
      top: 0;
      transition: right 0.5s;
      display: flex;
      z-index: 9999;
      &.hideMobile {
        right: -26.5625rem;
      }
      &.showMobile {
        right: 0%;
      }
      &-left {
        width: 5.6875rem;
        background: var(--color-ey-interaction-overlays);
        box-shadow: 0.1875rem 0 0.5rem 0 rgba(var(--color-black-rgb), 0.15);
        position: relative;
        text-align: center;
        & .avatar {
          border: none;
          margin-right: 0;
        }
        & button.avatar {
          background-color: inherit;
        }
        &__button {
          background: 0 0;
          border: none;
          min-height: 3.6875rem;
          width: 5.6875rem;
          color: var(--color-white);
          margin-top: 1.375rem;
          &:last-child {
            right: 0;
            position: absolute;
            bottom: 0;
          }
          & .material-icons {
            position: relative;
            & .badge {
              @include badge();
            }
          }
          & span {
            display: block;
            font-size: var(--font-size-4);
            font-family: var(--font-family-light) !important;
          }
          &.active {
            background: var(--color-ey-confident-grey);
            border-bottom: 0.25rem solid var(--color-ey-yellow);
          }
        }
      }
      &-right {
        width: 20.875rem;
        & .btn-close {
          display: block;
          width: 2.25rem;
          height: 2.25rem;
          color: var(--color-white);
          cursor: pointer;
          margin: 0.9375rem 1.25rem 1.875rem 0;
          padding: 0;
          border: 0;
          background: transparent;
          float: right;
          & .material-icons {
            font-size: 2.25rem;
          }
        }
        & ul {
          list-style-type: none;
          padding: 0;
          & li {
            width: 100%;
            display: flex;
            color: var(--color-white);
            & button {
              // override default button style
              background-color: inherit;
              min-width: inherit;
            }
            & a,
            & button {
              cursor: pointer;
              text-align: left;
              font-size: var(--font-size-5);
              font-family: var(--font-family-light) !important;
              padding: 0.5625rem 0 0.5rem 1.875rem;
              width: 100%;
              border: inherit; //override default button style
              border-left: 0.25rem solid transparent;
              color: inherit; //override default button,a style
              height: 2.813rem;
              line-height: 1.75rem;
              &:hover,
              &:focus,
              &.active,
              &:active {
                outline: none;
                text-decoration: none; //override default a:href style
                background: var(--color-ey-interaction-overlays);
                font-family: var(--font-family-bold) !important;
              }
              &:focus,
              &.active,
              &:active {
                border-left: 0.25rem solid var(--color-ey-yellow);
              }
            }
          }
        }
      }
    }
  }
  &__content {
    min-height: 1.25rem;
    &__tittle {
      display: flex;
      font-size: var(--font-size-4);
      color: var(--color-white);
      line-height: 0.75rem;
      margin-left: 0.9375rem;
      margin-bottom: 0;
      font-family: var(--font-family-light) !important;
      .global-header--tabs & {
        margin-bottom: 1rem;
      }
    }
  }
  &--tabs__tabs {
    margin-top: 1rem;
    & ul {
      list-style-type: none;
      height: 2.5rem;
      margin: 0;
      padding-left: 0.9375rem;
      padding-right: 1rem;
      background-color: var(--color-ey-confident-grey);
      & li {
        min-width: 7.625rem;
        float: left;
        font-size: var(--font-size-5);
        height: 100%;
        color: var(--color-white);
        & a,
        & button {
          text-align: center;
          cursor: pointer;
          height: 100%;
          line-height: 2.5rem !important;
          display: block;
          border-bottom: none;
          font-size: var(--font-size-4);
          font-family: var(--font-family-light) !important;
          border: none; // override default button
          border-bottom: 0.25rem solid transparent;
          color: inherit; // override default a,button style
          &:hover,
          &:focus,
          &:active {
            outline: none;
            text-decoration: none;
          }
          &.active {
            border-bottom: 0.25rem solid var(--color-ey-yellow);
            background: var(--color-ey-interaction-overlays);
            font-family: var(--font-family-bold) !important;
          }
        }
        & button {
          // override default button style
          background-color: inherit;
          min-width: inherit;
        }
        & a:hover {
          text-decoration: none; //override default a:href style
        }
      }
    }
  }
}

//
//
//
//
//

.motif.global-header {
  display: flex;
  height: 3.75rem;
  .global-header__hamburger {
    top: 0;
    width: 3.75rem;
    height: 3.75rem;
    display: flex;
    justify-content: center;
    border-right: 0.0625rem solid var(--color-ey-dark-gray);

    .global-header__hamburger__button {
      padding-top: 0.25rem;
      position: relative;
      background: 0 0;
      border: none;
      padding: 0;
      color: var(--color-white);
      width: 100%;
      overflow: hidden;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.313rem;
        height: 0.313rem;
        background: rgba(var(--color-white-rgb), 0.5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }
      &:focus:not(:active)::after {
        animation: ripple 2s ease-out;
      }
      &:hover,
      &.active {
        background: var(--color-ey-interaction-overlays);
      }
      .material-icons {
        font-size: 1.75rem;
      }
    }
  }
  .global-header_hamburger__visibility {
    visibility: hidden;
  }
  .global-header__tittle {
    height: 100%;
    flex: auto;
    align-items: center;
    padding: 0.5rem 0.875rem 0.5rem 1.25rem;
    .global-header__tittle__image {
      background: url("/src/assets/images/ey-logo.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      display: inline-block;
      margin: 0 0.5rem 0 0;
      width: 2.188rem;
      height: 2.275rem;
      margin-top: -0.625rem; // centered but needs fine adjustment
      & + .global-header__tittle__divider {
        display: none;
      }
    }
    .global-header__tittle__divider {
      width: 0.0625rem;
      height: 0.625rem;
      background: var(--color-white);
      border-radius: 0;
      display: flex;
      margin: 0;
    }
    .global-header__tittle__label {
      margin-right: 0.5rem;
      font-size: var(--font-size-4);
      font-weight: 300;
      height: 1.3125rem;
      line-height: 1.3125rem;
      display: flex;
      margin-top: 0;
      margin-bottom: 0;
      .global-header__tittle__sub-label {
        sup {
          top: 0.3rem;
        }
        font-family: var(--font-family-light); //NotoSans-Italic not available
        font-style: italic;
        font-weight: 400;
        font-size: 0.5625rem;
        height: 0.875rem;
        line-height: 0.875rem;
        display: flex;
        color: var(--color-ey-grey-2);
      }
      .material-icons {
        font-size: 1.3125rem;
        margin-left: 0.5rem;
        line-height: 1.3125rem;
        height: 1.3125rem;
        align-self: center;
      }
    }

    ::ng-deep {
      .subtitle-dropdown {
        border-radius: 0;
        margin: 0;
        padding: 0;
        .subtitle-option {
          padding: 0.5rem 1.5rem;
          position: relative;
          text-align: left;
          cursor: pointer;
          &.active {
            font-weight: bold;
          }
          &:hover {
            background-color: var(--color-ey-yellow-50);
          }
        }
      }
    }
  }
  .global-header__actions {
    display: flex;
    .global-header__actions__button {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 0;
      margin-right: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      .badge {
        color: var(--color-ey-grey-4);
        font-size: var(--font-size-2);
        font-family: var(--font-family-bold) !important;
        font-weight: bold;
        line-height: 0.5rem;
        padding: 0;
        right: 0.5rem;
        top: 1.125rem;
        left: unset;
        bottom: unset;
      }
      .avatar {
        width: 1.875rem;
        height: 1.875rem;
        border: none;
        background-color: var(--color-white);
        margin: 0;
        margin: unset;
      }
    }
  }
}
