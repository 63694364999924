@import './grid-menu';
@import './grid-pager';

.grid-wrapper {

    /* Over Grid Toolbar Styles */
    .k-grid-toolbar {
        padding: 0;
        color: var(--color-ey-dark-gray);
        background-color: var(--color-white);
        display: block;
        border-bottom: none;

        .grid-action-row {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            width: 100%;
            padding: 0.5rem 0 1rem 0;
            align-items: center;

            &.grid-action-default {
                & .button-group {
                    .button-container {
                        height: 2.5rem;
                        padding: 0.3rem;
                        align-items: center;
                        justify-content: center;
                        display: inline-flex;

                        &:not(preference-config-picker) {
                            width: 2.5rem;
                        }

                        & .ey-export-icon {
                            height: 1.5625rem;
                            width: 1.5625rem;
                            margin: 0 0 0 -0.0625rem;
                            justify-content: center;
                            align-items: center;
                        }

                        &:focus,
                        &:hover {
                            outline: -webkit-focus-ring-color auto 1px;
                        }
                    }

                    & .preference-config-picker-container .saved-views {
                        padding: 0.5rem 18px 0.5rem 0.5rem;
                        position: relative;
                        height: 2.625rem;

                        & .material-icons.down-arrow:nth-child(2) {
                            position: absolute;
                            left: 1.5625rem;
                            top: 0.5rem;
                        }
                    }
                }
            }

            .button-group {
                margin-right: 1rem;

                .button-container {
                    display: inline-block;
                    vertical-align: middle;
                    text-align: center;
                    height: 3.5rem;
                    border: 0.0625rem solid var(--color-ey-gray-tint-1);
                    padding: 1rem;
                    background-color: transparent;

                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        outline: -webkit-focus-ring-color auto 1px;
                    }

                    i {
                        font-size: var(--font-size-8);
                    }

                    &.header {
                        padding: 0.625rem;

                        i {
                            font-size: var(--font-size-6);
                        }

                        .button-header {
                            margin-top: 0.125rem;
                            font-size: var(--font-size-2);
                            margin-right: 0.3125rem;
                        }
                    }

                    &.active {
                        background: var(--color-ey-yellow);

                        &:hover {
                            background: var(--color-ey-yellow-50);
                        }
                    }
                }
            }

            .actions-group {
                margin-left: auto;

                ey-button {
                    vertical-align: top;
                    height: 2.813rem;

                    button {
                        margin-left: 1rem;
                        height: 2.813rem;
                    }
                }

                ey-select {
                    margin-top: 0.3125rem;
                }
            }

            .searchBox {
                .searchBox-icon {
                    border-style: solid;
                    border-width: 0.0625rem 0.0625rem 0.0625rem 0;
                    border-color: var(--color-ey-grey-3);
                    padding: 0.5625rem 0.5625rem 0.8125rem 0.5625rem;
                }
            }
        }
    }

    /* Grid Styles */
    kendo-grid,
    kendo-treelist,
    .treelist-custom-paginator {
        &.k-grid {
            td {
                text-align: left !important;
            }

            .k-reset {
                .k-icon {
                    color: var(--color-ey-dark-gray);
                }
            }

            border: none !important;

            input[type='checkbox']:not(.checkbox__input) {
                border: 0.0625rem solid var(--color-black);
                border-radius: 0;
                width: 1.25rem;
                height: 1.25rem;

                &.k-checkbox:indeterminate {
                    background-color: var(--color-white);
                }

                &:focus {
                    box-shadow: none;
                }

                &:checked {
                    background-color: var(--color-ey-confident-grey);

                    &:focus {
                        box-shadow: none;
                    }

                    &::before {
                        width: 0.875rem;
                        height: 0.875rem;
                        font-size: 0.9375rem;
                        top: 47%;
                        left: 48%;
                    }
                }
            }

            kendo-grid-filter-menu {
                float: right;

                .k-grid-filter {
                    color: var(--color-white);

                    .k-i-filter::before {
                        content: '\e031';
                    }
                }
            }

            kendo-treelist-list {
                tbody {
                    .k-icon {
                        height: 24px;
                        width: 28px;
                        font-size: var(--font-size-8);

                        &.k-i-expand {
                            position: relative;
                            display: inline-block;
                            color: var(--color-ey-confident-grey) !important;

                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: 'add';
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                /* Support for Firefox. */
                                -moz-osx-font-smoothing: grayscale;
                                /* Support for IE. */
                                font-feature-settings: 'liga';
                            }
                        }

                        &.k-i-collapse {
                            position: relative;
                            display: inline-block;
                            color: var(--color-ey-confident-grey) !important;

                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: 'remove';
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                /* Support for Firefox. */
                                -moz-osx-font-smoothing: grayscale;
                                /* Support for IE. */
                                font-feature-settings: 'liga';
                            }
                        }
                    }
                }
            }

            kendo-grid-list,
            kendo-treelist-list {
                .k-grid-content {
                    overflow-y: auto;
                    border-right: solid 0.06rem var(--color-ey-grey-3);
                    border-left: solid 0.06rem var(--color-ey-grey-3);
                    border-bottom: solid 0.06rem var(--color-ey-grey-3);
                    min-height: 21.875rem;
                }
            }

            .k-grouping-header {
                background-color: var(--color-white);
                border: solid 0.0625rem var(--color-gray-alt-2);
                vertical-align: middle;
                padding: 0 1rem;
                font-size: var(--font-size-3);
                color: var(--color-ey-dark-gray);
                line-height: 1.875rem;
                border-bottom: none;

                .k-indicator-container {
                    background-color: transparent;
                    padding: 0.3125rem 0;
                    margin-right: 0.5rem;
                    display: inline-block;
                    font-style: italic;

                    .k-group-indicator {
                        border: 0.0625rem solid var(--color-ey-grey-1);
                        border-radius: 1rem !important;
                        background-color: var(--color-white);
                        color: var(--color-ey-grey-1);
                        margin: 0;
                        padding-right: 0.5rem;
                        padding-left: 0.5rem;

                        .k-link {
                            font-family: var(--font-family) !important;
                            font-style: normal !important;

                            .k-icon {
                                &.k-i-sort-asc-sm {
                                    position: relative;
                                    display: inline-block;
                                    color: var(--color-ey-confident-grey) !important;

                                    &::before {
                                        font-family: var(--font-family-materialIcons);
                                        content: 'arrow_drop_up';
                                        -webkit-font-smoothing: antialiased;
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: 'liga';
                                    }
                                }

                                &.k-i-sort-desc-sm {
                                    position: relative;
                                    display: inline-block;
                                    color: var(--color-ey-confident-grey) !important;

                                    &::before {
                                        font-family: var(--font-family-materialIcons);
                                        content: 'arrow_drop_down';
                                        -webkit-font-smoothing: antialiased;
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: 'liga';
                                    }
                                }
                            }
                        }

                        .k-button-icon {
                            .k-icon {
                                &.k-i-group-delete {
                                    position: relative;
                                    display: inline-block;
                                    color: var(--color-ey-confident-grey) !important;

                                    &::before {
                                        font-family: var(--font-family-materialIcons);
                                        content: 'cancel';
                                        -webkit-font-smoothing: antialiased;
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: 'liga';
                                    }
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            .k-grid-header {
                background-color: var(--color-white);
                border-right: solid 0.0625rem var(--color-border-2);
                color: var(--color-white);
                font-size: var(--font-size-3);
                height: 2.5rem;
                font-feature-settings: 'liga';
                padding: 0 !important;
                border-top: solid 0.0625rem var(--color-border-2);

                .k-header {
                    border-left: 0;
                    border-right: 0;
                    font-family: var(--font-family-bold) !important;
                    font-weight: bold;
                    height: 2.5rem;
                    line-height: 2.5rem;
                    padding: 0 0 0 0.625rem;
                    position: relative;
                    vertical-align: top;
                    background-color: var(--color-white) !important;

                    &.utility-cell-header {
                        z-index: 1;
                        position: relative;
                        -webkit-box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2);
                        -moz-box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2);
                        box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2);
                    }

                    &:last-child {
                        border-right: none;
                    }

                    &:first-child:not(.k-group-cell) {
                        border-left: solid 0.0625rem var(--color-border-2);
                    }

                    &:focus {
                        outline: 0.0625rem solid -webkit-focus-ring-color;
                        outline-offset: -0.3125rem;
                    }

                    & {
                        color: var(--color-ey-confident-grey) !important;

                        .k-link {
                            color: var(--color-ey-confident-grey) !important;
                            font-family: var(--font-family-bold) !important;
                            font-weight: bold;
                            font-size: var(--font-size-3) !important;
                            padding-top: 0;
                            padding-bottom: 0;
                            padding-right: 0.625rem;
                            line-height: 2.5rem;
                            display: flex;
                            justify-content: space-between;
                            flex: 2 !important;

                            .k-icon.k-i-sort-asc-sm,
                            .k-icon.k-i-sort-desc-sm {
                                float: right;
                                line-height: 2.5rem;
                                margin-right: 0.5rem;
                            }

                            .k-sort-order {
                                float: right;
                                line-height: 2.5rem;
                                margin: 0;
                                color: inherit;
                            }

                        }

                        .k-icon {
                            font-weight: inherit;
                            vertical-align: middle;
                            margin: 0;
                        }

                        .k-cell-inner {
                            margin: 0;
                            justify-content: space-between;
                            overflow: visible;

                            kendo-grid-column-menu {
                                width: 1.875rem;
                                height: 100%;
                                text-align: center;

                                & .k-grid-header-menu.k-active {
                                    color: inherit;
                                    background-color: transparent;
                                }
                            }
                        }
                    }

                    kendo-grid-column-menu,
                    kendo-treelist-column-menu {
                        .k-grid-filter {
                            height: 2.5rem;
                            display: flex;
                            align-items: center;
                            padding: 0;
                            line-height: 1;
                            top: 0;
                            background: var(--color-white);
                            position: static;
                            float: right;

                            &:focus {
                                outline: 0.0625rem solid -webkit-focus-ring-color;
                                outline-offset: -0.3125rem;
                            }

                            .k-icon {
                                &.k-i-more-vertical {
                                    position: relative;
                                    display: inline-block;
                                    color: var(--color-ey-interaction-overlays) !important;

                                    &:hover,
                                    &:focus {
                                        color: var(--color-ey-grey-1) !important;
                                    }

                                    &::before {
                                        font-family: var(--font-family-materialIcons);
                                        content: 'more_vert';
                                        -webkit-font-smoothing: antialiased;
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: 'liga';
                                    }
                                }
                            }
                        }
                    }

                    .k-link {
                        .k-i-sort-asc-small {
                            &::before {
                                color: var(--color-ey-confident-grey);
                                font-family: var(--font-family-materialIcons);
                                content: "arrow_drop_up";
                                font-size: var(--font-size-5);
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                -moz-osx-font-smoothing: grayscale;
                                font-feature-settings: "liga";
                                padding: 0;
                                width: 1rem;
                                height: 1rem;
                                line-height: 1.125rem;
                                margin-right: 0.625rem;
                            }
                        }

                        .k-i-sort-desc-small {
                            &::before {
                                color: var(--color-ey-confident-grey);
                                font-family: var(--font-family-materialIcons);
                                content: "arrow_drop_down";
                                font-size: var(--font-size-5);
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                -moz-osx-font-smoothing: grayscale;
                                font-feature-settings: "liga";
                                padding: 0;
                                width: 1rem;
                                height: 1rem;
                                line-height: 1.125rem;
                                margin-right: 0.625rem;
                            }
                        }

                        .k-sort-icon:not(:has(.k-i-sort-asc-small)):not(:has(.k-i-sort-desc-small)) {
                            position: relative;
                            display: inline-block;
                            color: var(--color-ey-confident-grey) !important;
                            width: 1rem;
                            height: 2.5rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            padding: 0.5rem 0 0 0;

                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: 'arrow_drop_up';
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                /* Support for Firefox. */
                                -moz-osx-font-smoothing: grayscale;
                                /* Support for IE. */
                                font-feature-settings: 'liga';
                                font-size: 15px;
                                padding: 0;
                                width: 16px;
                                height: 16px;
                                line-height: 10px;
                            }

                            &::after {
                                font-family: var(--font-family-materialIcons);
                                content: 'arrow_drop_down';
                                -webkit-font-smoothing: antialiased;
                                text-rendering: optimizeLegibility;
                                /* Support for Firefox. */
                                -moz-osx-font-smoothing: grayscale;
                                /* Support for IE. */
                                font-feature-settings: 'liga';
                                padding: 0;
                                font-size: 15px;
                                width: 16px;
                                height: 16px;
                                line-height: 10px;
                                margin-top: -10px;

                            }

                        }

                        .k-icon {
                            &.k-i-sort-asc-sm {
                                position: relative;
                                display: inline-block;
                                color: var(--color-ey-confident-grey) !important;

                                &::before {
                                    font-family: var(--font-family-materialIcons);
                                    content: 'arrow_drop_up';
                                    -webkit-font-smoothing: antialiased;
                                    text-rendering: optimizeLegibility;
                                    /* Support for Firefox. */
                                    -moz-osx-font-smoothing: grayscale;
                                    /* Support for IE. */
                                    font-feature-settings: 'liga';
                                }
                            }

                            &.k-i-sort-desc-sm {
                                position: relative;
                                display: inline-block;
                                color: var(--color-ey-interaction-overlays) !important;

                                &::before {
                                    font-family: var(--font-family-materialIcons);
                                    content: 'arrow_drop_down';
                                    -webkit-font-smoothing: antialiased;
                                    text-rendering: optimizeLegibility;
                                    /* Support for Firefox. */
                                    -moz-osx-font-smoothing: grayscale;
                                    /* Support for IE. */
                                    font-feature-settings: 'liga';
                                }
                            }
                        }
                    }

                    &.k-group-cell {
                        border: none;
                        background-color: var(--color-white);
                    }

                    .k-column-resizer {
                        top: 0;
                        bottom: 0;
                        height: auto;
                        cursor: w-resize;
                    }

                    &.checkbox-header {
                        line-height: 0;
                        padding: 0.625rem 0.75rem;
                        text-overflow: initial;

                        .k-checkbox-label {
                            line-height: 0;
                            padding: 0;
                        }
                    }

                    .checkbox {
                        .checkbox__checkmark {
                            width: 1.25rem;
                            height: 1.25rem;
                        }
                    }

                    .checkbox:focus-within .checkbox__input:not(:checked):not(:disabled)~.checkbox__checkmark {
                        border: 0.0625rem solid var(--color-ey-link) !important;
                    }
                }
            }

            tbody {

                &>.k-grid-norecords:first-child,
                &>.k-grid-norecords:last-child {
                    td {
                        border: none;
                    }

                }

                &>tr {
                    height: 2.8125rem;

                    &.k-selected {
                        & td {
                            background-color: inherit !important;
                        }
                    }
                    &.k-state-selected {
                        color: var(--color-ey-dark-gray) !important;
                        background-color: var(--color-white);
                        border: none;

                        td {
                            background-color: transparent;
                        }

                        &:nth-child(2n) td:not(.k-group-cell) {
                            background-color: var(--color-white);
                        }
                    }

                    &.k-alt td:not(.k-group-cell) {
                        background-color: var(--color-white);
                    }

                    &:not(.k-detail-row):hover td:not(.k-group-cell) {
                        background-color: var(--color-ey-grey-5);
                        border-top: solid 0.06rem var(--color-border) !important;
                        border-bottom: solid 0.06rem var(--color-border) !important;
                    }

                    &.k-alt:not(.k-detail-row):hover td:not(.k-group-cell) {
                        border-top: solid 0.06rem var(--color-border) !important;
                        border-bottom: solid 0.06rem var(--color-border) !important;
                        background-color: var(--color-white);
                    }

                    &.k-detail-row,
                    &.k-detail-row.k-alt {
                        td {
                            background-color: var(--color-white);
                            border-top: 0.06rem solid var(--color-ey-gray-tint-3);
                            border-bottom: 0.06rem solid var(--color-white);
                        }

                        &:hover {
                            background-color: var(--color-white);
                        }

                        .k-detail-cell {
                            kendo-grid {
                                kendo-grid-group-panel {
                                    display: none;
                                }
                            }
                        }
                    }

                    td {
                        span:not(.k-icon) {
                            font-family: var(--font-family-light) !important;
                        }

                        .k-i-caret-alt-right {
                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: "add";
                            }
                        }

                        .k-i-caret-alt-down {
                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: "remove";
                            }
                        }

                        color: var(--color-ey-confident-grey) !important;
                        background-color: var(--color-ey-grey-5);
                        font-family: var(--font-family-light) !important;
                        font-size: var(--font-size-3);
                        padding: 0 0.75rem;
                        vertical-align: middle;
                        border-left: none;
                        border-right: none;
                        white-space: nowrap;
                        overflow-x: hidden;
                        text-overflow: initial;

                        &:first-of-type {
                            border-left: solid 0.0625rem var(--color-border-2);
                        }

                        &:not(.utility-cell) {
                            border-top: solid 0.06rem var(--color-border-2);
                            border-bottom: solid 0.06rem var(--color-white);
                            border-right: none;
                            text-overflow: ellipsis;
                        }


                        &.utility-cell {
                            line-height: 0;
                            padding: 0 0.75rem;

                            .k-checkbox-label {
                                line-height: 0;
                                padding: 0;
                            }

                            background-color: var(--color-ey-grey-5);
                            border-top: solid 0.06rem var(--color-border-2);
                            border-bottom: solid 0.06rem var(--color-white);
                            border-right: solid 0.0625rem var(--color-border-2);

                            .checkbox {
                                .checkbox__checkmark {
                                    width: 1.25rem;
                                    height: 1.25rem;
                                }
                            }

                            .checkbox:focus-within .checkbox__input:not(:checked):not(:disabled)~.checkbox__checkmark {
                                border: 0.0625rem solid var(--color-ey-link) !important;
                            }

                            span {
                                width: 1.5rem;
                                height: 1.5rem;
                                display: inline-block;
                            }

                            position: relative;

                            &.checkbox-cell,
                            &.k-command-cell {
                                position: relative;
                                -webkit-box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2) !important;
                                -moz-box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2) !important;
                                box-shadow: 0.3125rem 0 0.2rem -0.1125rem var(--color-border-2) !important;
                            }
                        }

                        .details-link {
                            color: var(--color-ey-link);
                        }

                        &.k-group-cell {
                            background-color: var(--color-white);
                            border: none;
                        }

                        &.center {
                            text-align: center;
                        }

                        .grid-command {
                            font-size: var(--font-size-5);
                            line-height: 0.75rem;

                            &.active-action {
                                color: var(--color-ey-yellow);
                            }
                        }

                        &.k-hierarchy-cell {
                            line-height: 0;
                            padding: 0.75rem;
                            vertical-align: top;
                            text-overflow: clip;

                            .k-icon {
                                padding: 0;
                                width: 1rem;
                                height: 1rem;

                                &.k-plus {
                                    &::before {
                                        content: '\f055';
                                        font-family: var(--font-family-fontAwesome);
                                        color: var(--color-ey-link);
                                        vertical-align: top;
                                    }
                                }

                                &.k-minus {
                                    &::before {
                                        content: '\f056';
                                        font-family: var(--font-family-fontAwesome);
                                        color: var(--color-ey-link);
                                        vertical-align: top;
                                    }
                                }
                            }
                        }

                        .k-reset {
                            font-feature-settings: 'liga';

                            .k-icon {
                                &.k-i-collapse {
                                    position: relative;
                                    display: inline-block;
                                    color: var(--color-ey-confident-grey) !important;

                                    &::before {
                                        font-family: var(--font-family-materialIcons);
                                        content: 'keyboard_arrow_down';
                                        -webkit-font-smoothing: antialiased;
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                    }
                                }

                                &.k-i-expand {
                                    position: relative;
                                    display: inline-block;
                                    color: var(--color-ey-confident-grey) !important;

                                    &::before {
                                        font-family: var(--font-family-materialIcons);
                                        content: 'keyboard_arrow_right';
                                        -webkit-font-smoothing: antialiased;
                                        text-rendering: optimizeLegibility;
                                        /* Support for Firefox. */
                                        -moz-osx-font-smoothing: grayscale;
                                        /* Support for IE. */
                                        font-feature-settings: 'liga';
                                    }
                                }
                            }
                        }

                        tr {
                            td {
                                background-color: var(--color-ey-grey-5) !important;
                            }
                        }

                        tr.k-alt {
                            td {
                                background-color: var(--color-white) !important;
                            }
                        }
                    }
                }
            }

            .k-hierarchy-col {
                width: 2.5rem;
            }

            &.wrap-text {
                tbody {
                    &>tr {
                        td {
                            white-space: normal;
                            text-overflow: clip;
                            word-break: break-all !important;
                            word-wrap: break-word !important;
                        }
                    }
                }
            }

            @include pager-styles;
        }
    }

    value-tag-list {
        .value-tag-list-outside {
            margin: 0.625rem;
            height: 100%;

            .filter {
                margin-bottom: 0.625rem !important;
            }

            .value-tag-list-filters-title {
                font-family: var(--font-family-bold);
                margin-right: 0.9375rem;
            }
        }
    }

    .k-drag-clue {
        background-color: var(--color-ey-gray);
        border-color: var(--color-ey-dark-gray);
        padding: 0 0.75em !important;
    }
}

.outside-column-chooser {
    .k-grid-column-menu {
        &.k-active .k-icon.k-i-more-vertical::after {
            content: '';
            position: absolute;
            width: 0.5625rem;
            height: 0.5625rem;
            background-color: var(--color-blue-06);
            border-radius: 50%;
            border: 1.5px solid var(--color-white);
            top: -2px;
            right: -4px;
            z-index: 1;
        }

        &:hover {
            color: var(--color-ey-confident-grey) !important;
        }
    }

    .k-icon.k-i-more-vertical {
        &::before {
            content: 'filter_list' !important;
            font-family: var(--font-family-materialIcons);
        }
    }
}

.modal-content {
    .k-grid-pager {
        .grid-pagination {
            justify-content: space-between;
            flex-wrap: wrap;
            grid-template-columns: repeat(6, 1fr) !important;
            grid-template-rows: repeat(2, 1fr) !important;
            grid-column-gap: 0px !important;
            grid-row-gap: 0px !important;

            @media (max-width: 1400px) {
                display: grid;

                kendo-pager-page-sizes,
                kendo-treelist-pager-page-sizes {
                    position: static !important;
                    grid-area: 1 / 1 / 2 / 3 !important;
                }

                span[data-showingcount] {
                    order: 1 !important;
                    grid-area: 1 / 3 / 2 / 7 !important;
                    text-align: right;
                }

                kendo-pager-prev-buttons,
                kendo-treelist-pager-prev-buttons {
                    grid-area: 2 / 1 / 3 / 2 !important;
                }

                kendo-pager-numeric-buttons,
                kendo-treelist-pager-numeric-buttons {
                    grid-area: 2 / 2 / 3 / 6 !important;
                    justify-content: center;
                    position: static !important;
                }

                kendo-pager-next-buttons,
                kendo-treelist-pager-next-buttons {
                    grid-area: 2 / 6 / 3 / 7 !important;
                }
            }

            span[data-showingcount] {
                position: static !important;
                top: 0 !important;
                right: 0 !important;
            }

            .k-pager-numbers {
                display: inline-block;
            }
        }
    }

    kendo-grid-list,
    kendo-treelist-list {
        .k-grid-content {
            min-height: auto !important;
        }
    }
}

.k-header {
    &.k-drag-clue {
        color: var(--color-ey-gray-alt);
        background: var(--color-white);
        border: 0.125rem solid var(--color-ey-link);
    }
}

.k-ie .k-grid-header .k-grid-filter,
.k-edge .k-grid-header .k-grid-filter,
.k-ie .k-grid-header .k-header-column-menu,
.k-edge .k-grid-header .k-header-column-menu {
    position: static;
    float: right;
    margin: 0 0 0 -0.875rem !important;
}

@include grid-menu-styles;

.grid-wrapper kendo-grid.k-grid tbody,
.grid-wrapper kendo-treelist.k-grid tbody {
    tr {
        td.utility-cell {
            span {
                cursor: pointer;
            }
        }
    }

    &>tr:not(.k-detail-row) {
        & td:not(.k-group-cell) {
            font-weight: 300;
        }

        &:hover,
        &:focus,
        &:active {
            & td:not(.k-group-cell) {
                font-family: var(--font-family-bold) !important;
                font-weight: bold;
                border-top: 0 !important;
                border-bottom: 0 !important;
                outline: 0;

                &>*:not(.material-icons):not(.k-icon) {
                    font-weight: inherit !important;
                    font-family: inherit !important;
                }
            }
        }
    }
}

.grid-wrapper kendo-grid.k-grid .k-grid-header .k-header,
.grid-wrapper kendo-treelist.k-grid .k-grid-header .k-header,
.grid-wrapper .treelist-custom-paginator.k-grid .k-grid-header .k-header {
    &:hover {
        background-color: var(--color-ey-grey-12) !important;
        border-right: solid 0.0625rem var(--color-ey-grey-1) !important;
    }
}