.color01 {
  background-color: var(--color-ey-interaction-overlays);
}
.color02 {
  background-color: var(--color-ey-confident-grey);
}
.color03 {
  background-color: var(--color-ey-grey-1);
}
.color04 {
  background-color: var(--color-ey-grey-2);
}
.color05 {
  background-color: var(--color-ey-grey-3);
}
.color06 {
  background-color: var(--color-ey-grey-4);
}
.color07 {
  background-color: var(--color-white);
  border: 1px solid var(--color-ey-grey-2);
}
.color08 {
  background-color: var(--color-ey-yellow);
}
// Maroons
.color09 {
  background-color: var(--color-maroon-01);
}
.color10 {
  background-color: var(--color-maroon-02);
}
.color11 {
  background-color: var(--color-maroon-03);
}
.color12 {
  background-color: var(--color-maroon-04);
}
.color13 {
  background-color: var(--color-maroon-05);
}
.color14 {
  background-color: var(--color-maroon-06);
}
.color15 {
  background-color: var(--color-maroon-07);
}
// Reds
.color16 {
  background-color: var(--color-red-01);
}
.color17 {
  background-color: var(--color-red-02);
}
.color18 {
  background-color: var(--color-red-03);
}
.color19 {
  background-color: var(--color-red-04);
}
.color20 {
  background-color: var(--color-red-05);
}
.color21 {
  background-color: var(--color-red-06);
}
.color22 {
  background-color: var(--color-red-07);
}
// Oranges
.color23 {
  background-color: var(--color-orange-01);
}
.color24 {
  background-color: var(--color-orange-02);
}
.color25 {
  background-color: var(--color-orange-03);
}
.color26 {
  background-color: var(--color-orange-04);
}
.color27 {
  background-color: var(--color-orange-05);
}
.color28 {
  background-color: var(--color-orange-06);
}
.color29 {
  background-color: var(--color-orange-07);
}
// Greens
.color30 {
  background-color: var(--color-green-01);
}
.color31 {
  background-color: var(--color-green-02);
}
.color32 {
  background-color: var(--color-green-03);
}
.color33 {
  background-color: var(--color-green-04);
}
.color34 {
  background-color: var(--color-green-05);
}
.color35 {
  background-color: var(--color-green-06);
}
.color36 {
  background-color: var(--color-green-07);
}
// Teal
.color37 {
  background-color: var(--color-teal-01);
}
.color38 {
  background-color: var(--color-teal-02);
}
.color39 {
  background-color: var(--color-teal-03);
}
.color40 {
  background-color: var(--color-teal-04);
}
.color41 {
  background-color: var(--color-teal-05);
}
.color42 {
  background-color: var(--color-teal-06);
}
.color43 {
  background-color: var(--color-teal-07);
}
// Blue
.color44 {
  background-color: var(--color-blue-01);
}
.color45 {
  background-color: var(--color-blue-02);
}
.color46 {
  background-color: var(--color-blue-03);
}
.color47 {
  background-color: var(--color-blue-04);
}
.color48 {
  background-color: var(--color-blue-05);
}
.color49 {
  background-color: var(--color-blue-06);
}
.color50 {
  background-color: var(--color-blue-07);
}
// Purple
.color51 {
  background-color: var(--color-purple-01);
}
.color52 {
  background-color: var(--color-purple-02);
}
.color53 {
  background-color: var(--color-purple-03);
}
.color54 {
  background-color: var(--color-purple-04);
}
.color55 {
  background-color: var(--color-purple-05);
}
.color56 {
  background-color: var(--color-purple-06);
}
.color57 {
  background-color: var(--color-purple-07);
}
// States
.color58 {
  background-color: var(--color-ey-confident-grey);
}
.color59 {
  background-color: var(--color-prompt-label-text);
}
.color60 {
  background-color: var(--color-inactive-icon-text);
}
.color61 {
  background-color: var(--color-error);
}
.color62 {
  background-color: var(--color-warning);
}
.color63 {
  background-color: var(--color-success);
}
.color64 {
  background-color: var(--color-ey-link);
}
.color65 {
  background-color: var(--color-ey-grey-2a);
}
.color66 {
  background-color: var(--color-ey-grey-5);
}
