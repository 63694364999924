/* RADIO BUTTON */

/* The container */

.rbcontainer {
  $self: &;
  text-align: left;
  $max-width: 48rem;
  &__wrapper {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    p {
      padding-right: 1rem !important;
    }
    @media (max-width: $max-width) {
      display: flex;
      align-items: center;
      margin: 0 0 1.5625rem 0;

      p {
        margin: 0 1.25rem 0 0;
        padding: 0 !important;
        width: 100%;
        max-width: 6.25rem;
      }
    }
  }
  &__label {
    @include fonts-col__instructional-text;
    position: relative;
    padding-left: 1.875rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 1.25rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: top;
    text-align: left;
    display: inline-block;
    line-height: 1.3125rem;
    & strong {
      @include fonts-col__table-data-headers;
    }
    &.focus {
      outline: 0.0625rem solid var(--color-border);
      outline-offset: 0.375rem;
    }
  }
  &__label.error {
    /* Create a custom radio button with error*/
    color: var(--color-ey-red);
  }

  &__checkmark {
    /* Create a custom radio button */
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background-color: var(--color-white);
    border-radius: 50%;
    border: 0.0625rem solid var(--color-ey-confident-grey);
    vertical-align: 50%;
    @media (max-width: $max-width) {
      top: calc(50% - 0.625rem);
    }
  }
  /* Style the indicator (dot/circle) */
  &__checkmark:after {
    top: 0.125rem;
    left: 0.125rem;
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: var(--color-ey-confident-grey);
  }
  /* When the radio button is checked, add a blue background */
  input:checked ~ &__checkmark {
    background-color: var(--color-white);
    border: 0.0625rem solid var(--color-ey-confident-grey);
  }

  /* When the radio button is disabled, set the border */
  input:disabled ~ &__checkmark {
    background-color: var(--color-white);
    border: 0.0625rem solid var(--color-ey-grey-2);
    cursor: not-allowed;
  }

  input:disabled ~ &__checkmark:after {
    background-color: var(--color-ey-grey-2);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ &__checkmark:after {
    display: block;
  }

  /* Hide the browser's default radio button */
  &__label {
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
  }

  /* On mouse-over, add a white background color */
  &__label:hover {
    input ~ #{ $self }__checkmark {
      background-color: var(--color-white);
    }
  }

  &__label-disabled {
    color: var(--color-ey-grey-2);
  }

  &__label-disabled:hover {
    cursor: not-allowed;
  }

  &__label.disabled {
    cursor: not-allowed;
  }

  /* hide the disabled indicator */
  &__label.rbcontainer__label.disabled {
    #{ $self }__checkmark:after {
      display: none;
    }
  }

  /* Style the error indicator*/
  &__error-container {
    padding: 0.125rem;
    margin: 0;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__container-icon {
    font-size: var(--font-size-8);
    line-height: 1.5rem;
    color: var(--color-ey-red);
    text-align: left;
    padding-right: 0.5rem;
    display: inline-block;
    vertical-align: top;
    margin-left: 1.75rem;
  }

  &__error-container-message {
    color: var(--color-ey-grey-1);
    line-height: 0.9375rem;
    vertical-align: top;
    margin-top: -0.125rem;
    display: inline-block;
   font-size: var(--font-size-3);
  }

  &__header {
    display: block;
    color: var(--color-ey-gray-alt);
    line-height: 1.3125rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &--vertical {
    display: block;
    margin-bottom: 0.25rem;
  }
}
