/* ON / OFF */

.sets {
  margin-bottom: 5rem;
  margin-top: 5rem;
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 2.188rem;
  height: 0.875rem;
}

/* The slider */

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.0625rem solid transparent; //required to avoid misaligment
  background-color: var(--color-ey-grey-2);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  &:before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: -0.125rem;
    bottom: -0.25rem;
    background-color: var(--color-ey-grey-1);
    box-shadow: 0.0625rem 0 0.125rem 0 rgba(var(--color-black-rgb), 0.25);
    -webkit-transition: 0.3s;
    transition: 0.3s;

    :focus:not(:disabled) + &,
    :hover:not(:disabled) + &,
    :active:not(:disabled) + & {
      border: 0.0625rem solid var(--form-color-default);
    }
  }

  /* Rounded sliders */
  .switch--round & {
    border-radius: 2.125rem;
    &:before {
      border-radius: 50%;
    }
  }
}

.switch__input {
  opacity: 0; //Hide default HTML checkbox
  width: 0;
  height: 0;

  /*-- toggle is ON --*/
  &:checked {
    & + .switch__slider {
      background: var(--color-green-03);
      &:before {
        background: var(--color-green-05);
        box-shadow: -0.0625rem 0 0.0625rem 0 rgba(var(--color-black-rgb), 0.25);
        -webkit-transform: translateX(1rem);
        -ms-transform: translateX(1rem);
        transform: translateX(1rem);
      }
    }
  }
  /*--END OF: toggle is is on --*/

  /*-- toggle is DISABLED --*/
  &:disabled {
    & + .switch__slider {
      background-color: transparent;
      border: 0.0625rem solid var(--color-ey-grey-2);
      cursor: not-allowed;
      &:before {
        background-color: var(--color-ey-grey-2);
      }
    }
  }
  /*--END OF: toggle is DISABLED --*/
}

.switch-label {
  color: var(--form-color-default);
  font-family: var(--font-family-light) !important;
 font-size: var(--font-size-3);
  position: relative;
  display: inline;
  top: -0.425rem;
  left: 1.85rem;

  :checked ~ & {
    font-family: var(--font-family-bold) !important;
  }

  &:before {
    position: absolute;
    content: "Off";
    left: -3.825rem;
    bottom: -0.1rem;
    font-family: var(--font-family-bold) !important;

    :checked ~ & {
      font-family: var(--font-family-light) !important;
    }
  }
}
