/* TOOL TIP DEFINITION */

.tool-tip-definition {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  margin: 0.25rem;
  &:after {
    filter: drop-shadow(0rem 0.3125rem 0.1875rem var(--color-shadow-2));
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: block;
    width: 0rem;
    height: 0rem;
    border-style: solid;
    content: "";
    position: absolute;
    opacity: 0;
    visibility: hidden;

    border-color: transparent transparent var(--color-ey-confident-grey) transparent;
    bottom: -0.5rem;
    left: 50%;
    transform: translate(-50%, 0rem);

    border-width: 0rem 0.5rem 0.5rem 0.5rem;
    z-index: 999;
  }
  &:before {
    filter: drop-shadow(0rem 0rem 0.5rem var(--color-shadow-2));
    display: block;
    @include fonts-col__body-copy;
   font-size: var(--font-size-3) !important; //override
    content: attr(tool-tip-content);
    position: absolute;
    background: var(--color-ey-confident-grey);
    color: var(--color-white);
    padding: 0.5rem;
    line-height: 1.188rem;
    font-style: normal;
    min-width: 10.81rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    bottom: 0rem;
    left: 50%;
    transform: translate(
      -50%,
      calc(100% + 0.5rem)
    ); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    box-sizing: border-box;
  }
  &.tool-tip-definition--light:before {
    background: var(--color-white);
    color: var(--color-ey-confident-grey);
  }
  &:hover:before,
  &:hover:after,
  &.tool-tip-definition--force-show:before,
  &.tool-tip-definition--force-show:after {
    display: block;
    visibility: visible;
    opacity: 1;
  }
  &.tool-tip-definition--light {
    &:after {
      border-color: transparent transparent var(--color-white) transparent;
    }
    &.tool-tip-position--top:after {
      border-color: var(--color-white) transparent transparent transparent;
    }
    &.tool-tip-position--left:after {
      border-color: transparent var(--color-white) transparent var(--color-white);
    }
    .material-icons.tool-tip-definition__icon {
      color: var(--color-ey-interaction-overlays); //no token, from design asset
      font-size: var(--font-size-8);
    }
  }
  &__icon.material-icons {
    color: var(--color-ey-interaction-overlays);
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    font-size: var(--font-size-8);
  }
}

/* TOOL TIP LABEL */

.tool-tip-label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  margin: 4rem;
  &:after {
    filter: drop-shadow(0rem 0.3125rem 0.1875rem var(--color-shadow-2));
    width: 0rem;
    height: 0rem;
    border-style: solid;
    content: "";
    position: absolute;
    opacity: 0;
    visibility: hidden;

    border-width: 0rem 0.5rem 0.5rem 0.5rem;
    border-color: transparent transparent var(--color-ey-confident-grey) transparent;
    bottom: -0.5rem;
    left: 50%;
    transform: translate(-50%, 0rem);
  }
  &:before {
    filter: drop-shadow(0rem 0rem 0.5rem var(--color-shadow-2));

    @include fonts-col__body-copy;
    content: attr(tool-tip-content);
    position: absolute;
    background: var(--color-ey-confident-grey);
    color: var(--color-white);
    padding: 0.5rem;
    line-height: 1.188rem;
    font-style: normal;
    min-width: 2.5rem;
    max-width: 5rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;

    bottom: 0rem;
    left: 50%;
    //transform: translate(-50%, calc(100% + 0.5rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translate(-50%);
    top: calc(100% + 0.5rem);
    height: 150%;
    height: -webkit-fit-content;
    height: -moz-max-content;
    height: fit-content;
    box-sizing: border-box;
  }

  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }
}

/* TOOL TIP POSITION */

.tool-tip-position {
  display: inline-block;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-style: normal;
  margin: 4rem;
  &:after {
    filter: drop-shadow(0rem 0.3125rem 0.1875rem var(--color-shadow-2));
    width: 0rem;
    height: 0rem;
    border-style: solid;
    content: "";
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  &:before {
    filter: drop-shadow(0rem 0rem 0.5rem var(--color-shadow-2));
    @include fonts-col__body-copy;
   font-size: var(--font-size-3) !important; //override
    content: attr(tool-tip-content);
    position: absolute;
    background: var(--color-ey-confident-grey);
    color: var(--color-white);
    padding: 0.5rem;
    line-height: 1.188rem;
    font-style: normal;
    min-width: 10.81rem;
    max-width: 15rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
  }
  &:hover:before,
  &:hover:after {
    visibility: visible;
    opacity: 1;
  }

  /*top*/

  &--top:before {
    display: inline-block;
    left: 50%;
    bottom: auto;
    //transform: translate(-50%, calc(-100% - 0.4375rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translate(-50%, -100%);
    top: -1.25rem;
    box-sizing: border-box;
  }

  &--top:after {
    display: block;
    border-width: 1.125rem 0.8125rem 0 0.8125rem;
    border-color: var(--color-ey-confident-grey) transparent transparent transparent;
    top: -0.8125rem;
    left: 50%;
    transform: translate(-50%, -0.4375rem);
  }

  /*bottom*/

  &--bottom:before {
    bottom: 0rem;
    left: 50%;
    //transform: translate(-50%, calc(100% + 0.5rem)); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translateX(-50%);
    top: calc(100% + 1.25rem);
    height: 200%;
    height: -webkit-fit-content;
    height: -moz-max-content;
    height: fit-content;
    box-sizing: border-box;
  }

  &--bottom:after {
    border-width: 0rem 0.8125rem 1.125rem 0.8125rem;
    border-color: transparent transparent var(--color-ey-confident-grey) transparent;
    bottom: -1.25rem;
    left: 50%;
    transform: translate(-50%, 0rem);
  }

  /*left*/

  &--left:before {
    left: 0rem;
    top: 50%;
    height: 200%;
    height: -webkit-fit-content;
    height: -moz-max-content;
    height: fit-content;
    //transform: translate(calc(-100% - 0.5rem), -50%); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translate(-100%, -50%);
    left: -1.25rem;
    box-sizing: border-box;
  }

  &--left:after {
    border-width: 0.8125rem 0rem 0.8125rem 1.125rem;
    border-color: transparent transparent transparent var(--color-ey-confident-grey);
    left: -1.25rem;
    top: 50%;
    transform: translate(0rem, -50%);
  }

  /*right*/

  &--right:before {
    right: 0;
    top: 50%;
    //transform: translate(calc(100% + 0.52rem), -50%); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    transform: translateY(
      -50%
    ); //IE10, IE11, and Edge < 14 don't support using calc() inside a transform
    left: calc(100% + 1.25rem);
    box-sizing: border-box;
  }

  &--right:after {
    border-width: 0.8125rem 1.125rem 0.8125rem 0rem;
    border-color: transparent var(--color-ey-confident-grey) transparent transparent;
    right: -1.25rem;
    top: 50%;
    transform: translate(0rem, -50%);
  }

  //override for ie/edge. ie-edge class can be on any parent element at any level
  .ie-edge & {
    &:before {
      display: inline-table;
    }
  }
}

/* Tooltip text */
.tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: auto;
  .tooltiptext {
    position: absolute;
    filter: drop-shadow(0rem 0rem 0.5rem var(--color-shadow-2));
    visibility: hidden;
    width: fit-content;
    min-width: 17.8125rem;
    white-space: nowrap;
    background-color: var(--color-ey-confident-grey);
    color: var(--color-white);
    text-align: center;
    padding: 0.625rem 0.9375rem;
    min-height: 4.375rem;
    z-index: 1;
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    ul {
      list-style: none;
      padding-inline-start: 0;
      padding: 0; //override for edge
      margin-bottom: 0;
      li {
        text-align: left;

        color: var(--color-white);
       font-size: var(--font-size-3);
        font-family: var(--font-family-light) !important;
        font-weight: 300;
        line-height: 1.125rem;
        margin: 0.3125rem 0;
        span {
          font-family: var(--font-family-bold) !important;
        }
      }
    }

    .material-icons {
      font-size: var(--font-size-6);
      vertical-align: bottom;
      margin-right: 0.3125rem;
    }
  }
  &.tooltip--light .tooltiptext {
    background-color: var(--color-white);
    color: var(--color-ey-confident-grey);
    ul li {
      color: var(--color-ey-confident-grey);
    }
    .material-icons {
      color: var(--color-ey-confident-grey);
      &.success {
        color: var(--color-success);
      }
      &.error {
        color: var(--color-red-06);
      }
    }
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: calc(100% - 0.0063rem); //less just a bit to remove hairline
    left: 50%;
    margin-left: 0rem;
    border-width: 0.8125rem 1.125rem 0.8125rem 0rem;
    border-style: solid;
    border-color: var(--color-ey-confident-grey) transparent transparent transparent;
  }
  &.tooltip--light {
    i {
      color: var(--color-ey-interaction-overlays); //no token, from design asset
    }
  }
  &.tooltip--light .tooltiptext::after {
    border-color: var(--color-white) transparent transparent transparent;
  }
}

/* Tooltip arrow */

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover,
.tooltip.tooltip--force-show {
  .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

/* Tooltip on the top */
.tooltip.tooltip--top {
  .tooltiptext {
    transform: translate(-50%, -100%);
    top: -0.9375rem;
  }
  .tooltiptext::after {
    left: calc(50% - 1.338rem);
    border-color: transparent var(--color-ey-confident-grey) transparent transparent;
    transform: rotate(270deg) translatex(0.25rem);
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent var(--color-white) transparent transparent;
  }
}

/* Tooltip on the Right */
.tooltip.tooltip--right {
  .tooltiptext {
    top: 0;
    transform: translateY(-1.4875rem);
    left: calc(100% + 1.00875rem);
  }
  .tooltiptext::after {
    top: 1.375rem;
    left: -1.125rem;
    border-color: transparent var(--color-ey-confident-grey) transparent transparent;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent var(--color-white) transparent transparent;
  }
}

/* Tooltip on the Bottom */
.tooltip.tooltip--bottom {
  .tooltiptext {
    top: 2.25rem;
    left: 0;
    transform: translate(-49%);
  }
  .tooltiptext::after {
    transform: rotate(90deg);
    left: 50%;
    top: -1.2rem;
    border-color: transparent var(--color-ey-confident-grey) transparent transparent;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent var(--color-white) transparent transparent;
  }
}

/* Tooltip on the Left */
.tooltip.tooltip--left {
  .tooltiptext {
    transform: translateX(-100%) translateY(-1.4875rem);
    left: -1.01875rem;
  }
  .tooltiptext::after {
    top: 1.375rem;
    left: calc(100% - 0.025rem);
    transform: rotate(180deg);
    border-color: transparent var(--color-ey-confident-grey) transparent transparent;
  }
  &.tooltip--light .tooltiptext::after {
    border-color: transparent var(--color-white) transparent transparent;
  }
}
