// Dropdown Colors:
//  - All parameters have defaults
//  - Parameters:
//    - $color {string} : Text color
//    - $color-bg {string} : Background color (Default/Primary)
//    - $color-bg-alt {string} : Background color (Alternative)
//    - var(--color-error) {string} : Error label/msg/icon color
@mixin dropdown-color(
  $color: var(--color-ey-confident-grey),
  $color-bg: var(--color-white),
  $color-bg-alt: var(--color-ey-grey-2a),
  $color-error: var(--color-red-06)
) {
  $parentClass: &;

  .dropdown-menu {
    .dropdown-item,
    .dropdown-header {
      color: $color;
      background: $color-bg;
      &.checkbox {
        background: $color-bg;
        @include dropdown-state($color, $color-bg-alt);
      }
      &.checkbox.checked {
        @include dropdown-state($color, $color-bg-alt, false);
      }
    }
    & .btn.dropdown__group--btn {
      background: $color-bg;
      @include dropdown-state($color, $color-bg-alt);
    }
  }
}

@mixin dropdown-state($color, $bk-color, $state: true) {
  @if $state {
    &:hover,
    &.hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      color: $color;
      background: $bk-color;
      outline: none;
    }
  } @else {
    background: $bk-color;
    outline: none;
  }
}

//////////////////////////////////////////////////////////////////////////

/*------- DROPDOWN MAIN STYLE ---css start here----------*/

$dropdown-width: 18.75rem;
$dropdown-height: 2.813rem;
@mixin dropdown-outline {
  outline: none;
  border: 0.0625rem solid var(--color-ey-grey-2a);
  border-radius: 0;
  &[x-placement="bottom-start"] {
    border-top-width: 0;
  }
}

/*-- DS Global Style for .dropdown-toggle--*/

.dropdown__expand_less,
.dropdown__expand_more {
  color: var(--color-ey-confident-grey);
  position: absolute;
  top: 0.625rem;
  right: 0.375rem;
  cursor: pointer;
  display: none;
  font-family: var(--font-family-materialIcons);
}

.dropdown {
  @include dropdown-color;

  // Dark theme ------
  .dark & {
    @include dropdown-color(
      $color: var(--color-ey-confident-grey),
      $color-bg: var(--color-white),
      $color-bg-alt: var(--color-ey-grey-2a),
      $color-error: var(--color-red-06)
    );
  }

  .dropdown-toggle {
    position: relative;
    min-height: $dropdown-height;
    width: $dropdown-width;
    // overflow: hidden;

    //for d-flex
    display: -webkit-box;
    display: flex;
    //for flex-column
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    //for mb-auto
    margin-bottom: auto;

    &[aria-expanded="false"] {
      &:not([aria-disabled="true"]) .dropdown__expand_more {
        display: block;
      }
    }

    &[aria-expanded="true"] {
      &:not([aria-disabled="true"]) .dropdown__expand_less {
        display: block;
      }
    }

    &::after {
      display: none;
    }

    &:hover {
      text-decoration: none;
    }
  }

  /*-- DS Global Style for .dropdown-menu--*/
  .dropdown-menu {
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
    @include dropdown-outline;
    .dropdown-item {
      @include fonts-col__instructional-text;
      color: var(--color-ey-confident-grey);
      line-height: 2.5rem !important;
      height: $dropdown-height;
      width: calc(#{$dropdown-width} - 0.12rem);
      overflow: hidden;
      background: var(--color-white);
      display: inherit;
      &.checkbox {
        line-height: 2.5rem !important;
      }
      &.hide {
        display: none;
      }
    }
  }

  /*override style of text input component under dropdown block*/

  .textinput-group__textinput {
    // font style set on _inputs.scss
    min-height: 100%;
    max-height: 100%;
  }

  .textinput-group__errmsg {
    @include fonts-col__field-labels-on-focus;
    position: inherit;
    min-height: 1.25rem;
    line-height: 1.25rem;
  }
}

/*-------END OF: DROPDOWN MAIN STYLE -----------*/
/*----------------------------------------------*/

/* ++++++++++ Multi-Select STYLES ++++++++++ */
// Note: this component is also using dropdown style, checkbox style and it corresponding script

.dropdown.dropdown--multi-select {
  .dropdown-toggle {
    &.dropdown-toggle--counter {
      .textinput-group__textinput {
        padding-left: 3.15rem;
        padding-right: 2.1875rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .dropdown-menu {
    background-color: transparent;
  }
  .dropdown__items {
    max-height: 15rem;
    overflow: overlay;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      background: var(--color-white);
      border-radius: 0;
      box-shadow: inset 0 0.0625rem 0.1875rem 0 rgba(var(--color-ey-grey-3-rgb), 0.5); //no token
      width: 0.625rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-ey-gray-border-3);
      border-radius: 0.375rem;
      border: 0.125rem solid var(--color-white);
    }
  }

  .dropdown-item {
    height: 2.5rem;
  }
}

.pill.pill--dropdown {
  position: absolute;
  left: 0;
  top: 0.2rem;
  background-color: var(--color-ey-confident-grey);
  padding-left: 0.375rem;
  padding-right: 0.25rem;
  min-width: 0;
  display: none;
  margin: 0;

  .dropdown-toggle.dropdown-toggle--counter .asterisk & {
    top: 1.3rem;
  }

  .dropdown-toggle.dropdown-toggle--counter & {
    display: block;
    top: 1.3rem;
    left: 0.6rem;
  }

  .Pill__label {
    width: 0.625rem;
    height: 0.75rem;
    color: var(--color-white);
    font-size: var(--font-size-2);
    font-family: var(--font-family-bold) !important;
    font-weight: bold;
    letter-spacing: 0;
    padding-right: 0.0625rem;
  }

  .pill__close {
    padding-left: 0.0625rem;
    .material-icons {
     font-size: var(--font-size-3);
      vertical-align: text-bottom;
      cursor: pointer;
    }
  }

  button.pill__close {
    // Override for <button>
    background-color: transparent;
    color: var(--color-white);
    border: none;
    padding: 0;
  }
}

/* Overriding Checkbox style for dropdown component */
.checkbox.dropdown-item {
  margin-bottom: 0;
  .checkbox__checkmark {
    top: 0.6875rem;
    left: 1.188rem;
  }
  .checkbox__label {
    left: 2.063rem;
    top: -0.1875rem;
  }
}
/* Overriding btn style for dropdown component */
.btn {
  .dropdown-menu & {
    min-width: 9.125rem;
    margin: 0;
  }
}
.dropdown__Buttons {
  background-color: var(--color-white);
  padding: 0.46875rem;
  margin: 0;
  @include dropdown-outline;
  border-width: 0;
  border-top-width: 0.0625rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;

  .btn {
    min-width: 0;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    margin: 0.46875rem;
  }
}

/* Overriding style for hierarchical dropdown component */
.dropdown__group {
  //d-flex flex-row align-items-center
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  flex-direction: row !important;
  -webkit-box-align: center !important;
  align-items: center !important;

  .dropdown__group--btn {
    display: unset;
    min-width: 1rem;
    height: 2.5rem;
    font-size: var(--font-size-5);
    line-height: 1rem;
    padding: 0 0.375rem 0 1.313rem;

    &.expand {
      .add {
        display: none;
      }
      .minus {
        display: unset;
      }
    }
    &.collapse {
      .add {
        display: unset;
      }
      .minus {
        display: none;
      }
    }
    .material-icons {
      margin: 0;
    }
  }

  .dropdown-header.checkbox {
    width: unset;
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: 2.5rem;
    margin: 0 auto 0 0;
    line-height: 2.5rem !important;

    .checkbox__checkmark {
      top: 0.6875rem;
      left: 0.375rem;
    }
    .checkbox__label {
      left: 1.375rem;
      line-height: 0.75rem !important;
    }
  }
}
.dropdown__group-child {
  &.hide {
    display: none;
  }
  .checkbox.dropdown-item {
    .checkbox__checkmark {
      left: 4.75rem;
    }
    .checkbox__label {
      margin: 0;
      padding: 0;
      left: 5.625rem;
    }
  }
}

/*-------END OF: Multi-Select STYLES -----------*/
/*----------------------------------------------*/

/*--for wrapper element only--*/
.wrapper-label {
  width: 2.813rem;
  height: 2.813rem;
  margin-right: 1.375rem;
  font-size: var(--font-size-2);
  color: var(--color-ey-confident-grey);
  text-align: right;
}

/*--main group of the component (DIV)*/
$single-select-width: 18.75rem;
$single-select-height: 3.125rem;
