input[type='text']:not(.mat-date-range-input-inner),
input[type='password'],
input[bsdatepicker]:not(.mat-date-range-input-inner) {
    @extend .textinput-group__textinput !optional;
}

select {
    @extend .textinput-group__textinput !optional;
}
.dropdown--datepicker {
    .textinput-group__label {
        max-width: calc(100% - 50px);
        overflow-x: hidden;
        height: auto;
    }
}

.ng-select.ey-select {
    border-radius: 0;
    min-width: 12.5rem;
    font-family: var(--font-family-light) !important;

    .ng-select-container {
        background: var(--color-ey-grey-4);
        border-radius: 0;
        height: 2.8125rem;
        box-shadow: none !important;
        overflow: visible;
        border: 0 solid transparent;
        border-bottom: 0.0625rem solid gray;
        outline: none;

        &:hover {
            background-color: var(--color-ey-grey-2a);

            .ng-value-container {
                .ng-placeholder {
                    &::after {
                        background-color: var(--color-ey-grey-2a);
                    }
                }
            }
        }

        .ng-value-container {
            display: block;
            color: var(--color-ey-confident-grey);
            font-family: var(--font-family-light);
            flex-wrap: nowrap !important;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 0.75rem;
            padding-top: 0.3125rem;
            height: 100%;

            .ng-placeholder {
                display: flex !important;
                position: absolute;
                top: 0.625rem;
                padding: 0;
                font-size: var(--font-size-4) !important;
                pointer-events: none;
                color: var(--color-ey-confident-grey);
                font-family: var(--font-family-light);
            }

            .ng-value {
                display: inline-block !important;
               font-size: var(--font-size-3);
                font-family: var(--font-family-light);
                margin-bottom: 0;
                background: inherit !important;
                border: 0 !important;
                border-radius: 0;
                vertical-align: middle;

                .ng-value-label {
                    bottom: 0;
                    left: 0.6rem;
                    top: inherit;
                    padding-left: 6px;
                    padding-right: 4px;
                }
                .pill--dropdown {
                    top: 1.125rem;
                }
                .pill--labeled {
                    top: 1.25rem;
                }
            }

            .ng-input {
                top: 0;
                height: 100%;
                padding: 0;
                padding-left: 0.75rem;
                position: absolute !important;
                left: 0 !important;
                width: 100% !important;
                display: inline-block;

                input {
                    line-height: inherit;
                    height: inherit;
                    padding: 0;
                    font-family: var(--font-family-light);
                    outline: 0 !important;
                    width: 90%;

                    &:focus {
                        color: transparent;
                        text-shadow: 0 0 0 var(--color-ey-confident-grey);
                    }
                }
            }
        }

        .ng-arrow-wrapper {
            margin-right: 0.4375rem;
            &::after {
                word-wrap: normal;
                font-family: var(--font-family-materialIcons);
                content: 'keyboard_arrow_down';
                font-size: var(--font-size-8);
                color: var(--color-ey-confident-grey);
                -webkit-font-smoothing: antialiased;
                text-rendering: optimizeLegibility;
                /* Support for Firefox. */
                -moz-osx-font-smoothing: grayscale;
                /* Support for IE. */
                font-feature-settings: 'liga';
            }

            .ng-arrow {
                display: none;
            }

            &:hover {
                .ng-arrow {
                    border-top-color: inherit;
                }
            }
        }

        &.ng-has-value {
            .ng-value-container {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                text-align: left;

                .result-name {
                    display: inline-block;
                    margin-top: 0.3125rem;
                    font-size: var(--font-size-4) !important;
                    font-family: var(--font-family-light);
                    color: var(--color-ey-confident-grey);
                }
                .ng-placeholder {
                    font-size: var(--font-size-3) !important;
                    color: var(--color-ey-confident-grey);
                    top: 0.125rem;
                }
            }
        }
    }

    &.ng-select-opened {
        .ng-select-container {
            .ng-arrow-wrapper {
                &::after {
                    font-family: var(--font-family-materialIcons);
                    content: 'keyboard_arrow_up';
                }
            }
            .ng-value-container {
                .ng-placeholder {
                    font-size: var(--font-size-3) !important;
                    color: var(--color-ey-confident-grey);
                    top: 0.125rem;
                }
            }
        }
    }

    &.ng-select-filtered {
        .ng-select-container {
            .ng-value-container {
                .ng-placeholder {
                    font-size: var(--font-size-3) !important;
                    color: var(--color-ey-confident-grey);
                    top: 0.125rem;
                }
            }
        }
    }

    &.ng-select-focused {
        .ng-select-container {
            outline: none;
            border: none;
            border-bottom: 0.0625rem solid var(--form-border-default);

            .ng-value-container {
                .ng-placeholder {
                    font-size: var(--font-size-3) !important;
                    color: var(--color-ey-confident-grey);
                    top: 0.125rem;
                }
            }
        }
    }

    &.ng-select-disabled {
        .ng-select-container {
            &:hover {
                background-color: var(--color-ey-grey-4);
                cursor: not-allowed;

                .ng-placeholder {
                    &::after {
                        background-color: var(--color-ey-grey-4);
                    }
                }
            }

            border-bottom: none;
            .ng-value-container {
                color: var(--color-inactive-icon-text);

                .ng-placeholder {
                    color: var(--color-inactive-icon-text);
                }
            }
            .ng-arrow-wrapper {
                &::after {
                    content: '';
                }
                .ng-arrow {
                    border-color: var(--color-inactive-icon-text) transparent transparent;
                }
            }
        }
    }
    .ng-select-container {
        &.ng-select-searchable {
            .ng-value-container {
                input {
                    &:focus {
                        color: var(--color-ey-confident-grey);
                        text-shadow: none !important;
                    }
                }
            }
        }
    }
    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                .ng-placeholder {
                    z-index: 0;
                    max-width: 80%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .ng-select-container.ng-has-value {
            .ng-value-container {
                .ng-value {
                    display: -webkit-box;
                    display: flex;
                    margin-right: 0;

                    & + .ng-value:before {
                        content: ',';
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
.active-pill {
    .ng-input {
        pointer-events: none;
        input {
            padding-left: 2.5rem !important;
            padding-top: 0.375rem;
            &:focus {
                color: var(--color-ey-confident-grey) !important;
                text-shadow: none !important;
            }
        }
    }
}
.labeled {
    .ng-select-container {
        .ng-value-container {
            padding-top: 0.75rem !important;
        }
    }
    .ng-input {
        input {
            padding-top: 0.3125rem !important;
        }
    }
}
.ng-dropdown-panel {
    z-index: 100001 !important;
    font-family: var(--font-family-light);
    border-radius: 0 !important;
    outline: var(--color-ey-confident-grey) solid 0.0625rem;
    border: 0;
    box-shadow: none !important;

    .ng-dropdown-panel-items {


        .ng-option {
            font-family: var(--font-family-light);
            font-size: var(--font-size-3);
            color: var(--color-ey-confident-grey);
            padding: 0;
            height: 2.5rem;
            border-radius: 0 !important;

            .ey-option {
                height: 100%;
                padding: 0.25rem 1rem;

                label.ey-option-label {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    margin: 0;
                    font-size: var(--font-size-4);
                    font-family: var(--font-family-light);
                    color: var(--color-ey-confident-grey);
                    cursor: pointer;
                    & .ey-option-icon {
                        margin-right: 10px;
                        color: var(--color-ey-grey-2a);
                    }
                    &.multiple .ey-option-icon {
                        margin-left: 16px;
                        margin-right: 0;
                    }

                    .checkbox__checkmark {
                        top: 0.375rem;
                    }
                    .checkbox__label {
                        font-size: var(--font-size-4);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0 !important;
                        line-height: inherit !important;
                        font-family: var(--font-family-light);
                        color: var(--color-ey-confident-grey);
                    }
                }
            }

            &.ng-option-marked {
                background: var(--color-ey-grey-4);
                .ey-option {
                    label.ey-option-label {
                        & .ey-option-icon {
                            color: var(--color-black);
                        }
                    }
                }
            }

            &.ng-option-selected {
                background: var(--color-ey-grey-4);
                .ey-option {
                    label.ey-option-label {
                        & .ey-option-icon {
                            color: var(--color-black);
                        }
                    }
                }
            }

            &.ng-option-selected.ng-option-marked {
                background: var(--color-ey-grey-4);
                .ey-option {
                    label.ey-option-label {
                        & .ey-option-icon {
                            color: var(--color-black);
                        }
                    }
                }
            }
        }

        .ng-option-disabled{
            .ey-option{
                label.ey-option-label{
                    .checkbox__label{
                        color: var(--color-ey-grey-1);
                        cursor: not-allowed;
                    }
                }
            }
        }
    }

    .ng-dropdown-header {
        border: 0;
        padding: 0.25rem 1rem;
        height: 2.5rem;
        display: flex;
        align-items: center;

        .checkbox {
            margin: 0;
            font-size: var(--font-size-4);

            .checkbox__label {
                line-height: inherit !important;
            }
        }
    }

    .ng-dropdown-footer {
        border: 0;
        border-top: 0.0625rem solid var(--color-ey-confident-grey);
        padding: 0.46875rem;
        outline: none;

        .footer-buttons {
            ey-button {
                button {
                    flex: 1 1 auto;
                    min-width: auto;
                }
            }
        }
    }
}

.ng-dropdown-panel.parent-group{
    .ng-dropdown-panel-items {

        .ng-option{
            &.ng-option-selected{
                background: var(--color-ey-grey-2a);
            }
            &.ng-option-marked {
                background: var(--color-ey-grey-2a);
            }

        }

        .ng-optgroup{
            display: flex;
            align-items: center;
            position: relative;
            margin: 0;
           font-size: var(--font-size-3);
            padding: 0.25rem 1rem 0.25rem 0;
            height: 2.5rem;
            &.ng-option-selected{
                background: var(--color-ey-grey-2a);
                font-weight: normal;
            }
            &.ng-option-marked {
                background: var(--color-ey-grey-2a);
            }

            .ey-option-group{
                margin: 0 0 0 0.3125rem;
                label, .checkbox{
                    margin: 0;
                }
            }

            .collapsible-btn{
                width: auto;
                height: 2.5rem;
                background-color: transparent;
                border: none;
                padding: 0 0.3125rem;

                i{
                    font-size: var(--font-size-5);
                }
                &:hover{
                    color: var(--color-ey-confident-grey);
                    background:var(--color-ey-grey-2a);
                }
            }

            .collapsed_grupo{
                display: none;
            }
            .expanded_group{
                display: inline-block;
            }

        &:hover{
            color:var(--color-ey-confident-grey);
            background: var(--color-ey-grey-2a);
        }


    }
        .ng-optgroup ~ .ng-option{
            display: none;
            position: absolute;
            z-index: -1;
            padding-left: 4.375rem;
        }
        .open-group-icon{
            .collapsible-btn{
                .collapsed_grupo{
                    display: inline-block !important;
                }
                .expanded_group{
                    display: none !important;
                }
            }
        }
        .open-group{
            display: block !important;
            position: relative !important;
            z-index: 2 !important;
            padding-left: 1.875rem !important;
        }
    }
}

.requiredMark {
    .ng-select-container {
        .ng-value-container {
            .ng-placeholder {
                &::after {
                    content: '*' !important;
                    padding-left: 0px !important;
                    color: var(--color-red-05);
                    position: absolute;
                    right: -0.25rem;
                    top: 0;
                    width: 0.625rem;
                    height: 100%;
                    background-color: var(--color-ey-grey-4);
                }
            }
        }
    }
}
[ng-reflect-error='true'] {
    .textinput-group__errmsg {
        display: block !important;
    }
}
.error {
    .ng-select-container {
        border-bottom-color: var(--color-red-06) !important;
    }
}

.ey-select--dark {

    .ng-select-container {
        background: var(--color-black-container) !important;
        border-bottom: 0.0625rem solid var(--color-white) !important;

        .ng-value-container {
            color: var(--color-white) ;
            width: 100%;

            .ng-placeholder {
                color: var(--color-white);
            }
            .ng-input {
                input {
                    color: var(--color-white);
                    &:focus {
                        text-shadow: 0 0 0 var(--color-white) !important;
                    }
                }
            }

            .ng-value{
                width: 100%;
            }

        .ng-arrow-wrapper {
            &::after {
                color: var(--color-white) !important;
            }
        }

        .result-name {
            color: var(--color-white) !important;
            width: 100%;
            span{
                max-width: calc(100% - 52px);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
            }
        }
        .ng-placeholder {
            color: var(--color-white) !important;
        }
    }

    .ng-arrow-wrapper {
        &::after {
            color: var(--color-white) !important;
        }
    }

    &.ng-select-focused {
        .ng-select-container {
            outline: none;
            border: none;
            border-bottom: 0.0625rem solid var(--form-border-default);

            .ng-value-container {
                .ng-placeholder {
                    font-size: var(--font-size-3) !important;
                    color: var(--color-ey-confident-grey);
                    top: 0.125rem;
                }
            }
        }
    }

    .ng-select-container {
        &.ng-select-searchable {
            .ng-value-container {
                input {
                    &:focus {
                        color: var(--color-white);
                    }
                }
            }
        }
    }

    }

    .ng-select-searchable {

        .ng-value-container {
            input {
                &:focus {
                    color: var(--color-white) !important;
                }
            }
        }
    }


.ng-dropdown-panel {
    outline: var(--color-white) solid 0.0625rem;
    background: var(--color-ey-confident-grey);
    top: auto;

    .ng-dropdown-panel-items {
        .ng-option {
            background: var(--color-ey-confident-grey);
            color: var(--color-white);
            cursor: pointer;

            &.ng-option-selected {
                &.ng-option-marked {
                    background: none;
                }
            }

            .ey-option {
                height: 100%;

                label.ey-option-label {
                    color: var(--color-white);
                   font-size: var(--font-size-3);
                    .checkbox__label {
                        color: var(--color-white);
                    }
                }
            }
        }
    }

    .ng-dropdown-footer {
        border-top: 0.0625rem solid var(--color-white);
    }
}


}

.ey-mode--borderless {
    .ng-select-container {
        border-bottom: none !important;
        background: var(--color-white) !important;
    }
}

.ng-select-opened.ey-mode--borderless{
    .ng-select-container {
        background-color: var(--color-ey-grey-2a) !important;
    }
}


[ng-reflect-mode='ey-mode--borderless'] {
    ng-dropdown-panel {
        .ng-optgroup{
            border-top: 0.0625rem solid var(--color-border-3);
            label{
                font-family: var(--font-family-bold);
                color: var(--color-ey-confident-grey);
            }
        }
        .ng-optgroup:first-child{
            border-top: none;
        }
        .ng-option{
            label{
                p.action{
                    font-family: var(--font-family-bold) !important;
                    color: var(--color-ey-link) !important;
                }
            }
        }

    }
}


