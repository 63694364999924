@mixin pager-font-styles {
    color: var(--color-ey-confident-grey);
    font-size: var(--font-size-4);
    font-family: var(--font-family-light) !important;
    font-weight: 300;
    line-height: 3rem;
}

@mixin pager-styles {
    .k-grid-pager {
        padding-top: 1.125rem;
        background: var(--color-white);
        border: none;
        justify-content: flex-end !important;

        .k-pager-info {
            justify-content: flex-start;
        }

        .k-pager-sizes {
            & kendo-dropdownlist {
                & .k-input-inner {
                    padding: 0.6rem 0.75rem;
                    display: flex;
                    justify-content: center;
                }
                & .k-input-button {
                    height: 100%;
                    background: transparent;
                    border: none;
                }
                & .k-i-arrow-s {
                    &::before {
                        font-family: var(--font-family-materialIcons);
                        content: 'expand_more';
                        -webkit-font-smoothing: antialiased;
                        text-rendering: optimizeLegibility;
                        /* Support for Firefox. */
                        -moz-osx-font-smoothing: grayscale;
                        /* Support for IE. */
                        font-feature-settings: 'liga';
                    }
                }

                &:hover {
                    background-color: var(--color-white);
                }
                background-color: var(--color-ey-grey-4);
                color: var(--color-ey-confident-grey);

                border: 0;
                border-bottom: 0.0625rem solid var(--color-ey-confident-grey);
                display: flex;
                font-family: var(--font-family-light) !important;
                line-height: 1.25rem !important;
                font-size: var(--font-size-4) !important;
                height: 2.813rem;
                width: 100%;
                text-align: left;
                cursor: pointer;
                margin-right: 1ex;
            }

            @include pager-font-styles;
            white-space: nowrap;
            & select {
                padding-right: 0;
                &:hover,
                &:focus,
                &:active {
                    &::before {
                        background: transparent;
                        background-color: var(--color-gray-alt-4);
                    }
                }
                &:focus {
                    background: transparent;
                    border-bottom: solid 0.1875rem var(--color-ey-confident-grey);
                }
                option {
                    @include pager-font-styles;
                }
            }

            @media (min-width: 768px) {
                & select {
                    float: left;
                    margin-top: 0.125rem;
                    padding-right: 0;
                }
            }
        }

        button.k-pager-nav.k-button {
            background: transparent;
            color: var(--color-black) !important;
            font-size: var(--font-size-4);
            border: none;
            padding: 0.25rem;
            height: 2.8125rem;
            width: 2.8125rem;

            &::before {
                background: transparent;
            }

            &:hover::before {
                background: transparent;
            }

            border-bottom: 0.1875rem solid transparent;
            &:hover,
            &:focus,
            &:active {
                &::before {
                    opacity: 1;
                    border-bottom: 0.1875rem solid var(--color-ey-confident-grey);
                    box-shadow: none;
                }
            }
            &:focus,
            &:active {
                box-shadow: none;
            }

            &.k-selected {
                pointer-events: none;
                color: var(--color-black) !important;
                font-weight: bold;

                &::before {
                    opacity: 1;
                    background: none;
                    border-bottom: solid 0.1875rem var(--color-ey-confident-grey);
                }
            }

            .k-icon {
                font-size: 1.6875rem;
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                &::before {
                    font-size: 1.6875rem;
                }
            }
        }

        .k-selected {
            border-bottom: 0.1875rem solid var(--color-ey-confident-grey);
        }

        grid-pagination {
            flex: 1;
        }
        .grid-pagination {
            span[data-showingcount] {
                position: absolute;
                top: 1.0625rem;
                right: 0.9375rem;
            }
        }
        kendo-pager-page-sizes,
        kendo-treelist-pager-page-sizes {
            order: 0;
            flex: 1 1;
        }
        kendo-pager-info {
            display: none;
        }

        @media (min-width: 1024px) {
            .grid-pagination {
                span[data-showingcount] {
                    display: flex;
                    @include pager-font-styles;
                    flex: 1 1;
                    position: relative;
                    top: auto;
                    right: auto;
                }
            }
            grid-pagination kendo-pager-page-sizes,
            grid-pagination kendo-treelist-pager-page-sizes {
                order: 0;
                flex: none;
            }
        }
        @media (min-width: 1200px) {
            kendo-pager-page-sizes,
            kendo-treelist-pager-page-sizes {
                flex: none; // non grid-pagination component
            }
            kendo-pager-info {
                @include pager-font-styles;
                display: flex;
                order: 1;
                flex: 1 1;
                justify-content: flex-start;
                margin-left: 0; // non grid-pagination component
                &::before {
                    content: '|';
                    margin-right: 1rem; // non grid-pagination component
                }
            }
        }

        .pagination-custom-message-pipe {
            display: none;
        }

        kendo-pager-prev-buttons,
        kendo-treelist-pager-prev-buttons {
            order: 2;
            display: flex;
            flex-wrap: nowrap;
            .k-pager-nav {
                font-feature-settings: 'liga';
                &.k-pager-first {
                    .k-icon {
                        &.k-i-seek-w {
                            position: relative;
                            display: inline-flex;
                            color: var(--color-black) !important;
                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: 'first_page';
                            }
                        }
                    }
                }
                .k-icon {
                    &.k-i-arrow-w {
                        position: relative;
                        display: inline-flex;
                        color: var(--color-black) !important;
                        &::before {
                            font-family: var(--font-family-materialIcons);
                            content: 'chevron_left';
                            -webkit-font-smoothing: antialiased;
                            text-rendering: optimizeLegibility;
                            /* Support for Firefox. */
                            -moz-osx-font-smoothing: grayscale;
                            /* Support for IE. */
                            font-feature-settings: 'liga';
                        }
                    }
                }
            }
        }
        kendo-pager-numeric-buttons,
        kendo-treelist-pager-numeric-buttons {
            order: 2;
        }
        kendo-pager-next-buttons,
        kendo-treelist-pager-next-buttons {
            order: 2;
            display: flex;
            flex-wrap: nowrap;
            .k-pager-nav {
                font-feature-settings: 'liga';
                &.k-pager-last {
                    .k-icon {
                        &.k-i-seek-e {
                            position: relative;
                            display: inline-flex;
                            color: var(--color-black) !important;
                            &::before {
                                font-family: var(--font-family-materialIcons);
                                content: 'last_page';
                            }
                        }
                    }
                }
                .k-icon {
                    &.k-i-arrow-e {
                        position: relative;
                        display: inline-flex;
                        color: var(--color-black) !important;
                        &::before {
                            font-family: var(--font-family-materialIcons);
                            content: 'chevron_right';
                            -webkit-font-smoothing: antialiased;
                            text-rendering: optimizeLegibility;
                            /* Support for Firefox. */
                            -moz-osx-font-smoothing: grayscale;
                            /* Support for IE. */
                            font-feature-settings: 'liga';
                        }
                    }
                }
            }
        }

        @media (max-width: 1024px) {
            padding-top: 3.75rem;
            justify-content: space-between !important;

            kendo-pager-info {
                position: absolute;
                top: 0;
                left: 12.8125rem;
            }
            kendo-pager-page-sizes,
            kendo-treelist-pager-page-sizes {
                width: 100%;
                max-width: 11.875rem;
                position: absolute;
                top: 0;
                left: 0;
            }
            kendo-pager-numeric-buttons,
            kendo-treelist-pager-numeric-buttons {
                .k-link {
                    max-width: 2.8125rem;
                    width: auto;
                }
            }
        }
    }
}
