:root {
    /* Spacing for padding and margin */
    --spacing-0: 0rem;
    --spacing-01: 0.3125rem;
    --spacing-02: 0.625rem;
    --spacing-03: 0.9375rem;
    --spacing-04: 1.25rem;
    --spacing-05: 1.5625rem;
    --spacing-06: 1.875rem;
    --spacing-07: 2.1875rem;
    --spacing-08: 2.5rem;
}
