.modal-open {
    overflow: hidden;
}

.modal-content {
    padding: 0;

    .header {
        background-color: var(--color-white) !important;
        border-bottom: 0.0625rem solid rgba(var(--color-ey-grey-2-rgb), 0.35) !important;
        .title {
            font-family: var(--font-family-light) !important;
            font-size: var(--font-size-8) !important;
            line-height: 1.5rem !important;
            font-weight: 500;
            color: var(--color-ey-confident-grey);
        }
    }

    .progress-indicator-container {
        .stepper__controls {
            &::after {
                content: '';
                position: absolute;
                top: 49.5%;
                width: 100%;
                height: 0.125rem;
                background-color: var(--color-ey-grey-2);
                margin-right: 0.3125rem;
            }

            overflow: hidden;
            counter-reset: step;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            flex-wrap: nowrap;
            -webkit-flex-wrap: nowrap;

            background-color: var(--color-white);
            position: relative;
            margin-bottom: 2rem;
            width: 100%;
            width: stretch;
            width: -webkit-fill-available;

            .stepper__control {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
                justify-content: flex-start;
                -webkit-justify-content: flex-start;
                flex-wrap: nowrap;
                -webkit-flex-wrap: nowrap;
                align-items: center;
                background-color: var(--color-white);
            }

            .stepper__group {
                display: flex;
                align-items: center;
            }

            .stepper__title {
                padding: 0 0.4375rem;
                background-color: var(--color-white);
                color: var(--color-ey-confident-grey);
                font-size: var(--font-size-3);
                line-height: 0.9375rem;
                z-index: 2;
            }
            .stepper__dot {
                position: relative;
                padding-left: 0.4375rem;
                z-index: 2;
                background: var(--color-white);
                &::before {
                    /* CSS for creating steper block before the li item*/
                    content: '';
                    counter-increment: step;
                    height: 1.25rem;
                    width: 1.25rem;
                    line-height: 1.875rem;
                    border: 0.0625rem solid var(--color-ey-confident-grey);
                    display: inline-block;
                    text-align: center;
                    margin: 0;
                    border-radius: 50%;
                    background-color: var(--color-white);
                    vertical-align: middle;
                    z-index: 1;
                    position: relative;
                }
            }
            .stepper__control:first-child {
                .stepper__dot {
                    padding-left: 0;
                    &::after {
                        left: 0.0625rem;
                    }
                }
            }

            .stepper__control.stepper__control--done {
                .stepper__dot {
                    &::before {
                        font-family: var(--font-family-materialIcons);
                        line-height: 1rem;
                        font-size: var(--font-size-3);
                        content: 'check';
                        -webkit-font-smoothing: antialiased;
                        text-rendering: optimizeLegibility;
                        /* Support for Firefox. */
                        -moz-osx-font-smoothing: grayscale;
                        /* Support for IE. */
                        font-feature-settings: 'liga';
                    }
                }
            }
            .stepper__control.stepper__control--active {
                .stepper__title {
                    font-weight: bold;
                    font-family: var(--font-family-bold) !important;
                }

                .stepper__dot {
                    &::before {
                        background-color: var(--color-green-04);
                        box-shadow: inset 0 0 0 0.1875rem var(--color-white);
                        content: '';
                    }
                }
            }
        }
    }
}

.stepper.stepper--line {
    position: relative;
    .stepper__controls::after {
        display: none;
    }
    .stepper__line {
        position: absolute;
        top: 39.5%;
        width: 100%;
        height: 0.125rem;
        background-color: var(--color-ey-grey-2);
        margin-right: 0.3125rem;
    }
}
