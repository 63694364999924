/* Tokens */
@import './scss/typography';
@import './scss/typography-mixins';

/* Initial Components*/
@import './scss/components/buttons';
@import './scss/components/check-boxes';
@import './scss/components/dropdowns';
@import './scss/components/inputs';
@import './scss/components/radio-buttons';
@import './scss/components/toggles';
@import './scss/components/icons';

/* Mixin Components */
@import './scss/components/accordion';
@import './scss/components/activity-cards';
@import './scss/components/avatars';
@import './scss/components/cards';
@import './scss/components/data-tables';
@import './scss/components/menus';
@import './scss/components/footers';
@import './scss/components/global-header';
@import './scss/components/header';
@import './scss/components/messaging-alert';
@import './scss/components/modals';
@import './scss/components/navigation';
@import './scss/components/pagination';
@import './scss/components/status-indicators';
@import './scss/components/story-cards';
@import './scss/components/tool-tips';
@import './scss/components/data-roll-up-bar';
@import './scss/components/stepper';
@import './scss/components/progress-bar';
@import './scss/components/filters';
@import './scss/components/slider';
@import './scss/components/search';
@import './scss/components/breadcrumb';
@import './scss/components/file-uploader';

* {
    font-family: var(--font-family);
}

*.eyfontregular {
    font-family: var(--font-family) !important;
}

*.eyfontlight {
    font-family: var(--font-family-light) !important;
}

*.eyfontbold {
    font-family: var(--font-family-bold) !important;
}

html {
    font-size: var(--font-size) !important;
}